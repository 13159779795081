import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/Button';

/**
 * 
 * @param {*} props 
 * @returns 
 * 
 *  layout	feft		middle		right
 *  ----------------------------------------------
 *	1		preview		   orderdata
 *	2		preview		orderdata   editions
 *	3		preview		orderdata   offers
 
 */
export const OrderLayouts = (layout, height, width) => {

    const l1 = {
        preview: {
            width: width < 900 ? '100%' : '33%',
            order: 1
        },
        orderdata: {
            width: width < 900 ? '100%' : '67%',
            height: height,
            order: 2
        },
        offers: {
            display: 'none'
        },
        editions: {
            display: 'none'
        }
    };
    const l2 = {
        preview: {
            width: width < 900 ? '100%' : '25%',
            order: 1
        },
        orderdata: {
            width: width < 900 ? '100%' : '50%',
            height: height,
            order: 2
        },
        offers: {
            display: 'none'
        },
        editions: {
            width: width < 900 ? '100%' : '25%',
            height: height,
            order: 3
        }
    };
    const l3 = {
        preview: {
            width: width < 900 ? '100%' : '25%',
            order: 1
        },
        orderdata: {
            width: width < 900 ? '100%' : '50%',
            height: height,
            order: 2
        },
        offers: {
            width: width < 900 ? '100%' : '25%',
            height: height,
            order: 3
        },
        editions: {
            display: 'none'
        },
    };
    const l4 = {
        preview: {
            width: width < 900 ? '100%' : '40%',
            order: 1
        },
        orderdata: {
            width: width < 900 ? '100%' : '60%',
            height: height,
            order: 2
        },
        offers: {
            display: 'none'
        },
        editions: {
            display: 'none'
        },
    };

    switch (layout) {
        case 1:
            return l1;
        case 2:
            return l2;
        case 3:
            return l3;
        case 4:
            return l4;
        default:
            return l1;
    }
}
