import React from 'react';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    properties, passwordCheck, checkedPasswordValid, checkedUserNameValid, device, features,
    isGuestAuthenticated, isWSS, isSsoAuthentication,
    businessPartner
} from '../../redux/Selectors.js';
import { checkPassword, checkPasswordAllowed, checkUserName, resetCheckedUserNameValid } from '../../redux/Actions';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import PasswordStrengthBar from 'react-password-strength-bar';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormControl,
    Grid,
    InputAdornment,
    Stack,
    TextField,
} from '@mui/material';
import { Typography } from '@mui/material';
import PasswordReminder from './PasswordReminder.jsx';
import { debounce } from '../../common/tools.js';
import { EyeIcon, EyeSlashIcon } from '../../common/widgetTools.js';

import MsgPopper from '../common/MsgPopper';


import { findKeyValue } from '../../common/tools.js';
import { styleForm } from '../../common/styles.js';


export const Account = forwardRef(({ credentials, marketingApproval, dirty, bpNew, valid }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _properties = useSelector((state) => properties(state));
    const _checkedPasswordValid = useSelector((state) => checkedPasswordValid(state));
    const _passwordCheck = useSelector((state) => passwordCheck(state));
    const _checkedUserNameValid = useSelector((state) => checkedUserNameValid(state));
    const _device = useSelector((state) => device(state));
    const _features = useSelector((state) => features(state))
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _isSsoAuthentication = useSelector((state) => isSsoAuthentication(state));

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmed, setPasswordConfirmed] = useState('');
    const [marketingApprovalValue, setMarketingApprovalValue] = useState(false);
    const [privacyPolicyValue, setPrivacyPolicyValue] = useState(false);
    const [displayMsgButton, setDisplayMsgButton] = useState(false);
    const [msgButtonText, setMsgButtonText] = useState('');
    const [passwordReminderOpen, setPasswordReminderOpen] = useState(false);


    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [userNameError, setUserNameError] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [checkPasswordValid, setCheckPasswordValid] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [curMsg, setCurMsg] = useState('');
    const [msgPassword, setMsgPassword] = useState('');
    const [msgUserName, setMsgUserName] = useState('');
    const [showError, setShowError] = useState(false);
    const [anchorRef, setAnchorRef] = useState();
    //   const [valid, setValid] = useState(false);

    const minPasswordLength = 8;

    const passwordRef = React.useRef(null);
    const usernameRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
        showErrors: () => {
            setShowError(true);
        } /*,
        isValid: () => {
            console.log("Account: isValid returns " + valid);
            return valid;
        } */
    }),);

    useEffect(() => {
        handleValidation(password);
    }, [password]);

    useEffect(() => {
        setPasswordError(!_passwordCheck.valid);
    }, [_passwordCheck]);

    const handleValidation = debounce(value => {
        dispatch(checkPassword(value));
    }, 600);

    useEffect(() => {
        setUserName(credentials?.name);
        setPassword(credentials?.password);
        setMarketingApprovalValue(_device === 'mobile' ? true : marketingApproval?.value)
        setIsNew(bpNew);
        setPrivacyPolicyValue(!bpNew);
        setCheckPasswordValid((_properties.registerFormRequieredElemntsBusiness.indexOf("checkPasswordAllowed") > -1) && (!_businessPartner || (_businessPartner && (password?.length > 0 || passwordConfirmed?.length > 0))));
        setMsgPassword(_properties?.passwordPatternExplanation?.length > 0 && t(_properties?.passwordPatternExplanation).length > 0 ? t(_properties?.passwordPatternExplanation) : t(_device === 'mobile' ? 'm.customerdata.passwordirective' : 'customerdata.passwordirective'));
        setMsgUserName(t('customerdata.account.username.error'))
        handleValidation(password);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setUserName(credentials?.name);
    }, [credentials?.name])

    useEffect(() => {
        setPasswordError(!_isGuestAuthenticated && password !== passwordConfirmed);
    }, [password, passwordConfirmed, _isGuestAuthenticated]);

    useEffect(() => {
        if (valid) {
            valid(!passwordError &&
                !userNameError &&
                (privacyPolicyValue || _device === 'mobile') && marketingApprovalValue &&
                (_isGuestAuthenticated || _checkedUserNameValid === true || userName === _businessPartner?.legalEntity?.credentials?.name));
        }
    }, [userNameError, marketingApprovalValue, privacyPolicyValue, passwordError, _checkedUserNameValid, userName]);

    // useEffect(() => {
    //     if (valid) {
    //         valid(!passwordError &&
    //             (_isGuestAuthenticated || (!checkPasswordValid || (checkPasswordValid && _checkedPasswordValid))) &&
    //             !userNameError &&
    //             (privacyPolicyValue || _device === 'mobile') && marketingApprovalValue &&
    //             (_isGuestAuthenticated || _checkedUserNameValid === true || userName === _businessPartner?.legalEntity?.credentials?.name));
    //     }
    // }, [userNameError, marketingApprovalValue, privacyPolicyValue, passwordError, _checkedPasswordValid, _checkedUserNameValid, userName]);

    useEffect(() => {
        if (password?.length > 0 && _properties.passwordPatternExplanation?.length > 0) {
            setCurMsg(msgPassword);
            setAnchorRef(passwordRef);
            setMsgOpen(!_checkedPasswordValid);
        }
    }, [_checkedPasswordValid]);

    useEffect(() => {
        console.log("_checkedUserNameValid " + _checkedUserNameValid);
        if (userName?.length > 0 && _checkedUserNameValid === false) {
            setCurMsg(msgUserName);
            setAnchorRef(usernameRef);
            if (isNew) {
                //          setPasswordReminderOpen(true);
                // setHandleMsgButton(handleDisplayPasswordReminder);
                setMsgButtonText(t('customerdata.account.password.change'));
                setDisplayMsgButton(true);
            } else {
                setDisplayMsgButton(false);
            }
            setMsgOpen(!_checkedUserNameValid);
        }
    }, [_checkedUserNameValid]);

    useEffect(() => {
        var userError = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userName);
        if (userName?.length > 0 && userName !== _businessPartner?.credentials?.name) {
            setUserNameError(userError);
            if (userError) {
                setCurMsg(t(_isGuestAuthenticated ? 'customerdata.account.email.invalid' : 'customerdata.account.username.invalid'));
                setAnchorRef(usernameRef);
            }
            setMsgOpen(userError);
        } else {
            setMsgOpen(false);
        }
    }, [userName]);

    useEffect(() => {
        if (msgOpen === false) {
            setDisplayMsgButton(false);
        }
    }, [msgOpen])

    const handleDisplayPasswordReminder = () => {
        setPasswordReminderOpen(true);
    }

    const handelCloseForgotten = () => {
        setPasswordReminderOpen(false);
        setMsgOpen(false);
    }

    const userNameChanged = (value) => {
        setUserName(value);
        setMsgOpen(false);
        credentials.name = value;
        if (dirty) dirty();
    }

    const passwordChanged = (value) => {
        setMsgOpen(false);
        setPassword(value);
        credentials.password = value;
        if (dirty) dirty();
    }

    // const checkPassword = () => {
    //     if (checkPasswordValid === true) {
    //         setMsgOpen(false);
    //         dispatch(checkPasswordAllowed(password));
    //     }
    // }

    const checkUsername = () => {
        console.log("checkUsername " + userName + "...");
        if (userName?.length > 0 && !_isGuestAuthenticated && userName !== _businessPartner?.legalEntity?.credentials?.name) {
            dispatch(resetCheckedUserNameValid());
            dispatch(checkUserName(userName));
        }
    }

    const passwordConfirmedChanged = (value) => {
        setPasswordConfirmed(value);
        if (dirty) dirty();
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMarketingApprovalChange = (e) => {
        setMarketingApprovalValue(e.target.checked);
        marketingApproval.value = e.target.checked;
    }

    const handlePrivacyPolicyChange = (e) => {
        setPrivacyPolicyValue(e.target.checked);
        //       privacyPolicy.value = e.target.checked;
    }

    return (
        <form>
            <Stack direction="column" sx={{ margin: '1em' }}>
                {!_isWSS && (
                    <>
                        <Typography variant={_device === 'mobile' ? "mobilePageSubTitle" : "pageTitleBold"}>
                            {_device === 'mobile' ? t('customer.m.home.account.title') : t('customerdata.account.title')}
                        </Typography>
                        {_device === 'mobile' && (
                            <Typography variant="mobilePageSubTitle2">
                                {t('customer.m.home.account.subTitle')}
                            </Typography>
                        )}
                        <TextField
                            sx={{ mt: _device === 'mobile' ? 0 : '2em', width: _device === 'mobile' ? '95%' : '400px' }}
                            ref={usernameRef}
                            variant="standard"
                            // inputProps={{ readOnly: true }}
                            label={t('customerdata.account.userName')}
                            type="text"
                            value={userName ?? ""}
                            error={showError && (userNameError || _checkedUserNameValid === false)}
                            onChange={e => userNameChanged(e.target.value)}
                            onBlur={e => checkUsername()}
                            required
                        />
                        <TextField
                            sx={{ mt: '1em', width: _device === 'mobile' ? '95%' : '400px' }}
                            ref={passwordRef}
                            variant="standard"
                            required
                            autoComplete="new-password"
                            label={isNew ? t('customerdata.account.password') : t('customerdata.account.password.change')}
                            type={showPassword ? "text" : "password"}
                            value={password ?? ""}
                            // error={passwordError || (showError && (checkPasswordValid && !_checkedPasswordValid))}
                            error={passwordError}
                            onChange={e => passwordChanged(e.target.value)}
                            onBlur={e => handleValidation(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment onClick={handleClickShowPassword} position="end">
                                        {showPassword && (<EyeSlashIcon size='lg' />)}
                                        {!showPassword && (<EyeIcon size='lg' />)}
                                    </InputAdornment>
                                ),
                                autoComplete: 'new-password',
                            }}
                        />

                        <TextField
                            sx={{ mt: '1em', width: _device === 'mobile' ? '95%' : '400px' }}
                            variant="standard"
                            required
                            label={t('customerdata.account.password.confirm')}
                            type={showPassword ? "text" : "password"}
                            value={passwordConfirmed ?? ""}
                            error={passwordError}
                            onChange={e => passwordConfirmedChanged(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment onClick={handleClickShowPassword} position="end">
                                        {showPassword && (<EyeSlashIcon size='lg' />)}
                                        {!showPassword && (<EyeIcon size='lg' />)}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography sx={{ mt: '2em' }}>{_passwordCheck.message}</Typography>
                        {_passwordCheck?.minLength !== 0 && (<Box sx={{ mt: '2em', width: _device === 'mobile' ? '95%' : '400px' }}>
                            <PasswordStrengthBar
                                password={password}
                                minLength={_passwordCheck.minLength}
                                scoreWords={[t('customerdata.account.password.scorewords.weak'),
                                t('customerdata.account.password.scorewords.weak'),
                                t('customerdata.account.password.scorewords.okay'),
                                t('customerdata.account.password.scorewords.good'),
                                t('customerdata.account.password.scorewords.strong')]}
                                shortScoreWord={t('customerdata.account.password.scorewords.tooshort')}
                            />
                        </Box>)}
                        {/* {password?.length > 0 && (<Box sx={{ mt: '2em', width: _device === 'mobile' ? '95%' : '400px' }}>
                            <PasswordStrengthBar
                                password={password}
                                minLength={minPasswordLength}
                                scoreWords={[t('customerdata.account.password.scorewords.weak'),
                                t('customerdata.account.password.scorewords.weak'),
                                t('customerdata.account.password.scorewords.okay'),
                                t('customerdata.account.password.scorewords.good'),
                                t('customerdata.account.password.scorewords.strong')]}
                                shortScoreWord={t('customerdata.account.password.scorewords.tooshort')}
                            />
                        </Box>)} */}

                        {/* {password?.length === 0 && (<Typography sx={{ mt: '2em' }}
                            // variant='termsAndConditions'
                            dangerouslySetInnerHTML={{ __html: msgPassword }}></Typography>)} */}

                    </>
                )}

                {(!_isGuestAuthenticated && _isSsoAuthentication) && (
                    <>
                        <Typography variant={_device === 'mobile' ? "mobilePageSubTitle" : "pageTitleBold"}>
                            {_device === 'mobile' ? t('customer.m.home.account.guest.title') : t('customerdata.account.guest.title')}
                        </Typography>

                        <TextField
                            sx={{ mt: _device === 'mobile' ? 5 : '2em', width: _device === 'mobile' ? '95%' : '400px' }}
                            ref={usernameRef}
                            variant="standard"
                            // inputProps={{ readOnly: true }}
                            label={t('customerdata.email')}
                            type="text"
                            value={userName}
                            error={showError && (userNameError || _checkedUserNameValid === false)}
                            onChange={e => userNameChanged(e.target.value)}
                            onBlur={e => checkUsername()}
                            required
                        />
                    </>
                )}

                {_device !== 'mobile' && (<>
                    <FormControl
                        sx={{ mt: '2em', display: 'flex', flexDirection: 'row' }}
                        error={showError && !privacyPolicyValue}
                    >
                        <FormControlLabel
                            sx={{ width: '800px' }}
                            labelPlacement="end"
                            label={<Typography
                                // variant='termsAndConditions'
                                dangerouslySetInnerHTML={{ __html: t('order.checkbox2') }}></Typography>}
                            control={
                                <Checkbox
                                    sx={{ color: showError && !privacyPolicyValue ? 'error.main' : 'primary.main' }}
                                    checked={privacyPolicyValue}
                                    onChange={handlePrivacyPolicyChange}
                                    disabled={!isNew}
                                    size="small" />
                            }
                        />
                        {!isNew && (<TextField variant="standard"
                            label={t('common.lastModified')}
                            value={t('common.date.short', { value: new Date() })}
                        >
                        </TextField>)}
                    </FormControl>
                    <FormControl
                        sx={{ mt: '2em', display: 'flex', flexDirection: 'row' }}
                        error={showError && !marketingApprovalValue}
                    >
                        <FormControlLabel
                            sx={{ width: '800px' }}
                            labelPlacement="end"
                            label={<Typography
                                // variant='termsAndConditions'
                                dangerouslySetInnerHTML={{ __html: t('order.checkbox3') }}></Typography>}
                            control={
                                <Checkbox
                                    sx={{ color: showError && !marketingApprovalValue ? 'error.main' : 'primary.main' }}
                                    checked={marketingApprovalValue}
                                    onChange={handleMarketingApprovalChange}
                                    size="small" />
                            }
                        />

                    </FormControl>
                </>)}


                {/* <FormControl sx={{ mt: '2em', display: 'flex', flexDirection: 'row' }} >
                    <FormControlLabel
                        sx={{ width: '800px' }}
                        labelPlacement="end"
                        label={<Typography
                            // variant='termsAndConditions'
                            dangerouslySetInnerHTML={{ __html: t('order.checkbox2') }}></Typography>}
                        control={
                            <Checkbox
                                size="small" />
                        }
                    />
                    <TextField variant="standard"
                        label={t('common.lastModified')}
                        value={t('common.date.short', { value: new Date() })}
                    >
                    </TextField>
                </FormControl> */}
            </Stack>
            <MsgPopper
                open={msgOpen}
                anchorRef={anchorRef}
                placement={_device === 'mobile' ? 'bottom' : 'right'}
                arrow={true}
                text={curMsg}
                button={displayMsgButton}
                buttonText={msgButtonText}
                handleButton={handleDisplayPasswordReminder}
            >
            </MsgPopper>
            <PasswordReminder
                dialogOpen={passwordReminderOpen}
                userName={userName}
                handleDialogClose={handelCloseForgotten} />

        </form >
    );
});


export const AccountSmall = ({ authentication, businessPartner }) => {
    const { t } = useTranslation();

    return (<Box>
        <Grid container spacing={2}>
            <Grid item lg={4} sm={4}>
                <Typography variant="cardData">
                    {t('customerdata.name')}:
                </Typography>
            </Grid>
            <Grid item lg={8} sm={8}>
                <Typography variant="cardData">
                    {authentication.userName}
                </Typography>
            </Grid>
        </Grid >
        <Grid container spacing={2}>
            <Grid item xs={4} >
                <Typography variant="cardData">
                    {t('customerdata.businessPartnerNo')}:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="cardData">
                    {businessPartner.businessPartnerNo}
                </Typography>
            </Grid>
        </Grid>
    </Box>
    );
}
