/**
 * 
 * @param {*} props 
 * @returns 
 * 
 *  layout	upper left		upper right		
 *  ----------------------------------------
 *	1		offers		    editions        
 *	1		          offers		        
 
 
 */
export const OfferLayouts = (layout, height, width) => {

    const l1 = {
        offers: {
            width: '74.5%',
            order: 1
        },
        editions: {
            width: '25%',
            height: height,
            order: 2
        }
    };

    const l2 = {
        offers: {
            width: '100%',
            order: 1
        },
        editions: {
            display: 'none',
            order: 2
        }
    };



    switch (layout) {
        case 1:
            return l1;
        case 2:
            return l2;
        default:
            return l2;
    }
}