import React from 'react';
import ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';

export const PaymentProvider = ({ childWindow }) => {
    const { t } = useTranslation();

    useEffect(() => {
        childWindow.focus();
        window.addEventListener('click', clicked);
        return (() => {
            window.removeEventListener('click', clicked);
        });
    }, []);

    const clicked = () => {
        childWindow.focus();
    };

    const style = {
        background: "black",
        opacity: 0.6,
        height: "100vh",
        left: 0,
        position: "fixed",
        top: 0,
        width: "100vw"
    }
    return ReactDOM.createPortal(
        <Stack sx={style} direction="row" alignItems="center" justifyContent="center">
            <Typography sx={{ color: 'white', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: t('payment.blockerMessage') }}></Typography>
        </Stack >,
        document.getElementById("portal")
    );
};

export const openPaymentWindow = (payment) => {
    let url = `/webstore/service/v1/payment/redirect?location=${encodeURIComponent(payment._links.approve.href)}`;
    let width = 800;
    let height = 800;
    let winLeft = window.screenLeft || window.screenX || 0;
    let winTop = window.screenTop || window.screenY || 0;
    let winWidth = window.innerWidth || window.screen.width;
    let winHeight = window.innerHeight || window.screen.width;
    let left = winLeft + winWidth / 2 - width / 2;
    let top = winTop + (window.outerHeight - window.innerHeight) + winHeight / 2 - height / 2 - 40;
    let options = `left=${left}, top=${top}, width=${width}, height=${height}, menubar=no, status=no`;
    return window.open(url, payment.method, options);

};