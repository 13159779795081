import React from 'react';
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { setMotif, setMotifStructure } from '../redux/Actions.js';
import {
    assetOdtUrl,
    formxUserFieldValues,
    material,
    mergedMaterial,
    properties,
    selectedTemplate,
    templateMediaNames,
    textFlows,
    isMotifColoured,
    motifColumns,
    onlineId,
    adBoxTextFlows
} from '../redux/Selectors.js';

import { render } from '../common/ServerRenderer';
import { makeAsset } from '../frames/Formx';
import { Skeleton } from '@mui/material';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Preview = () => {

    const [previewUrl, setPreviewUrl] = useState(null);
    const dispatch = useDispatch();
    const _assetOdtUrl = useSelector((state) => assetOdtUrl(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _formxUserFieldValues = useSelector((state) => formxUserFieldValues(state));
    const _textFlows = useSelector((state) => textFlows(state));
    const _adBoxTextflows = useSelector(state => adBoxTextFlows(state));
    const _properties = useSelector((state) => properties(state));
    const _templateMediaNames = useSelector((state) => templateMediaNames(state));
    const _material = useSelector((state) => material(state));
    const _mergedMaterial = useSelector((state) => mergedMaterial(state));
    const _isMotifColoured = useSelector((state) => isMotifColoured(state));
    const _motifColumns = useSelector((state) => motifColumns(state));
    const _onlineId = useSelector((state) => onlineId(state));

    useEffect(() => {
        if (_formxUserFieldValues && _formxUserFieldValues.UserFields) {
            let imageFields = [];
            if (_material) {
                _templateMediaNames?.forEach(tmn => {
                    let mm = _mergedMaterial || _material;
                    let m = mm.find(it => {
                        let asset = makeAsset(it.shortName);
                        return asset.widget === tmn;
                    });
                    if (m) {
                        let imageUrl;
                        if (m.aicUrl) {
                            imageUrl = m.aicUrl.replace('ProxyServlet?url=', '');
                        }
                        else {
                            imageUrl = encodeURIComponent(m._links.material.href);
                        }
                        //                        imageFields.push('&setNamedMediaUrl' + tmn + '=' + encodeURIComponent(m.aicUrl || m._links.material.href));
                        imageFields.push('&setNamedMediaUrl' + tmn + '=' + imageUrl);
                        console.log('###### aicUrl', m.aicUrl);
                    }
                    // ?????
                    // else if (m = this.getImage(this.formxValues, tmn)) {
                    //     imageFields.push('&setNamedMediaUrl' + tmn + '=' + encodeURIComponent(m.url));
                    // }
                });
            }

            //            console.log("imageFields" + JSON.stringify(imageFields));
            const renderOptions = {};
            renderOptions.isMotifColoured = _isMotifColoured;
            if (_motifColumns && _selectedTemplate) {
                renderOptions.widthInMM = _selectedTemplate.mmWidths[_motifColumns - _selectedTemplate.minWidth];
            }
            render(
                _selectedTemplate,
                {
                    formxUserFieldValues: _formxUserFieldValues,
                    textFlows: _textFlows,
                    adBoxFields: _adBoxTextflows,
                    onlineId: _onlineId,
                    imageFields
                },
                _properties,
                _assetOdtUrl,
                renderOptions).then((result) => {
                    setPreviewUrl(result.preview);
                    if (result.creativeXml) {
                        //                        console.log('xxxxxx RENDE', JSON.stringify(parseMotifStructure(result.creativeXml)));
                        dispatch(setMotifStructure(result.creativeXml));
                    }
                    if (result.creativeParams && result.creativeRawText) {
                        dispatch(setMotif(result.creativeParams, result.creativeRawText));
                    }
                }, (err) => {
                    console.log(err);
                });
        }
    }, [_formxUserFieldValues, _textFlows, _adBoxTextflows, _templateMediaNames, _material, _isMotifColoured, _motifColumns, _selectedTemplate]);

    const style = {
        width: "100%",
        height: "auto"
    };

    return (
        _selectedTemplate && previewUrl ?
            <Box sx={style}>
                <img alt="" src={previewUrl} width="100%"></img>
            </Box> : <Skeleton variant="rectangular" animation="wave" height="100%" />
    );
}

export default Preview; 