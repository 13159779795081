
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./Reducers.js";
import { callAPIMiddleware, anotherMiddleware } from "./Middleware.js";

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION__ ?
    compose(
        applyMiddleware(thunk, anotherMiddleware, callAPIMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
    ) : compose(
        applyMiddleware(thunk, anotherMiddleware, callAPIMiddleware)
    );

const store = createStore(
    rootReducer,
    enhancers
);

export default store;
