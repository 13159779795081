import JSZip from "jszip";


const normalizeText = (text = '') => {
    return text;
}

/*const getAttributeContent = (node, attr, html, limit) => {
    let t = node.getAttribute(attr);
    if (html) {
        let div = document.createElement("div");
        div.innerHTML = t.replace(/<\/p>/gmi, " ");
        t = div.innerText.replace(/<br>/gmi, " ").replace(/\s+/g, " ").trim();
        if (limit && t.length > limit) {
            let b = t.lastIndexOf(" ", limit);
            if (b === -1) b = limit;
            t = t.substring(0, b) + " ...";
        }
    }
    return t;
};*/

const creativeParams = data => {
    var cp = {};
    for (var t = new DOMParser().parseFromString(data, "text/xml").firstElementChild.firstElementChild; t; t = t.nextElementSibling) {
        if (t.nodeName === "textFrame-with-overflow" || t.nodeName === "used-userfield-names" || t.nodeName === "used-image-names") {
            let nodeName = t.nodeName;
            if (t.nodeName === "used-userfield-names") {
                nodeName = "usedUserFieldNames";
            } else if (t.nodeName === "used-image-names") {
                nodeName = "usedImageNames";
            }
            cp[nodeName] = [];
            for (var o = t.firstElementChild; o; o = o.nextElementSibling) if (o.firstChild) cp[nodeName].push(o.firstChild.nodeValue);
        } else if (t.nodeName === "paragraph-tags") {
            cp[t.nodeName] = [];
            for (var oo = t.firstElementChild; oo; oo = oo.nextElementSibling) cp[t.nodeName].push(
                { "name": oo.firstChild ? oo.firstChild.nodeValue : "", "lines": oo.getAttribute("lines"), "words": oo.getAttribute("words") });
        } else cp[t.nodeName] = t.firstChild ? t.firstChild.nodeValue : "";
    }
    return cp;
};


export const render = (
    template,
    data,
    properties,
    motifUrl,
    options,
    creativeUrl) => {

    return new Promise((resolve, reject) => {

        let odtUrl = creativeUrl || template.__source || template._links.source.href;
        let { formxUserFieldValues, textFlows, adBoxFields, onlineId, imageFields } = data;
        //       let uf = "";

        let at = {};
        if (formxUserFieldValues && formxUserFieldValues.UserFields) {
            formxUserFieldValues.UserFields.forEach(ta => {
                if (ta.value && ta.value.length) {
                    let v = normalizeText(ta.value);
                    at[ta.name] = { "value": v.replace(/\n/mg, "<br>") };
                }
            });
        }

        if (textFlows && textFlows.textFlows) {
            Object.keys(textFlows.textFlows).forEach(name => {
                let value = textFlows.textFlows[name];
                if (value) {
                    at[name] = { "value": value.replace(/\n/mg, "<br>") };
                }
            });
        }

        if (adBoxFields) {
            Object.keys(adBoxFields).forEach(ta => {
                let value = adBoxFields[ta];
                if (value) {
                    at[ta] = { "value": value.value.replace(/\n/mg, "<br>") };
                }
            });
        }

        if (onlineId) {
            if (onlineId.text && onlineId.text.length) {
                //                console.log("ServerRenderer set OnlineId " + onlineId.text);
                at['OnlineId'] = { "value": onlineId.text.replace(/\n/mg, "<br>") };
            }
        }

        let nm = "";
        if (imageFields) {
            imageFields.forEach(e => {
                nm += e;
            });
        }

        // https://dokuwiki.alfa.de/doku.php?id=de:stylo:serverrenderer_for_beginners
        let previewFormat = properties.previewFormat;
        let s = "";
        let f = previewFormat + ",stat";
        let body = "";
        if (motifUrl) {
            s = "&saveToPlus=" + motifUrl;
            f += ",odt,pdf,xml,rawtext";
            body = "doEmbed=true&";
        }
        let t = template.shortName;
        let isColoured = options?.isMotifColoured !== undefined ? options.isMotifColoured : t.defaultColorType !== "blackAndWhite";
        body += "format=" + f + "&xSize=400" + (properties.isWSS ? "" : "&color=" + String(isColoured)) + "&odtUrl=" + encodeURIComponent(odtUrl) + s + "&setAbstractText=" + encodeURIComponent(JSON.stringify(at)) + nm;
        //        _log('renderPreview: request:', template.shortName);
        if (options?.widthInMM) {
            body += `&width=${options.widthInMM * 1000}`;
        }
        fetch("/webstore/styloproxy/ServerRenderer", {
            "method": "POST",
            "headers": {
                "content-type": "application/x-www-form-urlencoded;charset=utf-8",
                "credentials": "same-origin"
            },
            "body": body
        }).then(res => {
            if (res.ok) {
                return res.blob();
            }
            else {
                reject(new Error("ServerRenderer failed"));
            }
        }).then(blob => {
            if (blob.size && blob.type === 'application/zip') {
                new JSZip().loadAsync(blob).then(zip => {
                    const parts = [];
                    const result = {};
                    parts.push(zip.file("the." + previewFormat).async("base64"));
                    parts.push(zip.file("thestat.xml").async("string"));
                    if (motifUrl) {
                        parts.push(zip.file("the.xml").async("string"));
                        parts.push(zip.file("therawtext.txt").async("string"));
                    }

                    Promise.all(parts)
                        .then(values => {
                            result.preview = "data:image/" + previewFormat + ";base64," + values[0];
                            if (values[1]) {
                                result.creativeParams = creativeParams(values[1]);
                            }
                            if (values[2]) {
                                result.creativeXml = values[2];
                            }
                            if (values[3]) {
                                result.creativeRawText = values[3];
                            }
                            resolve(result);
                        });

                }).catch(e => reject(e));
            }
        }).catch(e => reject(e));
    });
};

