import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Checkbox, FormControlLabel, Typography } from '@mui/material';

import AreaCardHeader from '../common/AreaCardHeader';
import PaymentPicker from './PaymentPicker'

import { businessPartner, features, isTermsAndConditionsConfirmed, properties } from '../../redux/Selectors.js';
import { confirmTermsAndConditions } from '../../redux/Actions.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderPaymentCard = ({ termsAndConditionsRequired }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _businessPartner = useSelector((state) => businessPartner(state));
    const _properties = useSelector((state) => properties(state));
    const _features = useSelector((state) => features(state));


    // terms and conditions checkbox 
    const _isTermsAndConditionsConfirmed = useSelector((state) => isTermsAndConditionsConfirmed(state));
    const [termsAndConditionsConfirmed, setTermsAndConditionsConfirmed] = useState(false);
    const handleTermsAndConditionsConfirmedChange = (e) => {
        setTermsAndConditionsConfirmed(e.target.checked);
        dispatch(confirmTermsAndConditions(e.target.checked));
    };
    const TermsAndConditions = () => {
        return (
            <FormControlLabel
                labelPlacement="end"
                label={<Typography
                    variant='termsAndConditions'
                    dangerouslySetInnerHTML={{ __html: t('order.checkbox1') }}></Typography>}
                control={
                    <Checkbox
                        sx={{ color: termsAndConditionsRequired ? 'error.main' : 'primary.main' }}
                        checked={termsAndConditionsConfirmed || _isTermsAndConditionsConfirmed}
                        onChange={handleTermsAndConditionsConfirmedChange}
                        size="small" />
                }
            />);
    };

    const hint = () => {
        return _features.GUEST_MODE.active ? 'order.notForBookingOrGuestAuthenticated' : 'order.notForBookingAuthenticated';
    };

    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('order.paymentCardTitle')} />
            {_businessPartner && (
                <CardContent>
                    <PaymentPicker />
                    <Box sx={{ m: 2 }} />
                    <TermsAndConditions />
                </CardContent>)}
            {!_businessPartner && (
                <CardContent sx={{
                    minHeight: '100%',
                    backgroundColor: 'action.disabled',
                    color: 'primary.contrastText',
                    padding: 12,
                }}>
                    < Typography
                        fontSize={14}
                        dangerouslySetInnerHTML={{ __html: t(hint()) }}
                    />
                </CardContent>)
            }
        </Card >
    );

}

