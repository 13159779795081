import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import InfoText from '../components/InfoText';
import OfferPicker from '../components/common/OfferPicker.jsx';
import { OfferLayouts } from '../components/order/OfferLayouts';
import { EditionsCard } from '../components/order/EditionsCard';
import { OrderPreviewCard } from '../components/order/OrderPreviewCard';
import { PriceCard } from '../components/common/PriceCard';
import { OfferInfoCard } from '../components/order/OfferInfoCard';
import MsgPopper from '../components/common/MsgPopper';
import { Box, Grid, Stack, Button, Typography } from '@mui/material';
import {
    offers,
    pendingOrder,
    selectedEditionIds,
    selectedOffer,
} from '../redux/Selectors.js';
import { calculateOfferPrice } from '../redux/Actions.js';
import { DEVICE_DESKTOP, getPage } from '../common/navigationTools';
import { useDebounce } from '../hooks/Hooks';
// import { OfferCard } from '../../components/common/Offer.jsx';

/**
 * @returns 
 */
export const Offers = () => {

    const { t } = useTranslation();

    const _offers = useSelector((state) => offers(state));
    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _selectedEditionIds = useSelector((state) => selectedEditionIds(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));

    const dispatch = useDispatch();

    const navigate = useNavigate();
    // const [height, setHeight] = useState(window.innerHeight - 500);

    const smallWidth = 900;

    const [upperHeight, setUpperHeight] = useState(((window.innerHeight - 195) / 3 * 2) - 5);
    const [lowerHeight, setLowerHeight] = useState(((window.innerHeight - 195) / 3) - 5);
    const [width, setWidth] = useState(window.innerWidth);
    const [layout, setLayout] = useState(_selectedOffer?.editionsSelectable ? 2 : 1);
    const [stackDirection, setStackDirection] = useState('row')
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [msgAnchorRef, setMsgAnchorRef] = useState(null);
    const [nextDisabled, setNextDisabled] = useState(true);
    const editionsCardRef = React.useRef(null);

    useEffect(() => {
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        setLayout(_selectedOffer?.editionsSelectable ? 1 : 2 /* 3*/);
    }, [_selectedOffer]);

    useEffect(() => {
        setNextDisabled(!validateCheckedEditions());
    }, [_selectedEditionIds.length]);

    /**
     * Hook to calculate offer prices
     */
    useEffect(() => {
        if (_pendingOrder && _offers) {
            calculateOfferPrices();
        }
    }, [_pendingOrder, _offers]);

    /**
     * 
     */
    const calculateOfferPrices = useDebounce(() => {
        _offers.forEach(o => {
            const order = Object.assign({}, _pendingOrder);
            if (order.marketId && order.motif) {
                // Angebotspez. Werte setzen:
                order.offerId = o._id;
                order.selectedEditionIds = o.editionsSelectable && _pendingOrder.selectedEditionIds && _pendingOrder.selectedEditionIds && _pendingOrder.selectedEditionIds.length > 0
                    ? _pendingOrder.selectedEditionIds.slice()
                    : o.preSelectedEditions.slice();
                order.selectedEditionIds = o.editionsSelectable && o.editions && o.editions.length > 0 && o.editions.indexOf(order.selectedEditionIds[0]) === -1
                    ? o.preSelectedEditions.slice()
                    : order.selectedEditionIds;
                // }
                if (order.templateId) {
                    dispatch(calculateOfferPrice(order, o));
                }
            }
        });
    }, 500);

    const validateCheckedEditions = (() => {
        let ok = true;
        if (_selectedOffer?.editionsSelectable) {
            if ((_selectedOffer?.minEditionsSelectable && _selectedEditionIds.length < _selectedOffer?.minEditionsSelectable) ||
                (_selectedOffer?.maxEditionsSelectable && _selectedEditionIds.length > _selectedOffer?.maxEditionsSelectable) ||
                (!_selectedOffer?.minEditionsSelectable && _selectedEditionIds.length < 1)) {
                ok = false;
            }
        }
        return ok;
    });


    const listenResize = () => {
        setUpperHeight(((window.innerHeight - 195) / 3 * 2) - 5);
        setLowerHeight(((window.innerHeight - 195) / 3) - 5);
        setWidth(window.innerWidth);
        setStackDirection(window.innerWidth < smallWidth ? 'column' : 'row');
    };

    const getPreviewCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : '25%'
        }
    };

    const getInfoCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : '50%'
        }
    };
    const getPriceCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : '25%'
        }
    };

    const buttonStyle = () => {
        return {
            /* marginRight: "1em",
             marginLeft: "1em",
             marginBottom: '1em',*/
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2
        }
    };
    // const SelectedOffer = ({ offer }) => {
    //     return (
    //         offer && (
    //             <Box onClick={next}>
    //                 <OfferCard offer={offer} highlight={true} properties={_properties} image={false} />
    //             </Box>
    //         ))
    // };

    const back = () => {
        navigate(getPage('creative', DEVICE_DESKTOP));
    };

    const next = () => {
        navigate(getPage('schedule', DEVICE_DESKTOP));
    };


    return (
        <>
            <Stack direction="column" spacing={5}>
                <AppHeader></AppHeader>
                <Box>
                    <Box sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }} elevation={4} >
                        <Grid container direction="row" rowSpacing={4} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, pr: 0, height: width < smallWidth ? "100%" : upperHeight, mr: 0, ml: 0 }}>
                                    <Stack
                                        direction={stackDirection}
                                        spacing={4}
                                        sx={{ width: '100%', height: '100%' }}>

                                        <Box sx={OfferLayouts(layout, upperHeight, width).offers} >
                                            <OfferPicker
                                                variant='xlarge'
                                                direction="vertical"
                                                height={upperHeight}
                                                doNotDisplayPrice="true"
                                                displayCurrentOffer="true"
                                                withNavigation="true"
                                                onClose={() => setLayout(1)} />
                                        </Box>
                                        <Box sx={OfferLayouts(layout, upperHeight, width).editions} >
                                            <EditionsCard height={upperHeight} width={width / 4} /*onClose={() => setLayout(1)}*/ />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, pr: 0, height: width < smallWidth ? "100%" : lowerHeight, mr: 0, ml: 0 }}>
                                    <Stack
                                        direction={stackDirection}
                                        spacing={4}
                                        sx={{ width: '100%', height: '100%', justifyContent: 'stretch' }}>
                                        <Box sx={getPreviewCardStyle()}>
                                            <OrderPreviewCard height={lowerHeight} width={width < smallWidth ? "100%" : width / 5} />
                                        </Box>
                                        <Box sx={getInfoCardStyle()}>
                                            <OfferInfoCard />
                                        </Box>
                                        <Box sx={getPriceCardStyle()} >
                                            <PriceCard displayPriceText={true} />
                                        </Box>

                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>

                                <Box width="100%" sx={buttonStyle()}>
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                        sx={{ width: '100%', justifyContent: 'stretch' }}
                                    >
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
                <AppFooter
                    action1={<Button variant="navigationBack" onClick={() => { back(); }} >{t('offer.back')}</Button>}
                    action2={<Button variant="navigationAction" disabled={nextDisabled} onClick={() => { next(); }} >{t('offer.next')}</Button>}
                >

                </AppFooter>
                <InfoText />
            </Stack >


            <MsgPopper
                open={msgOpen}
                arrow={true}
                anchorRef={msgAnchorRef}
                placement='left'
                text={msgText}
            >
            </MsgPopper>
        </>
    );
}

export default Offers;
