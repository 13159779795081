import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Box, Dialog } from '@mui/material';

export const OrderPaymentDialog = ({ open, payment }) => {

    const [src, setSrc] = useState(null);
    const iframe = useRef(null);

    useEffect(() => {
        if (payment) {
            let url = payment._links.approve.href;
            //let url = `/webstore/service/v1/payment/redirect?location=${encodeURIComponent(payment._links.approve.href)}`;
            setSrc(url);
        }
    }, [payment]);

    return (
        <Dialog fullScreen open={open}>
            {src && (
                <Box xsx={{ margin: 5, backgroundColor: 'blue' }}>
                    <iframe
                        style={{
                            backgroundColor: 'white',
                            height: "100vh",
                            left: 0,
                            top: 0,
                            width: "100vw",
                        }}
                        key={new Date().getTime()}
                        ref={iframe}
                        sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-top-navigation"
                        src={src}>
                    </iframe>
                </Box>)}
        </Dialog>
    );
}
