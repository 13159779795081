import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, FormControl, Grid, Typography, Box } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import { properties, selectedOffer, ordercriterias, _pendingOrder, pendingOrder } from '../../redux/Selectors';
import { CriteriaArea } from '../common/Criteria';
/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderCriteriaArea = ({ height }) => {
    const { t } = useTranslation();

    const _properties = useSelector((state) => properties(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _ordercriterias = useSelector((state) => ordercriterias(state));
    const _pendingOrder = useSelector((state) => pendingOrder(state));


    //   console.log(_properties);
    console.log(_selectedOffer);
    console.log(_ordercriterias);

    return (
        <>
            {(_ordercriterias?.length > 0 || _selectedOffer?.criteriaValues?.find(c => c.description === 'orderwithpatterncriteria'))
                && (<CriteriaArea criterias={_ordercriterias} offer={_selectedOffer} order={_pendingOrder} />)}
        </>
    );

}