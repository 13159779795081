import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { desiredDate, possibleDates, selectedOffer } from '../../redux/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import { DesiredDatePicker as DatePicker } from '../common/DesiredDatePicker';
import { setDesiredDate } from '../../redux/Actions';
import Grid from '@mui/material/Grid';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const DesiredDatePicker = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _desiredDate = useSelector((state) => desiredDate(state));
    const _possibleDates = useSelector((state) => possibleDates(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));

    return (
        <Box>
            <DatePicker
                startDate={_desiredDate}
                possibleDates={_possibleDates}
                displayAll={!_selectedOffer.publicationCalendar}
                onChange={(value) => dispatch(setDesiredDate(value))}
                variant="inputWithSteps" />
        </Box>
    );

}

export const StaticDesiredDatePicker = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _desiredDate = useSelector((state) => desiredDate(state));
    const _possibleDates = useSelector((state) => possibleDates(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));

    return (
        <Box>
            <DatePicker
                startDate={_desiredDate}
                possibleDates={_possibleDates}
                displayAll={!_selectedOffer.publicationCalendar}
                onChange={(value) => dispatch(setDesiredDate(value))}
                variant="static"
                monthsShown={props.monthsShown}
                orientation={props.orientation} />
        </Box>
    );

}