import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Typography, Stack } from '@mui/material';

import NavigationBar from './NavigationBar';
import InfoText from '../../components/InfoText';
import AppHeader from '../../components/AppHeader';

export const OrderConfirmationDialog = ({ open, handleClose, order, userName, displayDate }) => {

    const windowHeight = window.innerHeight;

    const { t } = useTranslation();

    return (
        <>
            {order && userName && (
                <Dialog fullScreen open={open}>
                    <Stack direction="column" alignItems="center" spacing={8}>
                        <AppHeader />
                        <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                            <Typography
                                sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold', textAlign: 'center' }}>{t('order.success.title')}</Typography>
                            <Typography
                                sx={{ textAlign: 'center' }}
                                dangerouslySetInnerHTML={{ __html: t('m.order.success', { displayDate: displayDate, orderCode: order.orderCode }) }}></Typography>

                            <Box sx={{ width: '100%' }}>
                                <Button sx={{ marginTop: '50vh', textTransform: 'uppercase', width: '100%' }} variant="contained" onClick={handleClose}>{t('order.newAd')}</Button>
                            </Box>
                        </Stack>
                        <NavigationBar />

                        <InfoText />
                    </Stack>
                </Dialog >)}
        </>
    );
}
