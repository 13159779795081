import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardActions, CardMedia, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdfOutlined';
import AreaCardHeader from '../common/AreaCardHeader';
import { assetBaseUrl, assetPreviewUrl, businessPartner, customerType, features, motifUuid, properties } from '../../redux/Selectors.js';
import { DEVICE_DESKTOP, getPage } from '../../common/navigationTools';

import {
    PenIcon
} from '../../components/common/DesktopIcons.jsx';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderPreviewCard = ({ height, width, printHandler }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const _assetBaseUrl = useSelector((state) => assetBaseUrl(state));
    const _assetPreviewUrl = useSelector((state) => assetPreviewUrl(state));
    const _customerType = useSelector((state) => customerType(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _features = useSelector((state) => features(state));
    const _motifUuid = useSelector((state) => motifUuid(state));
    const _properties = useSelector((state) => properties(state));

    const iconSx = () => {
        return { color: _properties.appPrimaryColor };
    };

    const isASE_1992 = () => {
        return _features && _features.ASE_1992 && _features.ASE_1992.status === "on";
    };

    const pdfUrl = (id) => {
        return _assetBaseUrl + '/' + id + '.pdf';
    };

    const pdf = async () => {
        document.getElementById('motif-download-as-pdf').click();
    };

    const pdfDisabled = () => {
        if (!Boolean(_businessPartner)) {
            return true;
        }
        else if (_customerType === 'private') {
            return true;
        }
        else {
            return false;
        }
    };


    return (
        <Card
            variant='outlined'
            sx={{}}>
            <AreaCardHeader
                sx={{ height: 38 }}
                titleTypographyProps={{ variant: 'areaCardHeader' }}
                title={t('order.previewCardTitle')}>
            </AreaCardHeader>
            <CardContent
                sx={{ height: printHandler ? height - 80 : height - 40 }}
                alignitems='center'>
                <CardMedia
                    component="img"
                    src={_assetPreviewUrl + '?t=' + Date.now()}
                    sx={{ maxHeight: printHandler ? height - 110 : height - 70, height: 'auto', maxWidth: width, width: 'auto', overflow: 'hidden', margin: 'auto' }}
                />

            </CardContent>
            <Divider />
            {printHandler && (<CardActions
                sx={{ height: 40 }} >
                <IconButton sx={iconSx()} size='small' onClick={printHandler} component="span">
                    <LocalPrintshopOutlinedIcon />
                </IconButton>
                {isASE_1992() && !pdfDisabled() && (
                    <div>
                        <IconButton sx={iconSx()} size="small" onClick={pdf}>
                            <PictureAsPdfIcon />
                        </IconButton>
                        <a style={{ visibility: "hidden" }}
                            id="motif-download-as-pdf"
                            href={pdfUrl(_motifUuid)}
                            target="_blank"
                            download={`${_motifUuid}.pdf`}>{_motifUuid}.pdf</a>
                    </div>)}
                <IconButton sx={iconSx()} style={{ marginLeft: 'auto' }} size="small" onClick={() => { navigate(getPage('creative', DEVICE_DESKTOP)) }}>
                    <PenIcon />
                </IconButton>
            </CardActions>)
            }
        </Card >
    );

}