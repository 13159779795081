import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Card, Stack, Box, CardMedia, CardContent, Typography, Grid, Button, Divider,
    Backdrop, CircularProgress
} from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import { orders, orderCancelled, properties, customerType, isWSS } from '../../redux/Selectors';
import { fetchOrders, cancelOrder } from '../../redux/Actions';
import { cutCatchword } from '../../common/tools';
import AppHeader from '../../components/AppHeader';
import RegistrationPaging from '../components/RegistrationPaging';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { ConfirmationDialog } from '../../components/ModalDialog';

export const Orders = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _orders = useSelector(state => orders(state));
    //    const period = useSelector(state => ordersPeriod(state));
    const _orderCancelled = useSelector(state => orderCancelled(state));
    const _properties = useSelector(state => properties(state));
    const _customerType = useSelector(state => customerType(state));
    const _isWSS = useSelector(state => isWSS(state));

    const [refreshCount, setRefreshCount] = useState(1);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [selectedOrder, setSelectedOrder] = useState();
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [ordersInitialized, setOrdersInitialized] = useState(false);
    const period = _isWSS ? 'month' : 'year';


    useEffect(() => {
        setBackdropOpen(true);
        setOrdersInitialized(false);
        dispatch(fetchOrders(period, refreshCount));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRefreshCount(refreshCount + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]
    );

    useEffect(() => {
        setBackdropOpen(true);
        console.log("fetch orders ...");
        setOrdersInitialized(false);
        dispatch(fetchOrders(period, refreshCount));
        //       setPending(true);
        setRefreshCount(refreshCount + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderCancelled]);

    useEffect(() => {
        console.log(_orders);
        if (/*_orders?.length > 0 && */ ordersInitialized === true) {
            console.log("setBackdropOpen false");
            setBackdropOpen(false);
        } else {
            setOrdersInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orders]);


    const isCancelable = order => {
        return Boolean(order && order._links.cancel);
    };

    const handleCancelClick = (order) => {
        setSelectedOrder(order)
        setMsg(t('m.order.cancelConfirmation'));
        setConfirmationDialogOpen(true);
    }

    const okAction = (e) => {
        console.log("Do cancel ... ");
        let url = selectedOrder._links.cancel.href.replace('{id}', selectedOrder._id);
        console.log("cancel with url " + selectedOrder._links.cancel.href);
        dispatch(cancelOrder(url, false));
        setMsg('');
        setConfirmationDialogOpen(false);
    }

    const cancelAction = (e) => {
        setMsg('');
        setConfirmationDialogOpen(false);
        setSelectedOrder();
    }

    const getSwiperStyle = () => {
        return {
            "--swiper-pagination-color": _properties.appPrimaryColor,
            "--swiper-navigation-color": _properties.appPrimaryColor,
            width: '100%',
            "paddingBottom": "40px",
            "paddingTop": "15px"
        }
    }

    const OrderCard = ({ order }) => {
        return (
            <Card
                key={order.orderCode}
                sx={{ height: '100%', border: 'none', boxShadow: 'none', cursor: 'pointer' }}>
                <Box sx={{ height: '100%', border: 1, borderRadius: 2.5, borderColor: '#A0A0A0', p: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Stack>
                        <Box sx={{ m: 4, height: '25px' }}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Typography variant="mobileOrderCardTitle">{order.orderCode}</Typography>
                                {isCancelable(order) && (<Button
                                    variant="cancelOrder"
                                    onClick={() => { handleCancelClick(order); }}
                                    color="inherit">
                                    {t('m.order.cancel')}
                                </Button>)}
                            </Stack>
                        </Box>
                        <Box sx={{ height: '25vh', m: 3, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <CardMedia
                                component="img"
                                image={order._links?.preview.href}
                                sx={{ maxHeight: '20vh', height: 'auto', width: 'auto', maxWidth: '80vw', overflow: 'hidden', margin: 'auto', m: 4 }}
                            />
                        </Box>
                        <CardContent sx={{ height: '25vh', pt: 1, textAlign: 'left' }}>
                            < Grid container >
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('order.orderCatchword')}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {cutCatchword(order.orderCatchword, 30)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('order.editionPart')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {order.effectiveEditionPartNames[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {order.effectiveDates.length === 1 ? t('m.customer.order.date') : t('m.customer.order.dates')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    {order.effectiveDates.length > 1 && (<Typography variant="mobileTextBoxLabel" >
                                        {
                                            order.effectiveDates.map(it => {
                                                return (
                                                    <Box key={it}>
                                                        <Typography variant="mobileTextBoxLabel">
                                                            {t('common.date.short', { value: new Date(it.substring(0, 10)) })}
                                                        </Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Typography>)}
                                    {order.effectiveDates.length === 1 && (<Typography variant="mobileTextBoxLabel" >
                                        {t('common.date.short', { value: new Date(order.effectiveDates[0].substring(0, 10)) })}
                                    </Typography>)}
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {order.effectiveEditionNames.length === 1 ? t('m.customer.order.edition') : t('m.customer.order.editions')}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    {order.effectiveEditionNames.length > 1 && (<Typography variant="mobileTextBoxLabel">
                                        {order.effectiveEditionNames.map(en => {
                                            return (
                                                <Box key={en}>
                                                    <Typography variant="mobileTextBoxLabel">
                                                        {en}
                                                    </Typography>
                                                </Box>
                                            )
                                        })}
                                    </Typography>)}
                                    {order.effectiveEditionNames.length === 1 && (<Typography variant="mobileTextBoxLabel" >
                                        {order.effectiveEditionNames[0]}
                                    </Typography>)}
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography sx={{ fontWeight: 'bold' }} variant="mobileTextBoxLabel">
                                        {_customerType === 'private' ? t('m.order.price') : t('m.order.price.net')}:
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography sx={{ fontWeight: 'bold' }} variant="mobileTextBoxLabel" >
                                        {t('global.currency.' + (order.orderPrice.currency ? order.orderPrice.currency : 'EUR'), { value: order.orderPrice.price })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Stack>
                </Box>
            </Card>
        );
    }

    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ minHeight: windowHeight - 167 }} spacing={4} width='100%'>
                <RegistrationPaging step={0} pagetitle='m.customer.orders.title' />
                <Divider />

                {_orders.length > 0 && (<Stack width='100%' alignItems='center'>
                    <Box width='90%' >
                        <Swiper
                            slidesPerView='1'
                            spaceBetween={10}
                            keyboard={{
                                enabled: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Keyboard, Pagination, Navigation]}
                            className="mySwiper"
                            direction={"horizontal"}
                            style={getSwiperStyle()}
                        >
                            {_orders.slice(0, 10).map(item =>
                                <SwiperSlide key={_isWSS ? item.orderCode + item.orderPosNr : item.orderCode} width='90%'>
                                    <OrderCard order={item} key={item.orderCode} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Box>
                </Stack>)}
                {_orders.length === 0 && (<Typography sx={{ pl: 4 }} variant="mobilePagePrimaryLabel">{t('m.customer.orders.empty')}</Typography>)}
            </Stack>

            <NavigationBar
                back={{ clicked: () => navigate(-1), label: t('m.customer.orders.back') }}>
            </NavigationBar>
            <InfoText />
            <ConfirmationDialog open={confirmationDialogOpen} msg={msg} okAction={okAction} cancelAction={cancelAction} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
}

export default Orders;