import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { device } from '../../redux/Selectors';
export const OrderProgressDialog = ({ open }) => {

    const { t } = useTranslation();

    const _device = useSelector((state) => device(state));

    return (
        <Dialog fullScreen open={open}>
            <Paper
                sx={{ minHeight: _device === 'mobile' ? '100%' : '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >

                <Typography
                    sx={{ margin: '3vh', fontSize: 36, fontWeight: 'bold', maxWidth: _device === 'mobile' ? '90%' : '50vh', textAlign: 'center' }}>
                    {t('order.progress.title')}
                </Typography>
                <CircularProgress sx={{ margin: '2vh' }}></CircularProgress>
                <Typography
                    sx={{ textAlign: 'center', maxWidth: _device === 'mobile' ? '90%' : '50vh' }}>{t(_device === 'mobile' ? 'order.progress.message.mobile' : 'order.progress.message')}
                </Typography>
            </Paper >
        </Dialog>
    );
}
