import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import axios from 'axios';

import {
    authentication,
    authRefresh,
    device,
    features,
    isBusinessPartnerAuthenticated,
    isGuestAuthenticated,
    isGuestAuthenticationOnly,
    isSsoAuthentication,
    properties,
    selectHasAdvertizers,
    services,
    sessionTimeoutSeconds,
    userName,
    userProfile,
} from '../redux/Selectors.js';

import {
    getAuthentication,
    fetchBusinessPartner,
    fetchUserProfile,
    logout,
    setCustomerTab
} from '../redux/Actions.js';

import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Typography
} from '@mui/material';

import { AdvertizersSelect } from './customer/Advertizers.jsx';
import ModalDialog from './ModalDialog.js';
import { LoginIcon, LogoutIcon, AccountIcon, OrdersIcon, DraftsIcon, InvoiceIcon, AdvertizersIcon } from '../common/widgetTools.js';

import { getPage } from '../common/navigationTools.js';

import { useTranslation } from 'react-i18next';
import '../configuration/i18n';

import ExternalAuthentication from '../frames/Authentication';

const AuthenticationStatus = ({ menuAnchor }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const _hasAdvertizers = useSelector((state) => selectHasAdvertizers(state));
    const _authentication = useSelector((state) => authentication(state));
    const _authRefresh = useSelector((state) => authRefresh(state));
    const _device = useSelector(state => device(state));
    const _features = useSelector((state) => features(state));
    const _isBusinessPartnerAuthenticated = useSelector((state) => isBusinessPartnerAuthenticated(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const _isGuestAuthenticationOnly = useSelector((state) => isGuestAuthenticationOnly(state));
    const _isSsoAuthentication = useSelector((state) => isSsoAuthentication(state));
    const _properties = useSelector((state) => properties(state));
    const _services = useSelector((state) => services(state));
    const _sessionTimeoutSeconds = useSelector((state) => sessionTimeoutSeconds(state));
    const _userName = useSelector((state) => userName(state));
    const _userProfile = useSelector((state) => userProfile(state));

    const [heartbeat, setHeartbeat] = useState(null);
    const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false);
    const [loginWindowIsOpen, setLoginWindowIsOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [isCustomerPageLocation, setIsCustomerPageLocation] = useState(false);
    const isMenuOpen = Boolean(menuAnchorEl);

    const [authenticationTimeout, setAuthenticationTimeout] = useState(null);
    const [authTimeoutDialogIsOpen, setAuthTimeoutDialogIsOpen] = useState(false);

    const CUSTOMER_PAGE_LOCATION = getPage('customer', _device);
    const REGISTRATION_PAGE_LOCATION = getPage('register', _device);

    const isMounted = React.useRef(true);

    useEffect(() => {
        /*     setIsCustomerPageLocation(location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)
                 || location.pathname.startsWith(getPage('register-mail', _device))
                 || location.pathname.startsWith(getPage('register-contact', _device))
                 || location.pathname.startsWith(getPage('register-payment', _device))); */
        isMounted.current = true;
        return (() => {
            isMounted.current = false;
        });
    }, []);

    useEffect(() => {
        if (_authentication) {
            if (_authentication.loggedOut) {
                document.getElementById('sso-status').src = '';
                //                dispatch(getAuthentication());
            }
            else if (_authentication.businessPartnerNumber) {
                dispatch(fetchBusinessPartner(_authentication.businessPartnerNumber));
            }
            else if (_isSsoAuthentication) {
                dispatch(fetchUserProfile());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_authentication]);

    useEffect(() => {
        setIsCustomerPageLocation(location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)
            || location.pathname.startsWith(getPage('register-mail', _device))
            || location.pathname.startsWith(getPage('register-contact', _device))
            || location.pathname.startsWith(getPage('register-payment', _device)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const AuthTimeoutDialog = () => {
        return (
            <Dialog
                open={authTimeoutDialogIsOpen}
            >
                <Alert severity="warning">{t('auth.sessionExpired.title')}</Alert>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="text">{t('auth.sessionExpired')}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={handleCloseAuthTimeoutDialog}>{t('main.ok')}</Button>
                </DialogActions >
            </Dialog >

        );
    };

    const handleCloseAuthTimeoutDialog = () => {
        setAuthTimeoutDialogIsOpen(false);
        dispatch(logout())
            .then(() => { navigate(getPage('templates', _device)) });
    };

    const handleAuthTimeout = () => {
        setAuthenticationTimeout(setTimeout(() => {
            setAuthTimeoutDialogIsOpen(true);
        }, _authentication.sessionTimeoutSeconds * 1000));
    };

    useEffect(() => {
        if (_authentication && _authentication.sessionTimeoutSeconds) {
            //            handleAuthTimeout();
        }
    }, [_authentication]);

    useEffect(() => {
        if (_authRefresh) {
            if (_authentication && _authentication.sessionTimeoutSeconds && authenticationTimeout) {
                clearTimeout(authenticationTimeout);
                handleAuthTimeout();
            }
        }
    }, [_authRefresh]);


    useEffect(() => {
        if (_userProfile) {
            if (_userProfile.legalEntityType) {
                //                dispatch(setAuthentication(Object.assign({}, authentication, { "customerType": userProfile.legalEntityType.toLowerCase() })));
            }
            else {
                navigate(REGISTRATION_PAGE_LOCATION);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_userProfile]);

    useEffect(() => {
        //        document.getElementById('sso-status').src = '/webstore/service/v1/authentication/sso/status';
        window.addEventListener('message', oauthHandler);
        return (() => {
            window.removeEventListener('message', oauthHandler);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // heartbeat to avoid session timeout
        if (_sessionTimeoutSeconds) {
            setHeartbeat(setInterval(() => {
                axios.get('/webstore/service/index')
                    .then(res => {
                    });
            }, _sessionTimeoutSeconds * 1000 - 1000));
        }
        return (() => {
            if (heartbeat) {
                clearInterval(heartbeat);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_sessionTimeoutSeconds]);

    const oauthHandler = (event) => {
        switch (event.data) {
            case "oauthSuccess":
                dispatch(getAuthentication());
                setLoginWindowIsOpen(false);
                break;
            case "oauthCancelled":
                setLoginWindowIsOpen(false);
                break;
            case "oauthError":
                setLoginWindowIsOpen(false);
                break;
            default:
                break;
        }
    };

    const handleMenuClose = () => {
        if (isMounted.current) {
            setMenuAnchorEl(null);
        }
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(document.getElementById(menuAnchor));
    };

    // function to handle modal open
    const handleOpenLogin = () => {
        if (_features.OAUTH2_AUTHENTICATION && _features.OAUTH2_AUTHENTICATION.active) {
            setLoginWindowIsOpen(true);
        }
        else {
            setLoginDialogIsOpen(true);
        }
    };

    const handleLogout = async () => {
        handleMenuClose();
        if (_authentication?.clientLogoutUrl) {
            document.getElementById('sso-status').src = _authentication.clientLogoutUrl;
        }
        dispatch(logout());
        if (isCustomerPageLocation) {
            navigate(-1);
        }
    }

    const handleOpenCustomerPage = (tab) => {
        if (location.pathname !== CUSTOMER_PAGE_LOCATION) {
            navigate(CUSTOMER_PAGE_LOCATION);
            dispatch(setCustomerTab(tab));
        }
    }

    // function to handle modal close
    const handleClose = (data) => {
        dispatch(getAuthentication());
        setLoginDialogIsOpen(false);
        setLoginWindowIsOpen(false);
    };

    const DisplayName = () => {
        let name = !_isGuestAuthenticated || _userName?.length > 0 ? _userName : '';
        if (name && 'mobile' === _device) {
            name = name.split(' ')[0];
        }

        //        return (
        //     name ?
        //         <Stack direction="row" spacing={2} alignItems="center">
        //             <AccountIcon color="primary"></AccountIcon>
        //             <Typography variant="text">{name}</Typography>
        //         </Stack>
        //         :
        //         <></>
        // );

        if (_hasAdvertizers && !_isGuestAuthenticated) {
            name += ' ' + t('status.for');
        }

        return (
            name ?
                < Button
                    variant="menu"
                    disabled={isCustomerPageLocation}
                    onClick={handleMenuOpen}
                    startIcon={<AccountIcon />}> {name}
                </Button >
                :
                <></>
        );
    };

    const LoginButton = () => {

        if (_isGuestAuthenticationOnly || _isBusinessPartnerAuthenticated) {
            return <></>
        }
        else if ('desktop' === _device) {
            return <Button
                variant="menu"
                color="inherit"
                onClick={handleOpenLogin}
                startIcon={<LoginIcon />}>
                {t('status.login')}
            </Button>
        }
        else if ('mobile' === _device) {
            return <IconButton onClick={handleOpenLogin} color="primary" >
                <LoginIcon />
            </IconButton>
        }

    };

    const menuId = 'menu';

    const CustomerMenu = () => {
        return (
            <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuList
                    dense
                >
                    <MenuItem
                        disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                        onClick={() => handleOpenCustomerPage('home')}
                        key={'CustomerData'}>
                        <ListItemIcon><AccountIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.myAccount')}</ListItemText>
                    </MenuItem>
                    {_device === 'desktop' && (<MenuItem
                        disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                        onClick={() => handleOpenCustomerPage('orders')}
                        key={'Orders'}>
                        <ListItemIcon><OrdersIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.myOrders')}</ListItemText>
                    </MenuItem>)}
                    {_device === 'desktop' && (<MenuItem
                        disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                        onClick={() => handleOpenCustomerPage('drafts')}
                        key={'Drafts'}>
                        <ListItemIcon><DraftsIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.myDrafts')}</ListItemText>
                    </MenuItem>)}
                    {_device === 'desktop' && (<MenuItem
                        disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                        onClick={() => handleOpenCustomerPage('invoices')}
                        key={'Invoices'}>
                        <ListItemIcon><InvoiceIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.myInvoices')}</ListItemText>
                    </MenuItem>)}
                    {_hasAdvertizers && _device === 'desktop' && (<MenuItem
                        disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                        onClick={() => handleOpenCustomerPage('advertizers')}
                        key={'Advertizers'}>
                        <ListItemIcon><AdvertizersIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('customerdata.advertizers.title')}</ListItemText>
                    </MenuItem>)}
                    {!_isGuestAuthenticated && (<MenuItem
                        disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                        onClick={handleLogout}
                        key={'Logout'}>
                        <ListItemIcon><LogoutIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.logout')}</ListItemText>
                    </MenuItem>)}

                </MenuList>
            </Menu >
        )
    };

    return (
        <Box id="ID_AUTHENTICATION_STATUS">
            {/* <span style={{ xdisplay: 'none' }}>{_businessPartner?.businessPartnerNo || ''}</span>
            <span style={{ xdisplay: 'none' }}>{ssoStatusSrc || ''}</span> */}
            <DisplayName />
            {_hasAdvertizers && (
                <AdvertizersSelect>
                </AdvertizersSelect>
            )}
            {!isCustomerPageLocation && (<CustomerMenu />)}
            {!_isGuestAuthenticated && (<LoginButton />)}
            {loginDialogIsOpen && (
                <ModalDialog open={loginDialogIsOpen} handleClose={handleClose} title={t('login.title')} />
            )}
            {loginWindowIsOpen && (
                <ExternalAuthentication url={_services.ssoLogin.href.replace(window.location.href, '')} closeHandler={handleClose} properties={_properties} />
            )}
            <AuthTimeoutDialog></AuthTimeoutDialog>
        </Box >
    );
}

export default AuthenticationStatus;