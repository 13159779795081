import { REFRESH_AUTHENTICATION } from './Actions';

export const callAPIMiddleware = ({ dispatch, getState }) => {
	return next => action => {
		const {
			types,
			callAPI,
			shouldCallAPI = () => true,
			payload = {},
		} = action;
		if (!types) {
			// Normal action: pass it on
			return next(action);
		}
		if (!Array.isArray(types) || types.length !== 3 || !types.every(type => typeof type === 'string')) {
			throw new Error('Expected an array of three string types.');
		}
		if (typeof callAPI !== 'function') {
			throw new Error('Expected callAPI to be a function.');
		}
		if (!shouldCallAPI(getState())) {
			return;
		}
		const [requestType, successType, failureType] = types;
		dispatch(
			Object.assign({}, payload, {
				type: requestType,
			})
		);
		dispatch({ type: REFRESH_AUTHENTICATION });
		return callAPI()
			.then(response => {
				if (!response.ok) {
					switch (response.status) {
						case 400:
						case 401:
						case 403:
						case 500:
							return dispatch(
								Object.assign({}, payload, {
									json: response,
									type: failureType,
								}));
						default:
							throw Error(response.statusText);
					}
				}
				return response;
			})
			.then(
				response => {
					let contentType = response.headers ? response.headers.get('content-type') : null;
					if (contentType) {
						if (contentType === 'text/xml') {
							return response.text().then(text => {
								const parser = new DOMParser();
								return dispatch(
									Object.assign({}, payload, {
										document: parser.parseFromString(text, 'application/xml'),
										type: successType,
									})
								);
							});
						}
						if (contentType.indexOf('text/html') !== -1) {
							return response.text().then(html => {
								return dispatch(
									Object.assign({}, payload, {
										html,
										type: successType,
									})
								);
							});
						}
						else {
							return response.json().then(json => {
								return dispatch(
									Object.assign({}, payload, {
										json,
										type: successType,
									})
								);
							});
						}
					}
					else {
						return dispatch(
							Object.assign({}, payload, {
								type: successType,
							})
						);
					}
				},
				error =>
					dispatch(
						Object.assign({}, payload, {
							json: error,
							type: failureType,
						})
					)
			);
	};
};


// standard middleware definition, with 3 nested functions:
// 1) Accepts `{dispatch, getState}`
// 2) Accepts `next`
// 3) Accepts `action`
export const anotherMiddleware =
	({ dispatch, getState }) =>
		next =>
			action => {
				// If the "action" is actually a function instead...
				if (typeof action === 'function') {
					// then call the function and pass `dispatch` and `getState` as arguments
					return action(dispatch, getState)
				}

				// Otherwise, it's a normal action - send it onwards
				return next(action)
			}
