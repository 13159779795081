import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Dialog,
    IconButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';

import { useSelector, useDispatch } from 'react-redux';
import { businessPartner, paymentTypes, preferredBankAccount, bankAccounts, orderToEdit, selectedPaymentMethod } from '../../redux/Selectors.js';
import { setPaymentMethod, setCustomerTab } from '../../redux/Actions';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import { Typography } from '@mui/material';
import { DEVICE_DESKTOP, getPage } from '../../common/navigationTools.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PaymentPicker = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _businessPartner = useSelector((state) => businessPartner(state));
    const _paymentTypes = useSelector((state) => paymentTypes(state));
    const _preferredBankAccount = useSelector((state) => preferredBankAccount(state));
    const _bankAccounts = useSelector((state) => bankAccounts(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _selectedPaymentMethod = useSelector((state) => selectedPaymentMethod(state));

    const [paymentTypeShortName, setPaymentTypeShortName] = useState('');

    useEffect(() => {
        setPaymentTypeShortName(_selectedPaymentMethod);
    }, [_selectedPaymentMethod]);


    const handleChanged = (e) => {
        dispatch(setPaymentMethod(e.target.value));
    };

    const editIconClicked = (e) => {
        //        navigate('/webstore/app/customer/payment');
        dispatch(setCustomerTab('payment'));
        navigate(getPage('customer', DEVICE_DESKTOP));
    };

    const bankAccountList = () => {
        let s = '';
        for (let i = 0; i < _bankAccounts.length; ++i) {
            let iban = _bankAccounts[i].ibanHidden;
            s += `Konto ${i + 1} ${iban} <br/>`;
        };
        return s;
    }

    return (
        <RadioGroup
            alignitems="center"
            sx={{
                width: '100%',
                display: 'flex',
                flexWrap: 'nowrap',
                overflowX: 'scroll',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }}
            onChange={handleChanged}
            value={paymentTypeShortName}
        >
            {_paymentTypes.map(it => {
                return (<FormControlLabel
                    xsx={{ backgroundColor: 'primary.main', borderRadius: '20px', padding: '0px' }}
                    disabled={_orderToEdit}
                    key={it.shortname}
                    value={it.shortname}
                    control={<Radio small="true" />}
                    label={
                        <Box
                            alignitems="center"
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                flexDirection: 'row',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <Typography sx={{ whiteSpace: 'nowrap' }} variant='termsAndConditions' >{it.name}</Typography>
                            {it.ibanPayment && _preferredBankAccount && (<Typography sx={{ ml: 5, whiteSpace: 'nowrap' }} variant='termsAndConditions' >{_preferredBankAccount.ibanHidden}</Typography>)}
                            {it.ibanPayment && (
                                <Tooltip placement="bottom" arrow title={<Typography dangerouslySetInnerHTML={{ __html: bankAccountList() }}></Typography>}>
                                    <ListIcon sx={{ ml: 5 }}></ListIcon>
                                </Tooltip>
                            )}
                            {it.ibanPayment && (<EditIcon onClick={editIconClicked} sx={{ mr: 5 }} />)}
                        </Box>
                    }
                />)
            })}
        </RadioGroup >
    );
}

export default PaymentPicker;
