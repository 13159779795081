import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
    assetOdtUrl,
    device,
    motifColumns,
    upsellingColumns,
    pageDimension,
    properties,
} from '../../redux/Selectors.js';
import {
    setTemplate,
    setAdBoxNumber,
    setMotifColumns,
    setUpsellingColumns,
    resetEditedOrder,
    resetRepeatedOrder,
} from '../../redux/Actions.js';

import { fetchTemplateDependencies } from '../../service/CreativeService';
import { DesignAlternative } from './AlternativesPicker';
import { UpsellingTool } from './UpsellingTool';

/**
 * @returns 
 */
export const UpsellingComponent = ({ handleComponentClose, selectedTemplate, alternatives }) => {

    const pageOffset = 250;
    const pageMargin = 1;
    const itemBorderWidth = 4;
    const columnGridSpacing = 10;

    const [mmToPx, setMmToPx] = useState(2);
    const [dimension, setDimension] = useState({});
    const [columnCount, setColumnCount] = useState(0);
    const [columnGap, setColumnGap] = useState(0);
    const [columnWidth, setColumnWidth] = useState(0);
    const [columns, setColumns] = useState([]);
    const [columnHeight, setColumnHeight] = useState(0);
    const [pageWidth, setPageWidth] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _assetOdtUrl = useSelector((state) => assetOdtUrl(state));
    const _device = useSelector((state) => device(state));
    const _motifColumns = useSelector((state) => motifColumns(state));
    const _upsellingColumns = useSelector((state) => upsellingColumns(state));
    const _pageDimension = useSelector((state) => pageDimension(state));
    const _properties = useSelector((state) => properties(state));

    const [items, setItems] = useState();
    const [favorite, setFavorite] = useState();
    //    const [width, setWidth] = useState(1);

    const [sized, setSized] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (alternatives) {
            let its = alternatives.map(it => {
                return it.unitWidth === 'COLUMNS' && it.minWidth !== it.maxWidth ? Object.assign({}, it, { __width: it.minWidth }) : it;
            });
            // ausgewähltes Muster an erste stellen setzen
            its.unshift(selectedTemplate.unitWidth === 'COLUMNS' && selectedTemplate.minWidth !== selectedTemplate.maxWidth ? Object.assign({}, selectedTemplate, { __width: selectedTemplate.minWidth }) : selectedTemplate);
            if (_motifColumns) {
                its[0].__width = _motifColumns;
            }
            its[0].__source = _assetOdtUrl;
            setItems(its);
            setFavorite(its[0]);
        }
        window.addEventListener('resize', listenResize);
        window.addEventListener('keydown', keyDown);
        return (() => {
            window.removeEventListener('resize', listenResize);
            window.removeEventListener('keydown', keyDown);
            dispatch(setUpsellingColumns(1));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const keyDown = (event) => {
        if (event.code == 'KeyA' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
        }
    };

    const setupPage = () => {
        let count = dimension.columnCount;
        let width = dimension.columnWidth;
        let gap = dimension.columnGap;
        setColumnCount(count);
        setColumnWidth(width * mmToPx); // mm -> px
        setColumnGap(gap * mmToPx);
        let pwPx = mmToPx * ((count * width) + ((count - 1) * gap));
        setPageWidth(pwPx);

        const c = [];
        for (let i = 0; i < count; ++i) {
            c.push(i);
        }
        setColumns(c);

        setColumnHeight(`${window.innerHeight - pageOffset - (2 * columnGridSpacing)}px`);
        setPageHeight(`${window.innerHeight - pageOffset}px`);

    };

    useEffect(() => {
        if (_device) {
            setMmToPx('desktop' === _device ? 2 : 1);
        }
    }, [_device]);

    useEffect(() => {
        if (_pageDimension) {
            setDimension(_pageDimension);
        }
    }, [_pageDimension]);

    useEffect(() => {
        setupPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimension]);

    useEffect(() => {
        setupPage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sized]);

    const listenResize = () => {
        setSized(Math.random);
    };

    const Column = styled(Box)(({ theme }) => ({
        backgroundColor: '#AEAEAE65',
        ...theme.typography.body2,
        //        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: `${columnWidth}px`,
        height: columnHeight
    }));

    const Space = styled(Box)(({ theme }) => ({
        backgroundColor: 'white',
        ...theme.typography.body2,
        //        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: `${columnGap}px`,
        height: columnHeight
    }));

    const PageColumn = ({ i }) => {
        return (
            <Stack direction="row" >
                <Column></Column>
                {i < columnCount && (<Space></Space>)}
            </Stack>);
    };

    const itemSelected = (e, item) => {
        setFavorite(item);
        setAnchorEl(e.currentTarget);
    };

    const itemWidth = item => {
        let w = item.minWidth !== item.maxWidth ? item.__width || item.minWidth : item.minWidth;
        let iw = item.__creativeParams ? item.__creativeParams['doc-width-1000mm'] * mmToPx / 1000 : columnWidth * w + (columnGap * (w - 1));
        iw += itemBorderWidth * 2;
        return iw;
    };

    const itemStyle = item => {
        if (item && favorite) {
            let w = `${itemWidth(item)}px`;
            let b = item._id === favorite._id ? `${itemBorderWidth}px solid ${_properties.appSecondaryColor}` : `${itemBorderWidth}px dotted transparent`;
            let c = item._id === favorite._id ? undefined : 'pointer';
            let o = item._id === favorite._id ? 1 : 0.65;

            return (
                {
                    cursor: c,
                    opacity: o,
                    border: b,
                    maxWidth: w,
                });
        }
    };

    const onPopperDismiss = () => {
        setAnchorEl(null);
    };

    const onPopperCheck = () => {
        setAnchorEl(null);

        if (selectedTemplate._id === favorite._id && _upsellingColumns === favorite.defaultWidth) {
            handleComponentClose('next');
        }
        else {
            dispatch(resetRepeatedOrder());
            dispatch(resetEditedOrder());
            fetchTemplateDependencies(favorite).then(id => {
                dispatch(setTemplate(id));
                dispatch(setAdBoxNumber());
                dispatch(setMotifColumns(_upsellingColumns));
            });
            handleComponentClose('back');
        }
    };

    const onPopperWidth = (col, item) => {
        setItems(items.map(it => {
            if (item._id === it._id) {
                return Object.assign({}, it, { __width: col });
            }
            else {
                return it;
            }
        }));
        setFavorite(Object.assign({}, favorite, { __width: col }));
        dispatch(setUpsellingColumns(col));
        //        setWidth(col);
    };

    const onRendered = creativeParams => {
        setFavorite(Object.assign({}, favorite, { __creativeParams: creativeParams }));
    };

    return (

        <Box>
            <Stack direction="column" spacing={columnGridSpacing} alignItems="center">
                {/* <Typography fontSize={32} color={_properties.appPrimaryColor} xvariant="cardTitle">columnWidth: {columnWidth} pageWidth: {pageWidth} offsetLeft: {offsetLeft} spacing: {columnGap}</Typography> */}
                {'desktop' === _device && (
                    <>
                        <Typography fontSize={32} color={_properties.appPrimaryColor} xvariant="cardTitle">{t('upselling.title')}</Typography>
                        <Typography fontSize={14} color={_properties.appPrimaryColor} xvariant="cardData">{t('upselling.description')}</Typography>
                    </>
                )}
                {'desktop' !== _device && (
                    <Typography color={_properties.appPrimaryColor} variant="mobilePageLabel">{t('m.upselling.title')}</Typography>
                )}
                <Paper
                    elevation={4}
                    sx={{
                        position: 'relative',
                        padding: pageMargin,
                        width: pageWidth + 20,
                        height: pageHeight,
                    }}>
                    {/* Spaltenraster */
                        <Box id='id-spaltenraster' sx={{
                            position: 'absolute',
                            left: columnGridSpacing,
                            top: columnGridSpacing,
                            maxHeight: columnHeight
                        }}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                spacing={0}
                            >
                                {
                                    columns.map((e, i) => {
                                        return <PageColumn i={i + 1} key={i}>x</PageColumn>
                                    })
                                }
                            </Stack>
                        </Box>
                    }
                    {/* Motive */}
                    <Box sx={{
                        position: 'absolute',
                        left: columnGridSpacing / 2,
                        top: columnGridSpacing,
                        maxHeight: columnHeight,
                        width: pageWidth,
                        overflowY: 'scroll',
                    }}>
                        <Stack
                            direction="column"
                            spacing={itemBorderWidth}
                        >
                            {
                                items?.map((item) => {
                                    return (
                                        <Box key={item._id} sx={itemStyle(item)}>
                                            <DesignAlternative
                                                upselling item={item}
                                                onItemSelected={itemSelected}
                                                pageDimension={_pageDimension}
                                                onRendered={onRendered}
                                            >
                                            </DesignAlternative>
                                        </Box>
                                    )
                                })
                            }
                        </Stack>
                    </Box>
                </Paper>
            </Stack >
            {
                favorite && anchorEl && (<UpsellingTool
                    item={favorite}
                    anchorEl={anchorEl}
                    onPopperWidth={onPopperWidth}
                    onPopperCheck={onPopperCheck}
                    onPopperDismiss={onPopperDismiss}
                ></UpsellingTool>)
            }
        </Box >
    )
}

export default UpsellingComponent;
