import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardContent, Collapse } from '@mui/material';
import AreaCardHeader from './AreaCardHeader';
import { PriceArea } from './PriceArea.jsx';
import { offers } from '../../redux/Selectors.js';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PriceCard = (props) => {
    const { t } = useTranslation();
    const _offers = useSelector((state) => offers(state));


    return (
        <Card sx={{ height: '100%' }} >
            {_offers.length > 1 && (<AreaCardHeader title={t('creative.priceCardTitle')} navAction={props.navAction} />)}
            {_offers.length <= 1 && (<AreaCardHeader title={t('creative.priceCardTitle')} />)}
            <CardContent sx={{ height: '100%', mt: !props.displayOffer ? -10 : -4 }}>
                <PriceArea navAction={props.navAction}
                    displayOffer={props.displayOffer ? props.displayOffer : false}
                    displayPriceText={props.displayPriceText ? props.displayPriceText : false}
                    displayMoreOfferText={props.displayMoreOfferText ? props.displayMoreOfferText : false} />
            </CardContent>
        </Card>
    );

}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CollapseablePriceCard = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const _offers = useSelector((state) => offers(state));

    const [expanded, setExpanded] = useState(true);

    useImperativeHandle(ref, () => ({ setExpanded }));

    return (
        <Card sx={{ height: '100%' }} >
            {_offers.length > 1 && (<AreaCardHeader title={t('creative.priceCardTitle')} navAction={props.navAction} fullCard={expanded} handleExpand={props.handleExpand} />)}
            {_offers.length <= 1 && (<AreaCardHeader title={t('creative.priceCardTitle')} fullCard={expanded} handleExpand={props.handleExpand} />)}
            <Collapse in={expanded}>
                <CardContent sx={{ height: props.height ? props.height : '100%', mt: !props.displayOffer ? -10 : -4 }}>
                    <PriceArea navAction={props.navAction}
                        displayOffer={props.displayOffer ? props.displayOffer : false}
                        displayPriceText={props.displayPriceText ? props.displayPriceText : false}
                        displayMoreOfferText={props.displayMoreOfferText ? props.displayMoreOfferText : false}
                        displayMorePriceText={props.displayMorePriceText ? props.displayMorePriceText : false} />
                </CardContent>
            </Collapse>
        </Card>
    );

});