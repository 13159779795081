import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Box, Card, Typography, CardContent, Grid, Divider, IconButton } from '@mui/material';
import { DownloadIcon } from '../../common/widgetTools';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import AppHeader from '../../components/AppHeader';
import RegistrationPaging from '../components/RegistrationPaging';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { properties, bpForModify } from '../../redux/Selectors';



export const ServicePackages = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _properties = useSelector(state => properties(state));

    const [servicePackages, setServicePackages] = useState([]);

    useEffect(() => {
        setServicePackages(_bpForModify.servicePackages);
    }, [_bpForModify.servicePackages]);


    const startDownload = e => {
        e.stopPropagation();
        const id = e.target.download;
        const checkDownload = (id, count) => {
            if (document.cookie.indexOf("download=" + id) !== -1 || count >= 20) {
                //            showBusyIndicator(false);
            }
            else {
                setTimeout(() => checkDownload(id, count + 1), 500);
            }
        }
        //    showBusyIndicator(true);
        document.cookie = "download=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkDownload(id, 0);
    };



    const DownloadButton = ({ servicePackage }) => {
        const fileName = `${servicePackage?.shortName}.pdf`;
        return <IconButton
            target="_blank"
            rel="noreferrer"
            download={fileName}
            onClick={startDownload}
            sx={{ color: _properties.appPrimaryColor }}
            href={servicePackage?._links.download.href}><DownloadIcon size='lg' /></IconButton>;
    }

    const getSwiperStyle = () => {
        return {
            "--swiper-pagination-color": _properties.appPrimaryColor,
            "--swiper-navigation-color": _properties.appPrimaryColor,
            width: '100%',
            "paddingBottom": "40px",
            "paddingTop": "15px"
        }
    }

    const ServicePackageCard = ({ servicePackage }) => {
        return (
            <Card
                key={servicePackage.shortName}
                sx={{ height: '100%', width: '90%', border: 'none', boxShadow: 'none', cursor: 'pointer' }}>
                <Box sx={{ height: '100%', border: 1, borderRadius: 2.5, borderColor: '#A0A0A0', p: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Stack>
                        <CardContent sx={{ height: '55vw', pt: 1, textAlign: 'left' }}>
                            < Grid container >
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.name')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {servicePackage.shortName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.description')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {servicePackage.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.regular')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {
                                            t('global.currency.' + ('EUR'), { value: servicePackage.nonSaleTotalNetPrice.value })
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.contract')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {
                                            t('global.currency.' + ('EUR'), { value: servicePackage.aggreedPrice.value })
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.rest')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {
                                            t('global.currency.' + ('EUR'), { value: servicePackage.remainingBalance.value })
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.from')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('common.date.short', { value: new Date(servicePackage.validFrom) })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('package.to')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={7} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('common.date.short', { value: new Date(servicePackage.validTo) })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} >

                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="mobileTextBoxLabel">
                                        <DownloadButton servicePackage={servicePackage} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Stack>
                </Box>
            </Card>
        );
    }


    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ minHeight: windowHeight - 150 }} width='100%' spacing={4}>
                <RegistrationPaging step={0} pagetitle='m.customer.servicepackages.title' />

                <Divider />

                <Stack width='100%' alignItems='center'>
                    <Box width='90%' >
                        <Swiper
                            slidesPerView='1'
                            spaceBetween={10}
                            keyboard={{
                                enabled: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Keyboard, Pagination, Navigation]}
                            className="mySwiper"
                            direction={"horizontal"}
                            style={getSwiperStyle()}
                        >
                            {servicePackages.slice(0, 10).map(item =>
                                <SwiperSlide key={item.invoiceNumber} width='90%'>
                                    <ServicePackageCard servicePackage={item} key={item.shortName} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Box>
                </Stack>
            </Stack>

            <NavigationBar
                back={{ clicked: () => navigate(-1), label: t('m.customer.servicepackages.back') }}>
            </NavigationBar>
            <InfoText />
        </Stack >
    );
}

export default ServicePackages;