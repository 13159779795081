import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { properties } from '../redux/Selectors.js';
import { Box, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import { Typography } from '@mui/material';
import { getPage, DEVICE_DESKTOP } from '../common/navigationTools.js';

const AppFooter = ({ title, logo, action1, action2, action3 }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const _properties = useSelector((state) => properties(state));
    const getStep = (pathname) => {
        if (getPage('template', DEVICE_DESKTOP) === pathname) {
            return 0;
        }
        else if (getPage('creative', DEVICE_DESKTOP) === pathname) {
            return 1;
        }
        else if (getPage('order', DEVICE_DESKTOP) === pathname || getPage('offers', DEVICE_DESKTOP) === pathname || getPage('schedule', DEVICE_DESKTOP) === pathname) {
            return 2;
        }
        else {
            return 0;
        }
    };

    const Title = () => {
        return <Box><Typography variant="pageTitleBold">{t(title)}</Typography></Box>
    };

    const Logo = () => {
        return logo;
    };

    const Action1 = () => {
        return action1;
    };

    const Action2 = () => {
        return action2;
    };

    const Action3 = () => {
        return action3;
    };
    const steps = [];
    steps.push(t('app.steps.template'));
    steps.push(t('app.steps.creative'));
    steps.push(t('app.steps.order'));
    return (
        // <Grid container columns={16}>
        //     <Grid item sm={0} md={1} lg={1} />
        //     <Grid item sm={16} md={14} lg={14} >
        <Box sx={{
            pt: '0.25rem',
            pb: '0.25rem'
        }}>
            < Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={5}
                sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }}
            >
                <Box sx={{ width: '66%' }}>
                    {title && (<Title />)}
                    {!title && (<Stepper activeStep={getStep(location.pathname)} >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel><Typography xsx={{ fontWeight: 'bold' }} variant="cardTitle">{label}</Typography></StepLabel>
                            </Step>
                        ))}
                    </Stepper>)}
                </Box>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={5}
                >
                    {logo && (<Logo />)}
                    {action1 && (<Action1 />)}
                    {action2 && (<Action2 />)}
                    {action3 && (<Action3 />)}
                </Stack>
            </Stack >
        </Box >
        //     </Grid>
        //     <Grid item sm={0} md={1} lg={1} />
        // </Grid>
    );

}

export default AppFooter;