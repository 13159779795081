import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import {
    Checkbox, Divider, Alert, Grid, FormControl, Stack, Typography, InputLabel,
    Input, InputAdornment, IconButton, Snackbar, FormHelperText, FormControlLabel
} from '@mui/material';
import AppHeader from '../../components/AppHeader';
import SelectedMarket from '../components/SelectedMarket.jsx';
import { DesiredDate } from '../components/DesiredDate';
import { EditionPartHierarchie } from '../../components/order/EditionPartHierarchie';
import { OrderCriteriaArea } from '../../components/order/OrderCriteriaArea';
import InfoText from '../../components/InfoText';
import NavigationBar from '../components/NavigationBar';
import { getPage } from '../../common/navigationTools';
import {
    defaults,
    desiredDate,
    orderCatchword,
    orderPriceResponse,
    pendingOrder,
    preSelectedOffer,
    selectedEditionPart,
    selectedMarket,
    selectedOffer,
    tenant,
} from '../../redux/Selectors';
import { setVoucher, resetPrice, setOrderCatchword, setOrderIsCombi } from '../../redux/Actions';
import { CheckIcon } from '../../common/widgetTools';
import PriceArea from '../../components/common/PriceArea.jsx';

/**
 * @returns 
 */
export const Schedule = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const _defaults = useSelector((state) => defaults(state));
    const _desiredDate = useSelector((state) => desiredDate(state));
    const _order = useSelector((state) => pendingOrder(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _preSelectedOffer = useSelector((state) => preSelectedOffer(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _tenant = useSelector((state) => tenant(state));
    const _orderCatchword = useSelector((state) => orderCatchword(state));

    const [displayDate, setDisplayDate] = useState('');
    const [displayDatesNumber, setDisplayDatesNumber] = useState(0);
    const [isOrderVoucherEnabled, setIsOrderVoucherEnabled] = useState(false);
    const [ordVoucherValue, setOrdVoucherValue] = useState('');
    const [combiChecked, setCombiChecked] = useState(false);
    const [catchword, setCatchword] = useState('');
    const [isVoucherFieldEnabled, setIsVoucherFieldEnabled] = useState(true);
    const [isVoucherButtonEnabled, setIsVoucherButtonEnabled] = useState(false);
    const [voucherErrorMsg, setVoucherErrorMsg] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [complete, setComplete] = useState(false);

    const DEVICE = 'mobile';
    const BACK = getPage(_selectedOffer?.editionsSelectable ? 'editions' : !_preSelectedOffer ? 'offers' : 'creative', DEVICE);
    const NEXT = getPage('order', DEVICE);
    const LABEL_BACK = _selectedOffer?.editionsSelectable ? 'm.navigation.toEditions' : !_preSelectedOffer ? 'm.navigation.toOffers' : 'm.navigation.toCreative';
    const LABEL_NEXT = 'm.schedule.next';

    useEffect(() => {
        // sapCombination
        console.log("_order?.orderIsCombi  = " + _order?.orderIsCombi);
        var checked = _selectedOffer?.sapCombination && (_order?.orderIsCombi || _selectedOffer?.sapDefault || _selectedOffer?.sapForce);
        /*       if (this.orderToRepeat) {
                   if (this.pendingOrder && this.pendingOrder.orderIsCombi) {
                       checked = true;
                   }
               } */
        dispatch(setOrderIsCombi(checked));
        setCombiChecked(checked);

        // voucher
        setOrdVoucherValue(_order?.voucher ? _order.voucher : _defaults.voucher);
        setCatchword(_orderCatchword);
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsVoucherButtonEnabled(isVoucherFieldEnabled && Boolean(ordVoucherValue));
    }, [ordVoucherValue, isVoucherFieldEnabled])

    useEffect(() => {
        console.log("---> useEffect _orderPriceResponse " + JSON.stringify(_orderPriceResponse));
        if (_orderPriceResponse) {
            if (_orderPriceResponse.success) {
                setVoucherErrorMsg('');
            }
            else if (_orderPriceResponse.exception) {
                setSnackbarMsg(t('main.commonFailure'));
                setSnackbarOpen(true);
                setSnackbarSeverity('error');
            } else {
                // ASE-395: Einloesen von Gutschein, etc
                //   let priceMessage = t('main.commonFailure') + '<br/>';
                let priceMessage = '';
                if (_orderPriceResponse.errors && ordVoucherValue && ordVoucherValue !== '') {
                    priceMessage += _orderPriceResponse.errors.map(m => m.text)[0];
                }
                setVoucherErrorMsg(priceMessage);
                setIsVoucherFieldEnabled(true);
            }
        }

        if (_orderPriceResponse && _orderPriceResponse.value) {
            const p = _orderPriceResponse.value;
            var orderPrice = _orderPriceResponse.value;
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(" \u2022 ");
            setDisplayDate(displayDate);
            setDisplayDatesNumber(orderPrice?.effectiveDates?.length);
        }
    }, [_orderPriceResponse]);

    useEffect(() => {
        setIsOrderVoucherEnabled(Boolean(_selectedOffer?.orderVoucher));
    }, [_selectedOffer]);

    useEffect(() => {
        if (_selectedOffer && _selectedEditionPart && _selectedEditionPart.bookable === true && _desiredDate && _orderPriceResponse?.value?.effectiveDates?.length > 0) {
            setComplete(true);
        } else {
            setComplete(false);
        }
    }, [_selectedOffer, _selectedEditionPart, _desiredDate, _orderPriceResponse]);

    const handleCombiChange = (event) => {
        setCombiChecked(event.target.checked);
        dispatch(setOrderIsCombi(event.target.checked));
    };

    const handleVoucherChange = (event) => {
        setVoucherErrorMsg('');
        setOrdVoucherValue(event.target.value);
        dispatch(setVoucher());
    }

    const handleCatchwordChange = (event) => {
        setCatchword(event.target.value);
        dispatch(setOrderCatchword(event.target.value));
    };

    const cashVoucher = (event) => {
        setIsVoucherFieldEnabled(false);
        dispatch(setVoucher(ordVoucherValue));
    }

    const back = () => {
        navigate(BACK);
    };

    const next = () => {
        navigate(NEXT);
    };

    const styleFormStack = () => {
        return (
            {
                mt: 8,
                width: '90%',
                minHeight: windowHeight - 150,
                '& .MuiInputBase-root': { mt: 3, mb: 8 },
                '& .MuiInputLabel-root': { zIndex: 0 } // WS-8348 Fragmente der Order-Page bei Anmeldung sichtbar
            });
    }

    const closeSnackbarHandler = () => {
        console.log("closeSnackbarHandler ...");
        setSnackbarOpen(false);
        setSnackbarMsg('');
        dispatch(resetPrice());
    }
    return (
        <Stack direction="column" alignItems="center" justifyContent="flexStart" spacing={8} >
            <AppHeader></AppHeader>

            <Stack sx={styleFormStack} direction="column" alignItems="left" spacing={5} >
                <SelectedMarket market={_selectedMarket}> </SelectedMarket>
                < Divider />


                <Typography sx={{ pb: 5, pt: 6 }} variant="mobilePageSubTitle" > {t('m.order.details')}: </Typography>

                < EditionPartHierarchie variant="mobile" />


                {
                    !_selectedOffer.publicationCalendar ?
                        (<DesiredDate variant="mobileWithDatePicker" />) : (<DesiredDate variant="mobilePulicationDatePicker" />)
                }

                {isOrderVoucherEnabled && (
                    <FormControl fullWidth>
                        <InputLabel shrink={true} sx={{ ml: "-14px" }}>{t('m.schedule.voucher')}</InputLabel>
                        <Input
                            variant='standard'
                            label={t('m.schedule.voucher')}
                            value={ordVoucherValue ?? ""}
                            onChange={handleVoucherChange}
                            disabled={!isVoucherFieldEnabled}
                            error={voucherErrorMsg !== ''}
                            sx={{ width: '100%' }}
                            endAdornment={<InputAdornment position='end'>
                                <IconButton
                                    onClick={cashVoucher}
                                    disabled={!isVoucherButtonEnabled}
                                    sx={{ pr: 0 }}>
                                    <CheckIcon />
                                </IconButton>
                            </InputAdornment>}
                        />
                        <FormHelperText error={voucherErrorMsg !== ''}>{voucherErrorMsg}</FormHelperText>
                    </FormControl>
                )}

                {_tenant?.orderCatchword && (
                    <FormControl fullWidth>
                        <InputLabel shrink={true} sx={{ ml: "-14px" }} >{t('order.catchword')}</InputLabel>
                        <Input
                            variant='standard'
                            label={t('order.catchword')}
                            value={catchword ?? ""}
                            onChange={handleCatchwordChange}
                            inputProps={{ maxLength: _tenant.orderCatchword }}
                            sx={{ width: '100%' }}
                        />
                    </FormControl>
                )}

                {_selectedOffer?.sapCombination && (
                    <>
                        <FormControlLabel
                            control={<Checkbox
                                checked={combiChecked}
                                onChange={handleCombiChange}
                                disabled={_selectedOffer?.sapCombination && _selectedOffer.sapForce}
                                sx={{ mr: 3, pl: 0 }} />}
                            label={t('order.sap.combination')}
                        >
                        </FormControlLabel>
                    </>
                )}

                <OrderCriteriaArea />

                <Typography variant="mobilePageSubTitle" sx={{ pt: 6 }}> {t('m.schedule.result')}: </Typography>


                < Grid container >
                    <Grid item xs={5} >
                        <Typography variant="mobilePageLabel" >
                            {t('m.schedule.offer')}:
                        </Typography>
                    </Grid>
                    < Grid item xs={7} >
                        <Typography variant="mobilePageLabel" >
                            {_selectedOffer?.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} >
                        <Typography variant="mobilePageLabel" >
                            {displayDatesNumber === 1 ? t('m.order.date') : t('m.order.dates')}:
                        </Typography>
                    </Grid>
                    < Grid item xs={7} >
                        <Typography variant="mobilePageLabel" >
                            {displayDate ? displayDate : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 6 }}>
                        <PriceArea />
                    </Grid>

                </Grid>

            </Stack>
            <NavigationBar
                back={{ clicked: back, label: t(LABEL_BACK) }}
                next={{ clicked: next, label: t(LABEL_NEXT), disabled: !complete }}>
            </NavigationBar>
            <InfoText />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
            >
                <Alert severity={snackbarSeverity}>{snackbarMsg}</Alert>
            </Snackbar>
        </Stack >

    );
}

export default Schedule;