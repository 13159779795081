import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Card, CardContent } from '@mui/material';
import AreaCardHeader from './common/AreaCardHeader';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const InactiveCard = ({ title, message, action }) => {
    const { t } = useTranslation();

    return (
        <Card
            sx={{ height: '100%' }}
            onMouseOver={action ? () => { action(); } : () => { }} >
            <AreaCardHeader title={t(title)} />
            <CardContent
                sx={{
                    backgroundColor: 'primary.main',
                    color: '#FFFFFF',
                    height: '100%',
                    fontSize: 16,
                    textAlign: 'center'
                }}>
                {t(message)}
            </CardContent>
        </Card>
    );

}