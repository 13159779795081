import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import {
    authentication,
    businessPartner,
    features,
    isSsoAuthentication
} from '../../redux/Selectors.js';
import { EditIcon, ListIcon } from '../../common/widgetTools'

import AreaCardHeader from '../common/AreaCardHeader';
import { AccountSmall } from './Account';
import { ContactSmall } from './Contact';
import { MailAddressSmall } from './MailAddress';
import { OrdersSmall } from './Orders';
import { InvoicesSmall } from './Invoices';
import { PaymentSmall } from './Payment';
import { DraftsSmall } from './Drafts';
import { AdvertizersSmall } from './Advertizers';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Home = ({ changeTab }) => {

    const { t } = useTranslation();

    const _authentication = useSelector((state) => authentication(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _features = useSelector((state) => features(state));
    const _isSsoAuthentication = useSelector((state) => isSsoAuthentication(state));

    const cards = [];
    if (!_isSsoAuthentication) {
        cards.push({ name: 'account', action: 'edit' });
    }
    cards.push({ name: 'address', action: 'edit' });
    cards.push({ name: 'contact', action: 'edit' });
    cards.push({ name: 'payment', action: 'edit' });
    cards.push({ name: 'orders', action: 'view' });
    if (_features.ASE_1992.active) {
        cards.push({ name: 'drafts', action: 'view' });
    }
    cards.push({ name: 'invoices', action: 'view' });
    if (_businessPartner?.isAgency) {
        cards.push({ name: 'advertizers', action: 'view' });
    }

    const DetailCard = ({ name, action }) => {

        if (!_businessPartner) {
            return null;
        }

        return (
            <Card elevation={4}>
                <CardHeader sx={{ pb: 0, heigth: '2rem' }}
                    title={
                        <Typography variant='cardTitle'>
                            {t(`customerdata.${name}.title`)}
                            {/* {t(`customerdata.home.${name}.title`)} */}
                        </Typography>}
                />
                <CardContent sx={{ height: '6rem' }}>
                    {'account' === name && (<AccountSmall authentication={_authentication} businessPartner={_businessPartner} />)}
                    {'address' === name && (<MailAddressSmall address={_businessPartner.legalEntity.mailAddresses[0]} />)}
                    {'contact' === name && (<ContactSmall teleComms={_businessPartner.legalEntity.teleComms} netAddresses={_businessPartner.legalEntity.netAddresses} />)}
                    {'payment' === name && (<PaymentSmall commercialProps={_businessPartner.commercialProps} />)}
                    {'orders' === name && (<OrdersSmall />)}
                    {'invoices' === name && (<InvoicesSmall />)}
                    {'drafts' === name && (<DraftsSmall />)}
                    {'advertizers' === name && (<AdvertizersSmall />)}
                </CardContent>
                <CardActions
                    sx={{
                        pt: 0,
                        mt: 0,
                        display: 'flex',
                        flexWrap: 'nowrap',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Button
                        onClick={() => { changeTab(name) }}
                        variant="text"
                        startIcon={'edit' === action ? (<EditIcon size='xs' />) : (<ListIcon size='xs' />)}>
                        {t('edit' === action ? 'common.actions.edit' : 'common.actions.overview')}
                    </Button>
                </CardActions>
            </Card >
        );
    };

    return (
        <Grid sx={{ p: 10 }} container spacing={10}>
            {cards.map(it => {
                return (
                    <Grid item sm={12} md={6} lg={4} key={it.name}>
                        <DetailCard key={it.name} name={it.name} action={it.action}>
                        </DetailCard>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default Home;