import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Dialog,
    DialogContent,
    Stack,
} from '@mui/material';


import UpsellingComponent from './UpsellingComponent';

/**
 * @returns 
 */
export const UpsellingDialog = ({ handleDialogClose, dialogOpen, selectedTemplate, alternatives }) => {

    const { t } = useTranslation();
    return (

        <Dialog
            maxWidth="lg"
            fullWidth={true}
            open={dialogOpen}
        >
            <DialogContent sx={{ height: '800px' }}>
                <UpsellingComponent
                    handleComponentClose={handleDialogClose}
                    selectedTemplate={selectedTemplate}
                    alternatives={alternatives}
                ></UpsellingComponent>
            </DialogContent >
            <Stack
                sx={{ m: '1rem' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <Button variant="navigationBack" onClick={() => { handleDialogClose('back'); }} >{t('upselling.back')}</Button>
                <Button variant="navigationAction" onClick={() => { handleDialogClose('next'); }} >{t('upselling.next')}</Button>
            </Stack>
        </Dialog >

    )
}

export default UpsellingDialog;
