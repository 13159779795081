import { grey, red } from "@mui/material/colors"
import './fonts/ABeZeh/ABeZeh-Bold.woff';
import './fonts/ABeZeh/ABeZeh-Bold.woff2';

import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

const ABeZee = "'AbeeZee', sans-serif";

export const appTheme = (prop) => {
  return {
    palette: {
      background: {
        default: prop.appBackgroundColor,
        paper: '#FEFEFE'
      },
      primary: {
        main: prop.appPrimaryColor,
        border: '#A0A0A0',
      },
      secondary: {
        main: prop.appSecondaryColor,
        border: '#A0A0A0',
      },
      error: {
        main: prop.appErrorColor
      },
      warning: {
        main: prop.appWarningColor
      }
    },
    typography: {

      fontSize: 14,
      fontFamily: [
        ABeZee
      ].join(','),

      //    bold: {
      //      fontSize: 14,
      //  fontFamily: 'ABeZehBold',
      //      fontWeight: 'bold'
      //    },
      h2: {
        styleOverrides: {
          fontSize: 20
        }
      },
      h6: {
        fontSize: 30,
        fontWeight: 700,
        textAlign: 'center'
      },
      button: {
        textTransform: "none"
      }
    },
    components: {

      MuiFormControlLabel: {
        variants: [
          {
            props: { variant: 'payment' },
            style: {
              fontSize: 14,
              whiteSpace: 'nowrap'
            }
          }
        ]
      },

      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'ABeZehBold';
            font-style: 'bold';
             font-weight: 400;
            src: local('ABeZeh-Bold'), url('fonts/ABeZeh/ABeZeh-Bold.woff') format('font-woff'),
            local('ABeZeh-Bold'), url('fonts/ABeZeh/ABeZeh-Bold.woff2') format('font-woff2');
         }
        `,
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink:
          {
            fontWeight: 'bold',
            paddingLeft: '-2px !important',
            bottom: '10 px !important'
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: prop.appToolbarColor,
            contrastText: 'red'
          }
        }
      },
      MuiListItemText: {
        variants: [
          {
            props: { variant: 'menu' },
            style: {
              fontSize: 14,
            }
          }]
      },
      MuiButton: {
        root: {
          textTransform: 'none'
        },
        hoover: {
          textTransform: 'none'
        },
        variants: [
          {
            props: { variant: 'menu' },
            style: {
              border: 'none',
              borderRadius: 4,
              opacity: 1,
              fontSize: 14,
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'cancelOrder' },
            style: {
              border: 'none',
              opacity: 1,
              fontSize: 14,
              letterSpacing: 0,
              color: red[700],
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              '& .MuiButtonBase-root': {
                padding: '0px'
              },
              padding: '0px'
            }
          },
          {
            props: { variant: 'simple' },
            style: {
              backgroundColor: prop.appPrimaryColor,
              border: '1px solid #707070',
              borderRadius: 4,
              opacity: 1,
              fontSize: 16,
              color: '#FFFFFF',
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'navigationBack' },
            style: {
              backgroundColor: '#FEFEFE',
              border: '1px solid #707070',
              borderRadius: 4,
              opacity: 1,
              fontSize: 16,
              color: '#060CAD',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'navigationAction' },
            style: {
              backgroundColor: prop.appPrimaryColor,
              border: '1px solid #707070',
              borderRadius: 4,
              opacity: 1,
              fontSize: 16,
              color: '#FFFFFF',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'link' },
            style: {
              border: 'none',
              opacity: 1,
              fontSize: 14,
              color: prop.appTextColor,
              textTransform: 'none',
              textAlign: 'left',
              letterSpacing: 0
            }
          },
          {
            props: { variant: 'swiperXL' },
            style: {
              color: prop.appPrimaryColor,
              border: 'none',
              opacity: 1,
              height: 27,
              width: 27,
              whiteSpace: 'nowrap'
            }
          },

        ]

      },

      MuiIconButton: {
        root: {
        },
        hoover: {
        },
        variants: [
          {
            props: { variant: 'mobileNavigation' },
            size: 'large',
            style: {
              color: prop.appPrimaryColor,
            }
          },
          {
            props: { variant: 'layoutCreativePage' },
            style: {
              color: prop.appPrimaryColor,
              minWidth: '40px',
            }
          }
        ]
      },

      MuiAvatar: {
        backgroundColor: prop.appPrimaryColor
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: prop.appPrimaryColor,
            borderRadius: '8px',
            fontSize: 12
          },
          arrow: {
            color: prop.appPrimaryColor
          }
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'offerpicker' },
            style: {
              color: grey[500]
            }
          },
          {
            props: { variant: 'appTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 18,
              textAlign: 'left',
              color: '#ffffff',
            }
          },
          {
            props: { variant: 'cardTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 18,
              textAlign: 'left',
              color: prop.appTextColor,
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'cardData' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 16,
              textAlign: 'left',
              color: prop.appTextColor
            }
          },
          {
            props: { variant: 'marketPickerTitle' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 14,
              textAlign: 'left',
              color: prop.appTextColor,
              fontWeight: 'bold',
              wordBreak: 'break-word'
            }
          },
          {
            props: { variant: 'templatePickerTitle' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 14,
              textAlign: 'center',
              color: prop.appTextColor,
              fontWeight: 'bold'
            }
          },
          {
            props: { variant: 'templatePickerSubtitle' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              textAlign: 'center',
              color: prop.appTextColor
            }
          },
          {
            props: { variant: 'orderCardTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 16,
              textAlign: 'center',
              color: prop.appTextColor,
              fontWeight: 'bold'
            }
          },
          {
            props: { variant: 'orderCardSubtitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 12,
              textAlign: 'center',
              color: prop.appTextColor
            }
          },
          {
            props: { variant: 'orderCardHeaderTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 12,
              color: prop.appTextColor
            }
          },
          {
            props: { variant: 'orderCardHeaderSubheader' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 12,
              color: prop.appTextColor
            }
          },
          {
            props: { variant: 'areaCardHeader' },
            style: {
              fontSize: 14,
              color: '#8B8B8B',
              opacity: 1,
              letterSpacing: 0.25,
              textTransform: 'uppercase'
            }
          },
          {
            props: { variant: 'pageTitle' },
            style: {
              fontSize: 18,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
            }
          },
          {
            props: { variant: 'pageTitleBold' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 18,
              color: prop.appTextColor,
              opacity: 1,
              fontWeight: 'bold',
              letterSpacing: 0,
              whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'textBold' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 16,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              fontWeight: 'bold'
            }
          },
          {
            props: { variant: 'text' },
            style: {
              fontFamily: 'ABeeZee, Regular',
              fontSize: 14,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0
            }
          },
          {
            props: { variant: 'errorText' },
            style: {
              fontFamily: 'ABeeZee, Regular',
              fontSize: 14,
              color: '#b71c1c',
              opacity: 1,
              letterSpacing: 0
            }
          },
          {
            props: { variant: 'customerTabButton' },
            style: {
              fontFamily: 'ABeeZee, Regular',
              fontSize: 14,
              color: '#8b8b8b',
              opacity: 1,
              letterSpacing: 0
            }
          },
          {
            props: { variant: 'termsAndConditions' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 14,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0

            }
          },
          {
            props: { variant: 'formValue' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 28,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0

            }
          },
          {
            props: { variant: 'dialogTitle' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 32,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'

            }
          },
          {
            props: { variant: 'offerCardTitle' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 24,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left',
              // whiteSpace: 'nowrap'
            }
          },
          {
            props: { variant: 'offerCardText' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 14,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left',
            }
          },
          {
            props: { variant: 'offerCardPrice' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 24,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'right'
            }
          },
          {
            props: { variant: 'mobilePageTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 24,
              fontWeight: 'bold',
              color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageSelectedMarket' },
            style: {
              fontFamily: 'ABeeZee, Regular',
              fontSize: 16,
              fontWeight: 'bold',
              color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageSubTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 12,
              fontWeight: 'bold',
              color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageSubTitle2' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 14,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageLabel' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 12,
              color: prop.appTextColor,
              // color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageLabelBold' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 12,
              fontWeight: 'bold',
              //color: prop.appTextColor,
              color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobileTextBoxLabel' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 12,
              color: prop.appTextColor,
              // color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePagePrimaryLabel' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 14,
              //color: prop.appTextColor,
              color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageNavigation' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 14,
              color: prop.appPrimaryColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobilePageValue' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 14,
              fontWeight: 'bold',
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobileOrderCardTitle' },
            style: {
              fontFamily: 'ABeZehBold, sans-serif',
              fontSize: 14,
              fontWeight: 'bold',
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0,
              textAlign: 'left'
            }
          },
          {
            props: { variant: 'mobileTermsAndConditions' },
            style: {
              fontFamily: 'ABeeZee, sans-serif',
              fontSize: 12,
              color: prop.appTextColor,
              opacity: 1,
              letterSpacing: 0

            }
          },
        ]

      }
    },
    MuiFormControlLabel: {
      labelPlacementTop: {
        alignItems: 'start',
        webkitAlignItems: 'start'
      }
    },
    spacing: 2
  }

}

/**
 * components used on customer center page
 */

export const CcSelect = styled(Select)(({ theme }) => ({
  height: '2em',
  fontSize: 14
}));

export const CcMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 14
}));

