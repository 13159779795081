import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box, Divider, Stack, Typography, FormControl, InputLabel, Select, MenuItem,
    Input, FormGroup, Autocomplete, TextField, FormControlLabel, Checkbox,
    Snackbar, Backdrop, CircularProgress
} from '@mui/material';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import RegistrationPaging from '../components/RegistrationPaging';
import { newBusinessPartner, debounce } from '../../common/tools';
import {
    selectedPaymentType, customerType, properties, locale, salutations, bpForModify,
    requiredCustomerFormFields, titles, services, countries, device, bpResponse, isWSS,
    isGuestAuthenticated
} from '../../redux/Selectors.js';
import { setBpForModify, saveBusinessPartner, resetBpResponse } from '../../redux/Actions';
import { SelectIconComponent, ClearIcon } from '../../common/widgetTools';
import { DEVICE_MOBILE, getPage } from '../../common/navigationTools.js';

/**
 * @returns 
 */
export const RegisterMail = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _customerType = useSelector((state) => customerType(state));
    const _properties = useSelector((state) => properties(state));
    const _locale = useSelector((state) => locale(state));
    const _selectedPaymentType = useSelector((state) => selectedPaymentType(state));
    const _salutations = useSelector((state) => salutations(state));
    const _titles = useSelector((state) => titles(state));
    const _bpForModify = useSelector((state) => bpForModify(state));
    const _requiredCustomerFormFields = useSelector((state) => requiredCustomerFormFields(state));
    const _services = useSelector((state) => services(state));
    const _countries = useSelector((state) => countries(state));
    const _bpResponse = useSelector((state) => bpResponse(state));
    const _isWSS = useSelector(state => isWSS(state));
    const _isGuestAuthenticated = useSelector(state => isGuestAuthenticated(state));
    const _device = useSelector((state) => device(state));

    const [modified, setModified] = useState();
    const [showError, setShowError] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const [mailAddress, setMailAddress] = useState();
    const [zipCodeInput, setZipCodeInput] = useState('');
    const [zips, setZips] = useState([]);
    const [cityNameInput, setCityNameInput] = useState('');
    const [cities, setCities] = useState([]);
    const [valid, setValid] = useState(false);
    const [marketingApprovalValue, setMarketingApprovalValue] = useState(false);
    const [isNewBp, setIsNewBp] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState('');
    const [formIsReadOnly, setFormIsReadOnly] = useState(false);

    useEffect(() => {
        console.log("useEffect: setBPForModify: " + JSON.stringify(_bpForModify));
        //       setModified(newBusinessPartner(_customerType, _properties, _locale, _selectedPaymentType));
        if (!_bpForModify || _bpForModify === '{}') {
            dispatch(setBpForModify(newBusinessPartner(_customerType, _properties, _locale, _selectedPaymentType, _device)));
            setIsNewBp(true);
        } else {
            setIsNewBp(false);
        }
        setFormIsReadOnly(_isWSS && !_isGuestAuthenticated)

        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormIsReadOnly(_isWSS && !_isGuestAuthenticated)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_isGuestAuthenticated])

    useEffect(() => {
        console.log("setMailAddress " + JSON.stringify(_bpForModify?.legalEntity?.mailAddresses[0]));
        const mailAddr = _bpForModify?.legalEntity?.mailAddresses[0];
        if (mailAddr) {
            setMailAddress(mailAddr);
            setCityNameInput(mailAddr.cityName);
            setZipCodeInput(mailAddr.zipCode);
        }
        setMarketingApprovalValue(_bpForModify?.marketingApproval?.value);

    }, [_bpForModify]);

    useEffect(() => {
        let invFields = [];
        if (isRequired('salutationMenu') && (mailAddress?.salutation === undefined)) {
            invFields.push('salutation');
        }
        if (isRequired('titleMenu') && mailAddress?.title === undefined) {
            invFields.push('title');
        }
        if (isRequired('forename') && mailAddress?.forename === '') {
            invFields.push('forename');
        }
        if (isRequired('name') && mailAddress?.name === '') {
            invFields.push('name');
        }
        if (isRequired('street') && mailAddress?.street === '') {
            invFields.push('street');
        }
        if (isRequired('number') && mailAddress?.houseNumber === '') {
            invFields.push('houseNumber');
        }
        if (isRequired('countryMenu') && mailAddress?.country === '') {
            invFields.push('country');
        }
        if (isRequired('zipCode') && mailAddress?.zipCode === '') {
            invFields.push('zipCode');
        }
        if (isRequired('city') && mailAddress?.cityName === '') {
            invFields.push('cityName');
        }
        console.log(invFields);
        setInvalidFields(invFields);
        setValid(invFields?.length === 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mailAddress?.salutation, mailAddress?.title, mailAddress?.forename, mailAddress?.name, mailAddress?.vatNo,
    mailAddress?.street, mailAddress?.houseNumber, mailAddress?.houseNumberAnnex, mailAddress?.country,
    mailAddress?.zipCode, mailAddress?.cityName])

    useEffect(() => {
        if (cityNameInput && mailAddress?.country) {
            handleCityChange(cityNameInput, mailAddress.country);
        } /* else {
            cityNameChanged(cityNameInput);
        } */
    }, [cityNameInput, mailAddress?.country]);

    const mailAddressChanged = () => {
        let a = JSON.stringify(modified?.legalEntity?.mailAddresses[0]);
        //      let b = JSON.stringify(_businessPartner.legalEntity.mailAddresses[0]);
        //      setMailAddressDirty(a !== b);
    };

    useEffect(() => {
        if (zipCodeInput && mailAddress?.country) {
            handleZipChange(zipCodeInput, mailAddress?.country);
        } /* else {
            zipCodeChanged(zipCodeInput); // reset zipCode fields
        } */
    }, [zipCodeInput, mailAddress?.country]);

    useEffect(() => {
        if (_bpResponse) {
            if (_bpResponse.success) {
                setSaveMsg(t('m.customerdata.change.success'));
            } else {
                if (_bpResponse.errors && _bpResponse.errors[0] && _bpResponse.errors[0].text && _bpResponse.errors[0].text !== "") {
                    setSaveMsg(_bpResponse.errors[0].text);
                }
            }
            setSnackbarOpen(true);
            setBackdropOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_bpResponse]);

    const closeSnackbarHandler = () => {
        setSnackbarOpen(false);
        if (_bpResponse.success) {
            navigate(-1);
        }
        dispatch(resetBpResponse());
    }

    const handleZipChange = debounce((value, countryCode = 'DE') => {
        if (value) {
            let url = _services.citiesByName.href;
            url = url.replace('{name}', value);
            url = url.replace('{?country}', `?country=${countryCode}`);
            axios.get(url)
                .then(res => {
                    console.log("set cities ...");
                    console.log(enrichCities(res.data._embedded.cityList));
                    setCities(enrichCities(res.data._embedded.cityList));
                });
        }
    }, 500);

    const handleChange = e => {
        console.log("handle change " + e.target.name + " = " + e.target.value);
        const { name, value } = e.target;
        setMailAddress(prevMailAddress => ({
            ...prevMailAddress,
            //         [name]: type !== 'click' ? value : item?.key
            [name]: value
        }));
        console.log(mailAddress);
    };

    const zipCodeChanged = (value) => {
        console.log("zipCodeChanged: " + value);
        setMailAddress(prevMailAddress => ({
            ...prevMailAddress,
            'zipCode': value
        }));
        setZipCodeInput(value);
    }

    const cityNameChanged = (value) => {
        console.log("cityNameChanged: " + value);
        setMailAddress(prevMailAddress => ({
            ...prevMailAddress,
            'cityName': value
        }));
        setCityNameInput(value);
        console.log(mailAddress);
    }

    const handleMarketingApprovalChange = (e) => {
        setMarketingApprovalValue(e.target.checked);
    }




    const handleCityChange = debounce((value, countryCode = 'DE') => {
        if (value) {
            let url = _services.citiesByName.href;
            url = url.replace('{name}', value);
            url = url.replace('{?country}', `?country=${countryCode}`);
            axios.get(url)
                .then(res => {
                    console.log("set zips ...");
                    console.log(enrichCities(res.data._embedded.cityList));
                    setZips(enrichCities(res.data._embedded.cityList));
                });
        }
    }, 500);

    const enrichCities = cities => {
        let list = cities.map(it => Object.assign({}, it, {
            label: it.zip,
            longName: cityLongname(it)
        }));
        return list.sort((a, b) => {
            let fa = a.longName.toLowerCase(),
                fb = b.longName.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    const cityLongname = city => {
        return city.cityName + (city.cityAnnex ? ' ' + city.cityAnnex : '');
    };

    const isRequired = (field) => {
        return _requiredCustomerFormFields.includes(field);
    };

    const isError = (field) => {
        return showError && invalidFields?.findIndex(x => x === field) > -1;
    }

    const cityLabel = city => {
        if (typeof city === 'string') {
            return city;
        }
        else {
            return city.zip + ' ' + city.longName;
        }
    };

    const back = () => {
        if (!_isWSS) {
            let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
            //       tmpBpForModify.legalEntity.mailAddresses.push(mailAddress);
            tmpBpForModify.legalEntity.mailAddresses[0] = mailAddress;
            dispatch(setBpForModify(tmpBpForModify));
        }
        navigate(-1);
    };

    const next = () => {
        console.log(mailAddress);
        if (valid && marketingApprovalValue === true) {
            let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
            //           tmpBpForModify.legalEntity.mailAddresses.push(mailAddress);
            tmpBpForModify.legalEntity.mailAddresses[0] = mailAddress;
            tmpBpForModify.marketingApproval.value = marketingApprovalValue;
            dispatch(setBpForModify(tmpBpForModify));
            if (isNewBp || _isGuestAuthenticated) {
                navigate(getPage('register-contact', DEVICE_MOBILE));
            } else {
                setBackdropOpen(true);
                console.log("save: " + JSON.stringify(tmpBpForModify));
                dispatch(saveBusinessPartner(tmpBpForModify, tmpBpForModify.businessPartnerNo));
            }
        } else {
            setShowError(true);
        }
    };


    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ xwidth: '90%', minHeight: windowHeight - 167 }} direction="column" alignItems="left" spacing={5}>
                <RegistrationPaging step={isNewBp ? 1 : 0} pagetitle='m.register.mail.title' />
                <Divider />
                {mailAddress && (<form><fieldset style={{ border: 'none' }}>
                    <Stack direction="column" sx={{ margin: '1em' }} spacing={5}>

                        {_salutations && (
                            <FormControl variant='standard' sx={{ width: '100%' }} disabled={formIsReadOnly}>
                                <InputLabel required={isRequired('salutationMenu')} id="salutation-label">{t('customerdata.salutation')}</InputLabel>
                                <Select
                                    id="salutation-select"
                                    labelId="salutation-label"
                                    name="salutation"
                                    value={mailAddress?.salutation ? mailAddress.salutation : ''}
                                    error={isError('salutation')}
                                    inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                    IconComponent={SelectIconComponent}
                                    onChange={handleChange}>
                                    {
                                        _salutations.map(e => {
                                            return (
                                                <MenuItem key={e.key} value={e.key}>{e.value}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>)}
                        {_titles && (
                            <FormControl variant="standard" sx={{ minWidth: '9em' }} disabled={formIsReadOnly}>
                                <InputLabel required={isRequired('titleMenu')} id="titel-label">{t('customerdata.title')}</InputLabel>
                                <Select
                                    id="title-select"
                                    labelId="titel-label"
                                    name="title"
                                    value={mailAddress.title ? mailAddress.title : ''}
                                    error={isError('title')}
                                    inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                    IconComponent={SelectIconComponent}
                                    onChange={handleChange}>
                                    {
                                        _titles.map(e => {
                                            return (
                                                <MenuItem key={e.key} value={e.key}>{e.value}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>)}


                        <FormControl required={isRequired('forename')} variant='standard' sx={{ mr: '2em', minWidth: '20em' }} disabled={formIsReadOnly} >
                            <InputLabel id="forename-label">{t('customerdata.forename')}</InputLabel>
                            <Input
                                id="forename-input"
                                name="forename"
                                type="text"
                                error={isError('forename')}
                                inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                value={mailAddress.forename}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl variant="standard" sx={{ mr: '2em', minWidth: '20em' }} disabled={formIsReadOnly}>
                            <InputLabel required={isRequired('name')} id="name-label">{t('customerdata.lastname')}</InputLabel>
                            <Input
                                id="name"
                                type="text"
                                name="name"
                                error={isError('name')}
                                inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                value={mailAddress.name}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormGroup row sx={{ justifyContent: 'space-between' }} >
                            <FormControl variant="standard" sx={{ width: '75%' }} disabled={formIsReadOnly}>
                                <InputLabel required={isRequired('street')} id="street-label">{t('customerdata.street')}</InputLabel>
                                <Input
                                    id="street-input"
                                    type="text"
                                    name="street"
                                    inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                    error={isError('street')}
                                    value={mailAddress.street}
                                    onChange={handleChange}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{ width: '23%' }} disabled={formIsReadOnly}>
                                <InputLabel required={isRequired('number')} id="houseNumber-label">{t('customerdata.houseNumber')}</InputLabel>
                                <Input
                                    id="houseNumber-input"
                                    type="text"
                                    name="houseNumber"
                                    error={isError('number')}
                                    inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                    value={mailAddress.houseNumber}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>

                        <FormGroup row sx={{ justifyContent: 'space-between' }}>
                            <FormControl variant="standard" sx={{ width: '28%' }} disabled={formIsReadOnly}>
                                <Autocomplete
                                    id="zip-input"
                                    name="cities"
                                    options={cities}
                                    disabled={formIsReadOnly}
                                    autoHighlight
                                    isOptionEqualToValue={(option, value) => option.zipCode?.startsWith(value) || value === ''}
                                    popupIcon={<SelectIconComponent size='xs' />}
                                    clearIcon={<ClearIcon size='xs' />}
                                    value={mailAddress.zipCode}
                                    onChange={(e, v) => {
                                        if (v) {
                                            zipCodeChanged(v.zip);
                                            cityNameChanged(v.cityName);
                                        }
                                    }}
                                    onBlur={(e, v) => {

                                        if (zipCodeInput === '') {
                                            zipCodeChanged(zipCodeInput);
                                        }

                                    }}
                                    inputValue={zipCodeInput}
                                    onInputChange={(e, v, r) => {
                                        if ('reset' !== r) {
                                            setZipCodeInput(v);
                                        }
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                required={isRequired('zipCode')}
                                                error={isError('zipCode')}
                                                {...params}
                                                variant="standard"
                                                label={t('customerdata.zip')}
                                                disabled={formIsReadOnly}
                                            />
                                        )
                                    }}
                                    noOptionsText=""
                                    getOptionLabel={(option) => {
                                        return cityLabel(option)
                                    }}
                                    renderOption={(props, option) => (
                                        <Box  {...props}>
                                            {cityLabel(option)}
                                        </Box>
                                    )}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{ width: '70%' }} disabled={formIsReadOnly}>
                                <Autocomplete
                                    id="city-input"
                                    options={zips}
                                    autoHighlight
                                    disabled={formIsReadOnly}
                                    isOptionEqualToValue={(option, value) => option.cityName?.startsWith(value) || value === ''}
                                    value={mailAddress.cityName}
                                    popupIcon={<SelectIconComponent size='xs' />}
                                    clearIcon={<ClearIcon size='xs' />}
                                    onChange={(e, v) => {
                                        if (v) {
                                            zipCodeChanged(v.zip);
                                            cityNameChanged(v.cityName);
                                        }
                                    }}
                                    onBlur={(e, v) => {

                                        if (cityNameInput === '') {
                                            cityNameChanged(cityNameInput);
                                        }

                                    }}
                                    inputValue={cityNameInput}
                                    onInputChange={(e, v, r) => {
                                        if ('reset' !== r) {
                                            setCityNameInput(v);
                                        }
                                    }}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                required={isRequired('city')}
                                                error={isError('cityName')}
                                                {...params}
                                                variant="standard"
                                                label={t('customerdata.city')}
                                                disabled={formIsReadOnly}
                                            />
                                        )
                                    }}
                                    noOptionsText=""
                                    getOptionLabel={(option) => {
                                        return cityLabel(option);
                                    }}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box {...props}>
                                                {cityLabel(option)}
                                            </Box>
                                        )
                                    }}

                                />
                            </FormControl>
                        </FormGroup>
                        {_countries && mailAddress.country && (
                            <FormControl variant='standard' disabled={formIsReadOnly}>
                                <InputLabel required={isRequired('countryMenu')} id="country-label">{t('customerdata.country')}</InputLabel>
                                <Select
                                    id="select-country"
                                    labelId="country-label"
                                    name="country"
                                    value={mailAddress.country}
                                    error={isError('country')}
                                    inputProps={{ readOnly: mailAddress.protectedFromChange }}
                                    IconComponent={SelectIconComponent}
                                    disabled={formIsReadOnly}
                                    onChange={handleChange}>
                                    {
                                        _countries.map(e => {
                                            return (
                                                <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>)
                        }

                        <FormControl
                            sx={{ pt: '2em', flexDirection: 'row', display: formIsReadOnly ? 'none' : 'flex' }}
                            error={showError && !marketingApprovalValue}
                        >
                            <FormControlLabel

                                labelPlacement="end"
                                label={<Typography
                                    sx={{ fontSize: 12 }}
                                    // variant='termsAndConditions'
                                    dangerouslySetInnerHTML={{ __html: t('order.checkbox3') }}></Typography>}
                                control={
                                    <Checkbox
                                        sx={{ color: showError && !marketingApprovalValue ? 'error.main' : 'primary.main' }}
                                        checked={marketingApprovalValue}
                                        onChange={handleMarketingApprovalChange}
                                        size="small" />
                                }
                            />

                        </FormControl>
                    </Stack>
                </fieldset>
                </form >)}
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: isNewBp ? t('m.register.mail.back') : t('m.register.back') }}
                next={!formIsReadOnly ? { clicked: next, label: isNewBp || _isGuestAuthenticated ? t('m.register.mail.next') : t('m.register.save') } : undefined}>
            </NavigationBar>
            <InfoText />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
                message={saveMsg}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
}

export default RegisterMail;
