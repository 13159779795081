//import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { device } from '../redux/Selectors';
import { useEffect } from 'react';

export const DEVICE_MOBILE = 'mobile';
export const DEVICE_DESKTOP = 'desktop';

export const NavigateToPage = (props) => {

    const navigate = useNavigate();
    const _device = useSelector((state) => device(state));

    let path = getPage(props.page, _device);

    navigate(path);
}

export const getPage = (page, device) => {
    let path = '';
    if (device === DEVICE_MOBILE) {
        path = '/webstore/app/m/'
    } else {
        path = '/webstore/app/'
    }

    if (page === 'templates') {
        path = path + 'templates';
    } else if (page === 'creative') {
        path = path + 'creative';
    } else if (page === 'offers') {
        path = path + 'offers';
    } else if (page === 'upselling') {
        path = path + 'upselling';
    } else if (page === 'schedule') {
        path = path + 'schedule';
    } else if (page === 'order') {
        path = path + 'order';
    } else if (page === 'customer') {
        path = path + 'customer';
    } else if (page === 'register') {
        path = path + (device === 'mobile' ? 'register-mail' : 'register');
    } else if (page === 'orders') {
        path = path + (device === 'mobile' ? 'customer-orders' : 'customer');
    } else if (page === 'invoices') {
        path = path + (device === 'mobile' ? 'customer-invoices' : 'customer');
    } else if (page === 'servicepackages') {
        path = path + (device === 'mobile' ? 'customer-servicepackages' : 'customer');
    } else if (page === 'register-payment') {
        path = path + 'register-payment';
    } else if (page === 'register-mail') {
        path = path + 'register-mail';
    } else if (page === 'register-contact') {
        path = path + 'register-contact';
    } else if (page === 'markets') {
        path = path + 'markets';
    } else if (page === 'editions') {
        path = path + 'editions';
    } else if (page === 'preview') {
        path = path + 'preview';
    } else if (page === 'start') {
        path = path + (device === 'mobile' ? 'markets' : 'templates');
    } else if (page === 'external-authentication') {
        path = path + (device === 'mobile' ? 'authentication' : 'authentication');
    }
    return (path);
}

