
import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectedTemplate,
    adBoxNumber,
    adBoxNumberTypes,
    motifColumns,
    properties,
    orderToEdit,
    orderToRepeat,
    pendingOrder,
    isMotifColoured,
    components
} from '../../redux/Selectors.js';
import { setMotifColoured, setMotifColumns, getAdBoxNumber, setAdBoxFields } from '../../redux/Actions.js';

import { useTranslation } from 'react-i18next';
import { Box, Button, Slider, Switch, Tooltip, Typography } from '@mui/material';
import { HelpIcon } from '../../common/widgetTools.js';
import { isMotifColourChangeable } from '../../common/tools.js';

import MsgPopper from '../common/MsgPopper';
import { AdBoxOptions, confirmAdbox, dismissAdbox } from '../AdBox.jsx';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CreativeConfigArea = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _properties = useSelector((state) => properties(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _adBoxNumberTypes = useSelector((state) => adBoxNumberTypes(state));
    const _adBoxNumber = useSelector((state) => adBoxNumber(state));
    const _orderToRepeat = useSelector((state) => orderToRepeat(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _motifColumns = useSelector((state) => motifColumns(state));
    const _isMotifColoured = useSelector((state) => isMotifColoured(state));
    const _components = useSelector((state) => components(state));

    const [adBoxChecked, setAdBoxChecked] = useState(false);
    const [adBoxOptionsOpen, setAdBoxOptionsOpen] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [columnMarks, setColumnMarks] = useState([]);
    const [adboxPreselected, setAdboxPreselected] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const [placement] = useState('right');
    const anchorRef = React.useRef(null);

    useEffect(() => {
        //     console.log(_selectedTemplate);
        return () => {
            setMsgOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /*
     * AdbBox
     */
    useEffect(() => {
        setMsgOpen(props.msgOpen && !adBoxChecked);
    }, [props.msgOpen, adBoxChecked]);

    useEffect(() => {
        setAdBoxChecked(Boolean(_adBoxNumber));
    }, [_adBoxNumber]);

    useEffect(() => {
        if (_adBoxNumber && _components) {
            let userFields = [];
            _components.variables.filter(v => v.semantic.startsWith("AD_BOX_NUMBER_")).forEach(v => {
                let uf = {
                    "name": v.shortName,
                    "force": true
                };
                switch (v.semantic) {
                    case "AD_BOX_NUMBER_TEXT":
                        uf.value = _adBoxNumber ? _adBoxNumber.text : "";
                        break;
                    case "AD_BOX_NUMBER_CODE":
                        uf.value = _adBoxNumber ? _adBoxNumber.code : "";
                        break;
                    default:
                        uf.value = "";
                        break;
                }
                userFields.push(uf);
            });
            dispatch(setAdBoxFields(userFields));
            //           dispatch(setUserFields(userFields));
        } else {
            dispatch(setAdBoxFields());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_adBoxNumber, _components]);

    useEffect(() => {
        setAdboxPreselected((!_orderToEdit && !_orderToRepeat && _selectedTemplate?.presetAdBox) || (_pendingOrder?.adBoxNumber && _selectedTemplate?.allowAdBox) || (_orderToRepeat?.adBoxNumber && _selectedTemplate?.allowAdBox));
    }, [_selectedTemplate, _orderToEdit, _orderToRepeat, _pendingOrder?.adBoxNumber]);

    useEffect(() => {
        setAdBoxOptionsOpen(!_orderToEdit && !_orderToRepeat && _selectedTemplate?.presetAdBox && !_adBoxNumber);
    }, [_selectedTemplate, _orderToEdit, _adBoxNumber, _orderToRepeat]);


    useEffect(() => {
        if (_adBoxNumberTypes && !_adBoxNumber) {
            if (_orderToRepeat?.adBoxNumber || adboxPreselected) {
                //            console.log("resetStyloReady: getAdBoxNumber");
                var type = _orderToRepeat?.adBoxNumber ? _adBoxNumberTypes[_orderToRepeat?.adBoxNumber.type] : _adBoxNumberTypes?.AD_BOX_NUMBER_TYPE_SEND[0];
                var adBoxNumberType;
                if (type === 'AD_BOX_NUMBER_TYPE_PICKUP') {
                    adBoxNumberType = type.find(e => e.branchOfficeCode === _orderToRepeat.adBoxNumber.answerOffice.office)
                } else {
                    adBoxNumberType = type;
                }
                //            console.log(adBoxNumberType);
                dispatch(getAdBoxNumber(adBoxNumberType, 0));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_adBoxNumberTypes, adboxPreselected, _orderToRepeat?.adBoxNumber]);

    const adBoxChanged = (e) => {
        setAdBoxChecked(e.target.checked);
        //        console.log("set adBoxOptionsOpen to " + e.target.checked);
        setAdBoxOptionsOpen(e.target.checked);
        if (!e.target.checked) {
            dismissAdbox();
        }
        setMsgOpen(false);
    };

    const handleCloseAdBoxOptions = (adBoxNumberType) => {
        //       console.log("set adBoxOptionsOpen to false");
        setAdBoxOptionsOpen(false);
        setAdBoxChecked(Boolean(adBoxNumberType));
        if (adBoxNumberType) {
            confirmAdbox(adBoxNumberType);
        }
        else {
            dismissAdbox();
        }
    };

    /*
     * Columns
     */
    useEffect(() => {
        if (_selectedTemplate &&
            _selectedTemplate.unitWidth === 'COLUMNS' &&
            _selectedTemplate.minWidth !== _selectedTemplate.maxWidth) {
            let marks = [];
            for (let i = _selectedTemplate.minWidth; i <= _selectedTemplate.maxWidth; ++i) {
                marks.push({ value: i, label: String(i) });
            }
            setColumnMarks(marks);
        }
    }, [_selectedTemplate]);

    const columnsChanged = (e) => {
        let col = e.target.value;
        dispatch(setMotifColumns(col));
    };

    /*
     * Coloured
     */
    const colouredChanged = (e) => {
        dispatch(setMotifColoured(e.target.checked));
    };


    return (
        <Box>
            {_selectedTemplate &&
                _selectedTemplate.unitWidth === 'COLUMNS' &&
                _selectedTemplate.minWidth !== _selectedTemplate.maxWidth &&
                (
                    <Box id="column-option"
                        alignItems="center"
                        sx={{ mb: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}                    >
                        <Typography sx={{ fontSize: 14, mr: 10, pl: 0 }}>{t('creative.columns')}</Typography>
                        <Slider
                            disabled={props.disabled}
                            size="small"
                            aria-label={t('creative.columns')}
                            valueLabelDisplay="auto"
                            value={_motifColumns || _selectedTemplate.defaultWidth || _selectedTemplate.minWidth}
                            min={_selectedTemplate.minWidth}
                            max={_selectedTemplate.maxWidth}
                            onChange={columnsChanged}
                            marks={columnMarks}
                            sx={{
                                '& .MuiSlider-markLabel': {
                                    fontSize: 14
                                }, '& .MuiSlider-valueLabel': {
                                    fontSize: 16,
                                    backgroundColor: _properties.appPrimaryColor,
                                    borderRadius: 20
                                },
                            }}
                        />
                    </Box>
                )}
            {isMotifColourChangeable(_selectedTemplate) && (
                <Box id="coloured-option" alignItems="center" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}                    >
                    <Typography sx={{ fontSize: 14, ml: 0, pl: 0 }}>{t('creative.coloured')}</Typography>
                    <Switch size="small" onChange={(e) => colouredChanged(e)} checked={_isMotifColoured} disabled={props.disabled} />
                </Box>
            )}
            {_adBoxNumberTypes && _selectedTemplate && _selectedTemplate.allowAdBox && (
                <Box id="adbox-option" ref={anchorRef} alignItems="center" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}                    >
                    <Box alignItems="center" sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ fontSize: 14, ml: 0, pl: 0 }}>{t('creative.adbox')}</Typography>
                        <Tooltip open={showTooltip}
                            onOpen={() => setShowTooltip(true)}
                            onClose={() => setShowTooltip(false)}
                            placement="right"
                            arrow
                            title={t('adboxOptions.tooltip')}>
                            <Box sx={{
                                fontSize: 14, ml: 0, pl: 0, '& .MuiButtonBase-root': {
                                    width: '25px', minWidth: '10px'
                                }
                            }}>
                                <Button
                                    size='small'
                                    color="primary"
                                    onClick={() => setShowTooltip(!showTooltip)}>
                                    <HelpIcon />
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Switch size="small" onChange={(e) => adBoxChanged(e)} checked={adBoxChecked} disabled={props.disabled} />
                </Box>
            )}
            <AdBoxOptions open={adBoxOptionsOpen} handleClose={handleCloseAdBoxOptions} title={t('login.title')} />
            <MsgPopper
                open={msgOpen}
                anchorRef={anchorRef}
                placement={placement}
                arrow={true}
                text={t('creative.adboxRequired')}
            >
            </MsgPopper>

        </Box >
    );

}