import React from 'react';
import axios from 'axios';
import { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    customerType,
    countries,
    requiredCustomerFormFields,
    salutations,
    services,
    titles
} from '../../redux/Selectors.js';
import { fetchCities } from '../../redux/Actions';

import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Autocomplete,
    Box,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormControl,
    Grid,
    InputLabel,
    Input,
    Stack
} from '@mui/material';
import { MenuItem, Select, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';

import { findKeyValue } from '../../common/tools.js';
import { styleForm } from '../../common/styles.js';
import { ChangeCircleRounded, ConstructionOutlined } from '@mui/icons-material';

import { debounce } from '../../common/tools.js';

import { CustomExpandMore, ClearIcon } from '../../common/widgetTools.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const MailAddress = forwardRef(({ mailAddress, legalEntity, dirty, valid, bpNew }, ref) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const _services = useSelector((state) => services(state));
    const _customerType = useSelector((state) => customerType(state));
    const _countries = useSelector((state) => countries(state));
    const _salutations = useSelector((state) => salutations(state));
    const _titles = useSelector((state) => titles(state));
    const _requiredCustomerFormFields = useSelector((state) => requiredCustomerFormFields(state));

    const [salutation, setSalutation] = useState('');
    const [title, setTitle] = useState('');
    const [forename, setForename] = useState('');
    const [name, setName] = useState('');
    const [vatNo, setVatNo] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState(0);
    const [houseNumberAnnex, setHouseNumberAnnex] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeInput, setZipCodeInput] = useState('');
    const [zips, setZips] = useState([]);
    const [cityName, setCityName] = useState('');
    const [cityNameInput, setCityNameInput] = useState('');
    const [cities, setCities] = useState([]);
    const [showError, setShowError] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);


    const [protectedFromChange, setProtectedFromChange] = useState(false);

    useImperativeHandle(ref, () => ({
        showErrors: () => {
            setShowError(true);
        }
    }));

    useEffect(() => {
        let invFields = [];
        if (isRequired('salutationMenu') && (salutation === undefined)) {
            invFields.push('salutation');
        }
        if (isRequired('titleMenu') && title === undefined) {
            invFields.push('title');
        }
        if (isRequired('forename') && forename === '') {
            invFields.push('forename');
        }
        if (isRequired('name') && name === '') {
            invFields.push('name');
        }
        if (isRequired('street') && street === '') {
            invFields.push('street');
        }
        if (isRequired('number') && houseNumber === '') {
            invFields.push('houseNumber');
        }
        if (isRequired('countryMenu') && countryCode === '') {
            invFields.push('countryCode');
        }
        if (isRequired('zipCode') && zipCode === '') {
            invFields.push('zipCode');
        }
        if (isRequired('city') && cityName === '') {
            invFields.push('cityName');
        }
        if (isRequired('vatNo') && legalEntity?.vatNo === '' && isBusiness) {
            invFields.push('vatNo');
        }
        console.log(invFields);
        setInvalidFields(invFields);
        if (valid) {
            valid(invFields?.length === 0);
        }
    }, [salutation, title, forename, name, vatNo, street, houseNumber, houseNumberAnnex, countryCode, zipCode, cityName])

    const isBusiness = Boolean('business' === _customerType);

    const handleSalutationChange = (event) => {
        const item = findKeyValue(_salutations, event.target.value);
        setSalutation(item);
        mailAddress.salutation = item.key;
        dirty();
    };

    const handleTitleChange = (event) => {
        const item = findKeyValue(_titles, event.target.value);
        setTitle(item);
        mailAddress.title = item.key;
        dirty();
    };

    const forenameChanged = (value) => {
        setForename(value);
        mailAddress.forename = value;
        dirty();
    }

    const nameChanged = (value) => {
        setName(value);
        mailAddress.name = value;
        dirty();
    }

    const streetChanged = (value) => {
        setStreet(value);
        mailAddress.street = value;
        dirty();
    }

    const houseNumberChanged = (value) => {
        setHouseNumber(value);
        mailAddress.houseNumber = value;
        dirty();
    }

    const houseNumberAnnexChanged = (value) => {
        // if ('' === value) {
        //     value = null;
        // }
        setHouseNumberAnnex(value);
        mailAddress.houseNumberAnnex = value;
        dirty();
    }

    const countryCodeChanged = (event) => {
        setCountryCode(event.target.value);
        mailAddress.country = event.target.value;
        dirty();
    };

    const vatNoChanged = (value) => {
        setVatNo(value);
        legalEntity.vatNo = value;
        dirty();
    };

    /*
     * zipCode
     */
    const zipCodeChanged = (value) => {
        console.log("zipCodeChanged: " + value);
        setZipCode(value);
        setZipCodeInput(value);
        mailAddress.zipCode = value;
        dirty();
    }

    useEffect(() => {
        console.log("zipCodeInput = " + zipCodeInput);
        if (zipCodeInput && countryCode) {
            setZipCode(zipCodeInput);
            handleZipChange(zipCodeInput, countryCode);
        } /* else {
            zipCodeChanged(zipCodeInput); // reset zipCode fields
        } */
    }, [zipCodeInput, countryCode]);

    const handleZipChange = debounce((value, countryCode = 'DE') => {
        if (value) {
            let url = _services.citiesByName.href;
            url = url.replace('{name}', value);
            url = url.replace('{?country}', `?country=${countryCode}`);
            axios.get(url).
                then(res => {
                    console.log("set cities ...");
                    console.log(enrichCities(res.data._embedded.cityList));
                    setCities(enrichCities(res.data._embedded.cityList));
                });
        }
    }, 500);


    /*
     *  cityName
     */
    const cityNameChanged = (value) => {
        console.log("cityNameChanged: " + value);
        setCityName(value);
        setCityNameInput(value);
        mailAddress.cityName = value;
    }

    useEffect(() => {
        if (cityNameInput && countryCode) {
            handleCityChange(cityNameInput, countryCode);
        } /* else {
            cityNameChanged(cityNameInput);
        } */
    }, [cityNameInput, countryCode]);

    const handleCityChange = debounce((value, countryCode = 'DE') => {
        if (value) {
            let url = _services.citiesByName.href;
            url = url.replace('{name}', value);
            url = url.replace('{?country}', `?country=${countryCode}`);
            axios.get(url).
                then(res => {
                    console.log("set zips ...");
                    console.log(enrichCities(res.data._embedded.cityList));
                    setZips(enrichCities(res.data._embedded.cityList));
                });
        }
    }, 500);

    const enrichCities = cityList => {
        let list = cityList.map(it => Object.assign({}, it, {
            label: it.zip,
            longName: cityLongname(it)
        }));
        return list.sort((a, b) => {
            let fa = a.longName.toLowerCase(),
                fb = b.longName.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    const cityLongname = city => {
        return city.cityName + (city.cityAnnex ? ' ' + city.cityAnnex : '');
    };

    const cityLabel = city => {
        if (typeof city === 'string') {
            return city;
        }
        else {
            return city.zip + ' ' + city.longName;
        }
    };


    useEffect(() => {
        let sal = findKeyValue(_salutations, mailAddress?.salutation);
        setSalutation(sal?.key ? sal : '');
        setTitle(findKeyValue(_titles, mailAddress?.title ? mailAddress?.title : ''));
        setForename(mailAddress?.forename);
        setName(mailAddress?.name);
        if (legalEntity && legalEntity?.vatNo) {
            setVatNo(legalEntity?.vatNo);
        }
        setStreet(mailAddress?.street);
        setHouseNumber(mailAddress?.houseNumber);
        if (mailAddress?.houseNumberAnnex) {
            setHouseNumberAnnex(mailAddress.houseNumberAnnex);
        }
        setCountryCode(mailAddress?.country);
        setZipCode(mailAddress?.zipCode ? mailAddress?.zipCode : '');
        setZipCodeInput(mailAddress?.zipCode);
        setCityName(mailAddress?.cityName ? mailAddress?.cityName : '');
        setCityNameInput(mailAddress?.cityName);
        // handleZipChange(mailAddress.zipCode, mailAddress.country);
        // handleCityChange(mailAddress.cityName, mailAddress.country);
        setProtectedFromChange(mailAddress?.isProtectedFromChange);

        return (() => {
        })

    }, []);

    const findCountry = code => _countries.find(e => e.code === code);

    const isRequired = (field) => {
        return _requiredCustomerFormFields.includes(field);
    };

    const isError = (field) => {
        return showError && invalidFields?.findIndex(x => x === field) > -1;
    }

    const nameOfWidgetsFromAdmin = "salutationMenu,titleMenu,countryMenu,forename,name,street,number,zipCode,city,fixedInternational,fixedAreaCode,fixedSubscriber,mobileInternational,mobileAreaCode,mobileSubscriber";

    return (
        <form>
            <Stack direction="column" sx={{ margin: '1em' }}>
                <Typography variant="pageTitleBold">
                    {t('customerdata.address.title')}
                </Typography>

                <FormGroup sx={{ mt: '2em', flexDirection: { xs: "column", sm: "row" } }} >
                    {_salutations && (
                        <FormControl variant='standard' sx={{ mr: '2em', minWidth: '9em' }} >
                            <InputLabel required={isRequired('salutationMenu')} id="salutation-label">{t('customerdata.salutation')}</InputLabel>
                            <Select
                                id="salutation-select"
                                labelId="salutation-label"
                                value={salutation && salutation !== '' ? salutation.key : ''}
                                error={isError('salutation')}
                                inputProps={{ readOnly: protectedFromChange }}
                                IconComponent={CustomExpandMore}
                                onChange={handleSalutationChange}>
                                {
                                    _salutations.map(e => {
                                        return (
                                            <MenuItem key={e.key} value={e.key}>{e.value}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>)}
                    {_titles && (
                        <FormControl variant="standard" sx={{ minWidth: '9em' }} >
                            <InputLabel required={isRequired('titleMenu')} id="titel-label">{t('customerdata.title')}</InputLabel>
                            <Select
                                id="title-select"
                                labelId="titel-label"
                                value={title && title !== '' ? title.key : ''}
                                error={isError('title')}
                                inputProps={{ readOnly: protectedFromChange }}
                                IconComponent={CustomExpandMore}
                                onChange={handleTitleChange}>
                                {
                                    _titles.map(e => {
                                        return (
                                            <MenuItem key={e.key} value={e.key}>{e.value}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>)}
                </FormGroup>

                <FormGroup sx={{ mt: '1em', flexDirection: { xs: "column", sm: "row" } }}>
                    {!isBusiness && (
                        <FormControl required={isRequired('forename')} variant='standard' sx={{ mr: '2em', minWidth: '20em' }} >
                            <InputLabel id="forename-label">{t('customerdata.forename')}</InputLabel>
                            <Input
                                id="forename-input"
                                type="text"
                                error={isError('forename')}
                                inputProps={{ readOnly: protectedFromChange }}
                                value={forename}
                                onChange={e => forenameChanged(e.target.value)}
                            />
                        </FormControl>
                    )}
                    <FormControl variant="standard" sx={{ mr: '2em', minWidth: '20em' }}>
                        <InputLabel required={isRequired('name')} id="name-label">{t(isBusiness ? 'customerdata.company' : 'customerdata.lastname')}</InputLabel>
                        <Input
                            id="name"
                            type="text"
                            error={isError('name')}
                            inputProps={{ readOnly: protectedFromChange }}
                            value={name}
                            onChange={e => nameChanged(e.target.value)}
                        />
                    </FormControl>
                    {isBusiness && (
                        <FormControl variant="standard" >
                            <InputLabel id="vatno-label">{t('customerdata.vatNo')}</InputLabel>
                            <Input
                                id="vatno-input"
                                type="text"
                                error={isError('vatNo')}
                                inputProps={{ readOnly: protectedFromChange }}
                                value={vatNo}
                                onChange={e => vatNoChanged(e.target.value)}
                            />
                        </FormControl>
                    )}
                </FormGroup>
                <FormGroup sx={{ mt: '1em', flexDirection: { xs: "column", sm: "row" } }}>
                    <FormControl variant="standard" sx={{ mr: '2em', width: '20em' }}>
                        <InputLabel required={isRequired('street')} id="street-label">{t('customerdata.street')}</InputLabel>
                        <Input
                            id="street-input"
                            type="text"
                            inputProps={{ readOnly: protectedFromChange }}
                            error={isError('street')}
                            value={street}
                            onChange={e => streetChanged(e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ mr: '2em', width: '4em' }}>
                        <InputLabel required={isRequired('number')} id="houseNumber-label">{t('customerdata.houseNumber')}</InputLabel>
                        <Input
                            id="houseNumber-input"
                            type="text"
                            error={isError('number')}
                            inputProps={{ readOnly: protectedFromChange }}
                            value={houseNumber}
                            onChange={e => houseNumberChanged(e.target.value)}
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ width: '4em' }}>
                        <InputLabel id="houseNumberAnnex-label">{t('customerdata.houseNumberAnnex')}</InputLabel>
                        <Input
                            variant="standard"
                            label={t('customerdata.houseNumberAnnex')}
                            error={isError('houseNumberAnnex')}
                            type="text"
                            inputProps={{ readOnly: protectedFromChange }}
                            value={houseNumberAnnex}
                            onChange={e => houseNumberAnnexChanged(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup sx={{ mt: '1em', flexDirection: { xs: "column", sm: "row", width: "100%" } }}>
                    {_countries && countryCode && (
                        <FormControl variant='standard' sx={{ mr: '2em', minWidth: '9em' }} >
                            <InputLabel required={isRequired('countryMenu')} id="country-label">{t('customerdata.country')}</InputLabel>
                            <Select
                                id="select-country"
                                labelId="country-label"
                                value={countryCode}
                                error={isError('countryCode')}
                                inputProps={{ readOnly: protectedFromChange }}
                                IconComponent={CustomExpandMore}
                                onChange={countryCodeChanged}>
                                {
                                    _countries.map(e => {
                                        return (
                                            <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>)
                    }
                    <FormControl variant="standard" sx={{ mr: '2em', width: '9em' }} >
                        <Autocomplete
                            id="zip-input"
                            options={cities}
                            autoHighlight
                            value={zipCode}
                            popupIcon={<CustomExpandMore size='xs' />}
                            clearIcon={<ClearIcon size='xs' />}
                            isOptionEqualToValue={(option, value) => option.zip.startsWith(value) || value === ''}
                            onChange={(e, v) => {
                                if (v) {
                                    zipCodeChanged(v.zip);
                                    cityNameChanged(v.cityName);
                                }
                            }}
                            onBlur={(e, v) => {

                                if (zipCodeInput === '') {
                                    zipCodeChanged(zipCodeInput);
                                }

                            }}
                            inputValue={zipCodeInput}
                            onInputChange={(e, v, r) => {
                                if ('reset' !== r) {
                                    setZipCodeInput(v);
                                }
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        required={isRequired('zipCode')}
                                        error={isError('zipCode')}
                                        {...params}
                                        variant="standard"
                                        label={t('customerdata.zip')}
                                    />
                                )
                            }}
                            noOptionsText=""
                            getOptionLabel={(option) => {
                                return cityLabel(option)
                            }}
                            renderOption={(props, option) => (
                                <Box {...props}>
                                    {/* {option.label + " " + option.cityName + (option.cityAnnex ? " " + option.cityAnnex : '')} */}
                                    {cityLabel(option)}
                                </Box>
                            )}
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ mr: '1em', width: '15em' }} >
                        <Autocomplete
                            id="city-input"
                            options={zips}
                            autoHighlight
                            value={cityName}
                            isOptionEqualToValue={(option, value) => option.cityName.startsWith(value) || value === ''}
                            popupIcon={<CustomExpandMore size='xs' />}
                            clearIcon={<ClearIcon size='xs' />}
                            onChange={(e, v) => {
                                if (v) {
                                    zipCodeChanged(v.zip);
                                    cityNameChanged(v.cityName);
                                }
                            }}
                            onBlur={(e, v) => {

                                if (cityNameInput === '') {
                                    cityNameChanged(cityNameInput);
                                }

                            }}
                            inputValue={cityNameInput}
                            onInputChange={(e, v, r) => {
                                if ('reset' !== r) {
                                    setCityNameInput(v);
                                }
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        required={isRequired('city')}
                                        error={isError('cityName')}
                                        {...params}
                                        variant="standard"
                                        label={t('customerdata.city')}
                                    />
                                )
                            }}
                            noOptionsText=""
                            getOptionLabel={(option) => {
                                return cityLabel(option);
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <Box {...props}>
                                        {cityLabel(option)}
                                    </Box>
                                )
                            }}

                        />
                    </FormControl>
                </FormGroup>
            </Stack>
        </form >
    );

});

export const MailAddressSmall = ({ address }) => {
    const { t } = useTranslation();
    const _countries = useSelector((state) => countries(state));
    let filtered = _countries.filter(it => it.code === address.country);
    let country = filtered && filtered.length === 1 ? filtered[0].name : '';

    return (<>
        <Grid container width='100%' >
            <Grid item xs={4} >
                <Typography variant="cardData">
                    {t('customerdata.home.address.address')}:
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="cardData">
                    {`${address.street} ${address.houseNumber}${address.houseNumberAnnex || ''}`}
                </Typography>
            </Grid>
        </Grid >
        <Grid container width='100%' >
            <Grid item xs={4} >
                <Typography variant="cardData">
                    {''}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="cardData">
                    {`${address.zipCode} ${address.cityName}`}
                </Typography>
            </Grid>
        </Grid>
        <Grid container width='100%' >
            <Grid item xs={4} >
                <Typography variant="cardData">
                    {''}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="cardData">
                    {`${country}`}
                </Typography>
            </Grid>
        </Grid>
    </>
    );
}



