import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Card, CardContent, FormControl, Grid, Typography, Box, Stack,
    Button
} from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import { EditIcon } from '../../common/widgetTools';
import { EditionPartHierarchieSummary } from './EditionPartHierarchie';
import { useSelector, useDispatch } from 'react-redux';
import {
    pendingOrder, selectedMarket, selectedOffer, orderPriceResponse,
    editionDisplayNames, selectedEditionPart, orderToEdit, promotionalText,
    offers, orderCatchword
} from '../../redux/Selectors.js';
import format from 'date-fns/format';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import { DataBold, Label } from '../common/Commons';
import { DEVICE_DESKTOP, getPage } from '../../common/navigationTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderSummaryCard = ({ height }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [displayDate, setDisplayDate] = useState('');

    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _editionDisplayNames = useSelector((state) => editionDisplayNames(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _promotionalText = useSelector((state) => promotionalText(state));
    const _offers = useSelector((state) => offers(state));
    const _orderCatchword = useSelector((state) => orderCatchword(state));

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (_pendingOrder) {
        }
    }, [_pendingOrder]);

    useEffect(() => {
        console.log("OrderSummaryCard called useEffect .... ");
        if (_orderPriceResponse) {
            console.log(_orderPriceResponse);
            var orderPrice = _orderPriceResponse.value;
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(" \u2022 ");
            setDisplayDate(displayDate);
        }
    }, [_orderPriceResponse, i18n.language]);

    function getLocaleNumberString(num) {
        if (num) {
            return ((parseFloat(num) / 1000).toLocaleString() + " mm");
        } else {
            return '';
        }
    }



    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('order.bookingCardTitle')} />
            <CardContent>
                <Box
                    sx={{
                        mb: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: height,
                        overflow: "hidden"

                    }}
                >
                    <FormControl sx={{ width: '100%' }}>
                        <Grid container width='100%' spacing='10'>
                            <Grid item xs={4} >
                                <Label text='order.category' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={_selectedMarket ? _selectedMarket.name : 'xxx'} />
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.offer' />
                            </Grid>
                            <Grid item xs={8}>
                                <Stack direction='row'>
                                    <DataBold text={_selectedOffer ? _selectedOffer.name : 'xxx'} />
                                    {!_orderToEdit && _offers.length > 1 && (
                                        <Button onClick={() => navigate(getPage('offers', DEVICE_DESKTOP))}>
                                            <EditIcon size='sm' />
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.editionPart' />
                            </Grid>
                            <Grid item xs={8}>
                                <Stack direction='row'>
                                    <EditionPartHierarchieSummary variant="oneLevel" />
                                    {!_orderToEdit && (
                                        <Button onClick={() => navigate(getPage('schedule', DEVICE_DESKTOP))}>
                                            <EditIcon size='sm' />
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.catchword' />
                            </Grid>
                            <Grid item xs={8}>
                                <Stack direction='row'>
                                    <DataBold text={_orderCatchword} />
                                    {!_orderToEdit && (
                                        <Button onClick={() => navigate(getPage('schedule', DEVICE_DESKTOP))}>
                                            <EditIcon size='sm' />
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.dates' />
                            </Grid>
                            <Grid item xs={8}>
                                <Stack direction='row'>
                                    <DataBold text={displayDate ? displayDate : ''} />
                                    {!_orderToEdit && (
                                        <Button onClick={() => navigate(getPage('schedule', DEVICE_DESKTOP))}>
                                            <EditIcon size='sm' />
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.editions' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={_editionDisplayNames ? _editionDisplayNames : ''} />
                            </Grid>

                            <Grid item xs={4} >
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 12 }} >
                                    {_promotionalText ? _promotionalText : ''}
                                </Typography>
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.motifWidth' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={getLocaleNumberString(_pendingOrder?.motif.width)} />
                                {(parseInt(_pendingOrder?.motif?.columnCount) > 0) && (<Typography>
                                    {t('order.motifColumn', { column: _pendingOrder.motif.columnCount })}
                                </Typography>)}
                            </Grid>

                            <Grid item xs={4} >
                                <Label text='order.motifHeight' />
                            </Grid>
                            <Grid item xs={8}>
                                <DataBold text={getLocaleNumberString(_pendingOrder?.motif.height)} />
                            </Grid>

                        </Grid>
                    </FormControl>
                </Box>
            </CardContent >
        </Card >
    );

}