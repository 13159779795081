import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Box,
    Grid,
    Skeleton,
    Stack,
    Typography
} from '@mui/material'
import { CcMenuItem, CcSelect } from '../../AppTheme';
import { DataGrid } from '@mui/x-data-grid';

import {
    fetchInvoices,
    setInvoicesPeriod
} from '../../redux/Actions.js';
import {
    invoices,
    invoicesPeriod,
    datagridLocaleText
} from '../../redux/Selectors.js';

const invoicesList = (invoices, t) => {
    return invoices.map(e => {
        return {
            id: e.invoiceNumber,
            invoiceDate: t('common.date.short', { value: new Date(e.invoiceDate) }),
            number: e.invoiceNumber,
            description: e.description,
            value: e.grossValue.value,
            //            value: t('global.currency.' + e.grossValue.currency, { value: e.grossValue.value }),
            download: e
        }
    });
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Invoices = ({ showBusyIndicator }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _invoices = useSelector((state) => invoices(state));
    const _datagridLocaleText = useSelector(state => datagridLocaleText(state));
    const period = useSelector((state) => invoicesPeriod(state));
    const [rows, setRows] = useState([]);

    useEffect(() => {
        dispatch(fetchInvoices(period));
        showBusyIndicator(true)
    }, [period]);

    const handlePeriodChanged = e => {
        dispatch(setInvoicesPeriod(e.target.value));
    };

    useEffect(() => {
        setRows(invoicesList(_invoices, t));
        showBusyIndicator(false)
    }, [_invoices]);

    const PeriodSelection = () => {
        const ID = 'select-period';
        return (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing={'0.5em'}
            >
                <Typography variant="text">{t('customerdata.invoices.period.title', { count: _invoices.length })}</Typography>
                <CcSelect
                    sx={{ xfontSize: 14, ml: '10' }}
                    id={ID}
                    value={period}
                    onChange={handlePeriodChanged}>
                    <CcMenuItem value={'month'}>{t('customerdata.invoices.period.month')}</CcMenuItem>
                    <CcMenuItem value={'half'}>{t('customerdata.invoices.period.half')}</CcMenuItem>
                    <CcMenuItem value={'year'}>{t('customerdata.invoices.period.year')}</CcMenuItem>
                </CcSelect>
            </Stack>
        )
    }

    const startDownload = e => {
        e.stopPropagation();
        const id = e.target.download;
        const checkDownload = (id, count) => {
            if (document.cookie.indexOf("download=" + id) != -1 || count >= 20) {
                showBusyIndicator(false);
            }
            else {
                setTimeout(() => checkDownload(id, count + 1), 500);
            }
        }
        showBusyIndicator(true);
        document.cookie = "download=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkDownload(id, 0);
    };

    const columns = [
        { field: 'invoiceDate', headerName: t('invoice.date'), width: 200, type: Date },
        { field: 'number', headerName: t('invoice.number'), width: 200 },
        {
            field: 'value',
            headerName: t('invoice.value'),
            width: 200,
            type: Number,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: params => {
                // todo: get currency by params.id from order-list
                return t('global.currency.' + ('EUR'), { value: params.value });
            },
        },
        {
            field: 'download',
            headerName: t('invoice.download'),
            width: 200,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => {
                const fileName = `${params.value.invoiceNumber}.pdf`;
                return <a
                    target="_blank"
                    download={fileName}
                    onClick={startDownload}
                    href={params.value._links.download.href}>{params.value.invoiceNumber}.pdf</a>;
            }
        }
    ];

    return (
        <Stack
            sx={{ m: '1em' }}
            direction="column"
            justifyContent="space-between"
            spacing="0.5em"
            alignItems="left">

            <Typography variant="pageTitleBold">
                {t('customerdata.invoices.title')}
            </Typography>

            <PeriodSelection />
            <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    localeText={_datagridLocaleText}
                />
            </Box>
        </Stack>

    );
}

/**
 * 
 * 
 * @param {*} orders 
 * @returns 
 */
export const InvoicesSmall = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _invoices = useSelector((state) => invoices(state));
    const period = useSelector((state) => invoicesPeriod(state));
    const [pending, setPending] = useState(true);
    const [list, setList] = useState([]);

    let timeoutId;
    useEffect(() => {
        timeoutId = setTimeout(() => { setPending(false) }, 5000);
        return (() => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        });
    }, []);

    useEffect(() => {
        setPending(true);
        dispatch(fetchInvoices(period));
    }, [period]);

    useEffect(() => {
        const l = []
        invoicesList(_invoices, t).forEach(function (item, index, array) {
            if (index < 3) {
                l.push(item);
            }
        });
        setList(l);
        setPending(Boolean(_invoices.length === 0));
    }, [_invoices]);

    return (!pending ? (
        <Box>
            {
                list.map(it => {
                    return (<Grid key={it.number} container width='100%' >
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {t('common.date.long', { value: it.invoiceDate })}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="cardData">
                                {it.number} - {it.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    )
                })
            }
        </Box>
    ) : (< Box >
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
    ));
}


