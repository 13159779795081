// eslint-disable-next-line no-unused-vars
const _log = (...messages) => {
	console.log(String(new Date()).split(' ')[4], "Expose:", messages);
}

// Status:
// -1: nicht gefunden
// 0: importiert, aber offline
// 1: online
// 2: nicht mehr online
// 3: teilweise online

/**
 * 
 */
export const Expose = {

	/**
	 * 
	 */
	status: (order, status) => {

		const UNKNOWN = -1;
		// eslint-disable-next-line no-unused-vars
		const IMPORTED = 0;
		const ONLINE = 1;
		const OFFLINE = 2;

		if ('ClassmarketsComponent' === status.portal) {
			switch (order.orderState) {
				case 'printCanceled':
					status.state = OFFLINE;
					break;
				default:
					status.state = ONLINE;
					break;
			}
			if (order.endDate && new Date(order.endDate) < new Date()) {
				status.state = OFFLINE;
			}
		}
		else if (UNKNOWN !== status.state) {
			status.statisticsLink = order._links['expose-statistics'].href;
			status.stornoLink = order._links['expose-storno'].href;
		}

		return status;

	}

};
