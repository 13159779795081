import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    bpForModify, businessPartner, isWSS, properties, isGuestAuthenticated
} from '../../redux/Selectors.js';
import {
    setBpForModify
} from '../../redux/Actions.js';
import { IconButton, Box, Stack, Typography } from '@mui/material';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import {
    ContactMailIcon, AccountBoxIcon, ContactIcon, PaymentsIcon, OrdersIcon,
    InvoiceIcon, ServicePackageIcon, DraftsIcon
} from '../../common/widgetTools';
import { getPage } from '../../common/navigationTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Customer = (props) => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { tab } = useParams()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const MAIL_PAGE = "register-mail";
    const CONTACT_PAGE = "register-contact";
    const PAYMENT_PAGE = "register-payment";
    const ORDERS_PAGE = "orders";
    const INVOICES_PAGE = "invoices";
    const SERVICEPACKAGES_PAGE = "servicepackages";
    const DRAFTS_PAGE = "customer-drafts";

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _properties = useSelector((state) => properties(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));

    useEffect(() => {
        //        alert("Customer ...");
        if ((!_bpForModify || _bpForModify === '{}') && _businessPartner) {
            dispatch(setBpForModify(_businessPartner));
        }
    }, []);


    const next = () => {
        //      navigate(getPage('templates', DEVICE));
    }

    const back = () => {
        navigate(-1);
    }
    const buttonStyle = () => {

        return (
            {
                padding: '0.2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                color: _properties.appPrimaryColor,
                backgroundColor: _properties.appBackgroundColor,
                border: '1px solid',
                borderRadius: '10px',
                cursor: 'pointer'
            }
        );
    };

    const typographyStyle = market => {
        return (
            {
                color: _properties.appPrimaryColor,
                fontSize: 28
            }
        );
    }


    const DataButton = ({ title, page }) => {
        return (
            <Box
                sx={buttonStyle}
                onClick={(e) => navigate(getPage(page, 'mobile'))}
            >
                <Stack spacing={5} direction="row" alignItems="center" justifyContent="left">
                    {page === MAIL_PAGE && (<ContactMailIcon size='xl' />)}
                    {page === CONTACT_PAGE && (<ContactIcon size='xl' />)}
                    {page === PAYMENT_PAGE && (<PaymentsIcon size='xl' />)}
                    {page === ORDERS_PAGE && (<OrdersIcon size='xl' />)}
                    {page === INVOICES_PAGE && (<InvoiceIcon size='xl' />)}
                    {page === SERVICEPACKAGES_PAGE && (<ServicePackageIcon size='xl' />)}
                    {page === DRAFTS_PAGE && (<DraftsIcon size='xl' />)}
                    <Typography
                        variant='templatePickerTitle'
                        sx={typographyStyle}
                    >{t(title)}
                    </Typography>
                </Stack>
            </Box>
        );
    }

    return (<>
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <Typography sx={{ mt: '1rem' }} variant="mobilePagePrimaryLabel">{t('m.customerdata.choose')}</Typography>
                <Stack direction="column" spacing={6}>
                    <DataButton title='customerdata.address.title' page={MAIL_PAGE} />
                    <DataButton title='customerdata.contact.title' page={CONTACT_PAGE} />
                    <DataButton title='customerdata.payment.title2' page={PAYMENT_PAGE} />
                    {!_isGuestAuthenticated && (<DataButton title='status.myOrders' page={ORDERS_PAGE} />)}
                    {_isWSS === false && (<DataButton title='status.myInvoices' page={INVOICES_PAGE} />)}
                    {_isWSS === false && _bpForModify?.servicePackages?.length > 0 && (<DataButton title='status.myServicePackages' page={SERVICEPACKAGES_PAGE} />)}
                </Stack >
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: t('customerdata.back') }}
            />
            <InfoText />
        </Stack >
    </>);

}

export default Customer;