import React from 'react';
import { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import { device, properties } from '../redux/Selectors.js';
import { useSelector } from 'react-redux';


const Splash = () => {

    const _device = useSelector((state) => device(state));
    const _properties = useSelector((state) => properties(state));

    //    const [splashImage, setSplashImage] = useState('/webstore/service/v1/masterdata/resources/image/desktop.png');
    const [splashImage, setSplashImage] = useState('');

    useEffect(() => {
        if (_device === 'desktop') {
            setSplashImage('/webstore/service/v1/masterdata/resources/image/desktop.png');
        } else if (window.innerWidth < _properties.splashSmall) {
            setSplashImage('/webstore/service/v1/masterdata/resources/image/phone.png');
        } else if (window.innerWidth < _properties.splashMedium) {
            setSplashImage('/webstore/service/v1/masterdata/resources/image/mini.png');
        } else if (window.innerWidth < _properties.splashLarge) {
            setSplashImage('/webstore/service/v1/masterdata/resources/image/tablet.png');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_device])

    return (
        <Box id="splash" sx={{ backgroundImage: `url(${splashImage})`, backgroundColor: _properties.splashBackground }}>
            <LinearProgress></LinearProgress>
        </Box>
    );
}
export default Splash;
