import { urlParamsToState, customerTypeFromLE, propertiesToState } from "../common/tools.js";

const initialState = () => {
    return {
        properties: {},
        defaults: {},
        serviceVersionInfo: null,
        tenant: null
    };
};

export const ApiReducer = (state, action) => {
    if (!state) {
        return {
            services: {},
            ts: Date.now()
        };
    }
    let newState = state;
    switch (action.type) {
        case 'LOAD_SERVICES_REQUEST':
            break;
        case 'LOAD_SERVICES_SUCCESS':
            newState = Object.assign({}, state, {
                services: Object.assign({}, state.services, { common: action.json._links }),
                serviceVersionInfo: action.json.serviceVersionInfo,
                tenant: action.json.tenant,
                version: action.json.version,
                activeProfiles: action.json.activeProfiles,
                ts: Date.now(),
                exposeOperations: action.json.exposeOperations,
                sessionTimeoutSeconds: action.json.sessionTimeoutSeconds
            });
            break;
        case 'LOAD_SERVICES_FAILURE':
            console.log(action.type, action.error);
            break;
        case 'LOAD_PRIVATE_SERVICES_SUCCESS':
            newState = Object.assign({}, state, { services: Object.assign({}, state.services, { 'private': action.json._links }) });
            break;
        case 'LOAD_BUSINESS_SERVICES_SUCCESS':
            newState = Object.assign({}, state, { services: Object.assign({}, state.services, { 'business': action.json._links }) });
            break;
        default:
            break;
    }
    return newState;
};

export const CommonReducer = (state, action) => {
    if (!state) {
        return initialState();
    }
    let newState = state;
    switch (action.type) {
        case 'INIT_ORDER_SUCCESS':
            newState = Object.assign({}, state, { sapSessionId: action.json.sapSessionId });
            break;
        case 'RESET_REPEATED_ORDER':
            newState = Object.assign({}, state, { orderCode: null });
            break;
        case 'URL_PARAMS':
            newState = Object.assign({}, state, urlParamsToState(state));
            break;
        case 'SET_PROPERTIES':
            newState = Object.assign({}, state, propertiesToState(state, action.value));
            break;
        case 'SET_BUSINESS_PARTNER':
            newState = Object.assign({}, state, { customerType: customerTypeFromLE(action.businessPartner.legalEntity.type) });
            break;
        // set customer type
        case 'FETCH_BP_SUCCESS':
            newState = Object.assign({}, state, {
                orgMarketingApproval: action.json.marketingApproval.value,
                customerType: customerTypeFromLE(action.json.legalEntity.type)
            });
            break;
        case 'SET_CUSTOMER_TYPE':
            newState = Object.assign({}, state, {
                customerType: action.value
            });
            break;

        case 'LOAD_FEATURES_SUCCESS':
            newState = Object.assign({}, state, { features: action.json });
            break;
        case 'GET_AUTH_PROVIDER_SUCCESS':
            newState = Object.assign({}, state, { oAuthProvider: action.json._embedded ? action.json._embedded.authConfigList : null });
            break;
        case 'FETCH_PROPERTIES_SUCCESS':
            newState = Object.assign({}, state, propertiesToState(state, action));
            break;
        case 'SET_MARKETING_APPROVAL':
            newState = Object.assign({}, state, {
                orgMarketingApproval: action.value
            });
            break;
        case 'SET_PERFLOG_BASEEVENT':
            newState = Object.assign({}, state, { perflogBaseEvent: action.value });
            break;
        case 'LOCALES_SUCCESS':
            break;
        default:
            break;


    }
    return newState;
};
