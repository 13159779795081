import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import ModalDialog from '../ModalDialog.js';
import ExternalAuthentication from '../../frames/Authentication';
import {
    authentication,
    businessPartner,
    device,
    features,
    properties,
    services,
    userName
} from '../../redux/Selectors.js';
import {
    getAuthentication,
    setAuthentication
} from '../../redux/Actions.js';
import axios from 'axios';
import { getPage } from '../../common/navigationTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderLoginCard = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const _authentication = useSelector((state) => authentication(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _device = useSelector((state) => device(state));
    const _features = useSelector((state) => features(state));
    const _properties = useSelector((state) => properties(state));
    const _services = useSelector((state) => services(state));
    const _userName = useSelector((state) => userName(state));

    const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false);
    const [loginWindowIsOpen, setLoginWindowIsOpen] = useState(false);

    // const isSsoFeature = () => false;
    // const isGuestModeFeature = () => false;
    const isSsoFeature = () => _features?.OAUTH2_AUTHENTICATION?.active;
    const isGuestModeFeature = () => _features?.GUEST_MODE?.active;

    const handleRegister = () => {
        navigate(getPage('register', _device));
    };

    const handleOpenLogin = () => {
        if (_features.OAUTH2_AUTHENTICATION && _features.OAUTH2_AUTHENTICATION.active) {
            //navigate(getPage('external-authentication', _device));
            setLoginWindowIsOpen(true);
        }
        else {
            setLoginDialogIsOpen(true);
        }
    };

    // ???? wohin
    const handleBookAsGuest = () => {
        axios({
            method: 'post',
            url: _services.login.href,
            data: JSON.stringify({ userName: 'GUEST', providerId: 'GUEST' }),
            'headers': {
                'content-type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                navigate(getPage('register', _device));
                dispatch(setAuthentication(response.data));
            }
        }, (err) => {
            console.log(err);
        }
        );
    };

    const oauthHandler = (event) => {
        switch (event.data) {
            case "oauthSuccess":
                dispatch(getAuthentication());
                setLoginWindowIsOpen(false);
                break;
            case "oauthCancelled":
                setLoginWindowIsOpen(false);
                break;
            case "oauthError":
                setLoginWindowIsOpen(false);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('message', oauthHandler);
        return (() => {
            window.removeEventListener('message', oauthHandler);
        });
    }, []);

    const handleCloseLogin = (data) => {
        if (_features.OAUTH2_AUTHENTICATION && _features.OAUTH2_AUTHENTICATION.active) {
            setLoginWindowIsOpen(false);
        }
        else {
            setLoginDialogIsOpen(false);
        }
    };

    const Desktop = () => {
        return (
            <>
                <Card sx={{
                    height: '100%'
                }} >
                    <AreaCardHeader title={t('order.loginCard.title')} />
                    {!_businessPartner && (
                        <Box sx={{ ml: 6, pt: 8, }}>
                            <Grid container
                                columnSpacing="2"
                                rowSpacing="16"
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                            >

                                <Grid item sm={12} lg={5}>
                                    <Typography sx={{ fontSize: 12 }}
                                        dangerouslySetInnerHTML={{ __html: t('order.loginCard.login') }}>
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} lg={7}>
                                    <Button variant="contained" onClick={handleOpenLogin} sx={{ minWidth: "120px", fontSize: 12 }}>
                                        {t('order.loginCard.doLogin')}
                                    </Button>
                                </Grid>
                                <Grid item sm={12}></Grid>
                                {!_features.GUEST_MODE.active && (<>
                                    <Grid item sm={12} lg={5}>
                                        <Typography sx={{ fontSize: 12 }}
                                            dangerouslySetInnerHTML={{ __html: t('order.loginCard.register') + ':' }}>
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} lg={7}>
                                        <Button variant="contained" onClick={handleRegister} sx={{ fontSize: 12 }}>
                                            {t('order.loginCard.doRegister')}
                                        </Button>
                                    </Grid>
                                </>)}
                                {_features.GUEST_MODE.active && (<>
                                    <Grid item sm={12} lg={5}>
                                        <Typography sx={{ fontSize: 12 }}
                                            dangerouslySetInnerHTML={{ __html: t('order.loginCard.registerOrGuest') + ':' }}>
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} lg={3}>
                                        <Button variant="contained" onClick={handleRegister} sx={{ minWidth: "120px", fontSize: 12 }}>
                                            {t('order.loginCard.doRegister')}
                                        </Button>
                                    </Grid>
                                    <Grid item sm={12} lg={4}>
                                        <Button variant="contained" onClick={handleBookAsGuest} sx={{ fontSize: 12 }}>
                                            {t('order.loginCard.asGuest')}
                                        </Button>
                                    </Grid>
                                </>)}

                            </Grid>
                        </Box>

                    )
                    }
                    {_businessPartner && (<>
                        <CardContent sx={{
                            padding: 0,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box sx={{ margin: 5 }}>
                                <Typography sx={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: t('order.loginCard.authenticated', { userName: _userName }) }} ></Typography>
                            </Box>
                        </CardContent>
                    </>
                    )
                    }
                </Card >
                {
                    loginDialogIsOpen && (
                        <ModalDialog open={loginDialogIsOpen} handleClose={handleCloseLogin} title={t('login.title')} />
                    )
                }
                {
                    loginWindowIsOpen && (
                        <ExternalAuthentication url={_services.ssoLogin.href.replace(window.location.href, '')} closeHandler={handleCloseLogin} properties={_properties} />
                    )
                }
            </>
        );

    };

    const LoginButtonMobile = () => {
        return <Button variant="navigationAction" onClick={handleOpenLogin}>{t('status.login')}</Button>
    }
    const RegisterButtonMobile = () => {
        return <Button color="secondary" variant="navigationAction" onClick={handleRegister}>{t('register.register')}</Button>
    }
    const GuestButtonMobile = () => {
        return <Button color="secondary" variant="navigationAction" onClick={handleBookAsGuest}>{t('order.loginCard.asGuest')}</Button>
    }

    const Mobile = () => {
        return (
            <Stack direction="column" spacing={5} sx={{ width: '100%' }}>
                <LoginButtonMobile />
                {isSsoFeature() && isGuestModeFeature() && (<GuestButtonMobile />)}
                {!isSsoFeature() && !isGuestModeFeature() && (<RegisterButtonMobile />)}
                {!isSsoFeature() && isGuestModeFeature() && (
                    <Stack direction="row" justifyContent="space-between">
                        <RegisterButtonMobile />
                        <GuestButtonMobile />
                    </Stack>)}
                {
                    loginDialogIsOpen && (
                        <ModalDialog open={loginDialogIsOpen} handleClose={handleCloseLogin} title={t('login.title')} />
                    )
                }
                {
                    loginWindowIsOpen && (
                        <ExternalAuthentication url={_services.ssoLogin.href.replace(window.location.href, '')} closeHandler={handleCloseLogin} properties={_properties} />
                    )
                }
            </Stack >
        );

    };

    if ('desktop' === _device) {
        return <Desktop></Desktop>
    }
    else if ('mobile' === _device) {
        return <Mobile></Mobile>
    }
    else {
        return <></>
    }

}