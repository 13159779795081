import React from 'react';

import i18n from 'i18next';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebounce } from '../hooks/Hooks';
import {
    allTemplates,
    customerType,
    editionPartsHierarchy,
    isWSS,
    orderToEdit,
    orderToRepeat,
    paymentStatus,
    pendingOrder,
    rawText,
    selectedEditionIds,
    selectedEditionPart,
    selectedMarket,
    selectedOffer,
    selectedTemplate,
    templates,
    features
} from '../redux/Selectors.js';
import {
    calculatePossibleDates,
    calculatePrice,
    fetchAdBoxNumberTypes,
    fetchEditionParts,
    fetchOfferCriterias,
    setAdBoxNumber,
    setAlternativeAddress,
    setDesiredDate,
    setEditionPart,
    setEditions,
    setMarket,
    setOffer,
    setOrderCatchword,
    setOrderIsCombi,
    setPaymentMethod,
    setSelectedDates,
    setTemplate,
    setTextFlows,
    resetPrice
} from '../redux/Actions.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderService = () => {

    const dispatch = useDispatch();
    const _allTemplates = useSelector((state) => allTemplates(state));
    const _customerType = useSelector((state) => customerType(state));
    const _editionPartsHierarchy = useSelector((state) => editionPartsHierarchy(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _orderToRepeat = useSelector((state) => orderToRepeat(state));
    const _paymentStatus = useSelector((state) => paymentStatus(state));
    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _rawText = useSelector((state) => rawText(state));
    const _selectedEditionIds = useSelector((state) => selectedEditionIds(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _templates = useSelector((state) => templates(state));
    const _features = useSelector((state) => features(state));

    /**
     * Hook to load template dependencies 
     */
    useEffect(() => {
        if (_selectedTemplate && _selectedMarket) {
            let defaultAdEdPartId = _selectedTemplate ? _selectedTemplate.defaultAdEdPartId : 0;
            let editionPartId = 0;
            if (_orderToRepeat) {
                editionPartId = _orderToRepeat.editionPartId;
            }
            dispatch(fetchEditionParts(_customerType, _selectedMarket, _selectedTemplate, _selectedOffer, _rawText, editionPartId === 0 ? defaultAdEdPartId : editionPartId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedTemplate, _selectedOffer, _selectedEditionIds, _selectedEditionPart, _rawText, i18n.language, _customerType, _selectedMarket]);

    useEffect(() => {
        if (!_selectedEditionPart) {
            // check for preselected edition part
            let preSelected = undefined;
            let edParts = _editionPartsHierarchy?.find(ed => ed.selected === true);
            while (edParts) {
                preSelected = edParts;
                let x = edParts.editionParts.find(ed => ed.selected === true);
                edParts = x;
            }
            //           console.log("preselected edition part: " + JSON.stringify(preSelected));
            dispatch(setEditionPart(preSelected?._id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_editionPartsHierarchy]);

    useEffect(() => {
        //       console.log("OrderService - useEffect: _selectedEditionPart?._id = " + _selectedEditionPart?._id + " / _editionPartsHierarchy = " + JSON.stringify(_editionPartsHierarchy));
        let isInHierarchy = false;
        if (_editionPartsHierarchy && _selectedEditionPart) {
            isInHierarchy = JSON.stringify(_editionPartsHierarchy).includes(_selectedEditionPart?._id.toString());
        }
        if (!isInHierarchy) {
            //           console.log("---> reset selectedEditionPart ...");
            dispatch(setEditionPart(undefined));
        }
        //        console.log("---> reset price ...");
        dispatch(resetPrice());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedTemplate, _selectedOffer, _editionPartsHierarchy])


    /**
     * Preisberechnung entprellt
     */
    const doPriceCalculation = useDebounce(() => {
        //        console.log("---> doPriceCalculation reset price ...");
        dispatch(resetPrice());
        //       console.log("---> doPriceCalculation calculatePrice " + JSON.stringify(_pendingOrder));
        dispatch(calculatePrice(_pendingOrder));
    }, 500);

    useEffect(() => {
        if (_pendingOrder) {
            doPriceCalculation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_pendingOrder]);

    useEffect(() => {
        dispatch(calculatePossibleDates(_selectedTemplate, _selectedOffer, _selectedEditionIds, _selectedEditionPart));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedTemplate, _selectedOffer, _selectedEditionIds, _selectedEditionPart]);

    useEffect(() => {
        if (_selectedOffer && !_selectedOffer.publicationCalendar) {
            dispatch(setSelectedDates());
        }
        var isCombi = _selectedOffer?.sapCombination && (_pendingOrder?.orderIsCombi || _selectedOffer?.sapDefault || _selectedOffer?.sapForce);
        dispatch(setOrderIsCombi(isCombi));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedOffer]);

    const doContinueOrRepeat = (order) => {

        if (order && _allTemplates) {

            //	Unterscheidung Entwurf/Wiederholung
            let marketId, offerId, editionPartId/*, orderCatchword, bankAccountId, useAlternativeIban, alternativeIban, alternativeBic*/;
            let alternativeAddress/*, useAlternativeAddress*/;
            let orderIsCombi, forOrderSelectedPaymentType;
            // if (order.orderState === 'draft') {
            marketId = order.marketId;
            offerId = order.offerId;
            if (order.editionPartId !== 0) {
                editionPartId = order.editionPartId;
            } else if (order.scheduleCompositions) {
                editionPartId = order.scheduleCompositions[0].editionPart._id;
            }
            console.log("editionPartId = " + editionPartId);
            //       orderCatchword = order.orderCatchword;
            if (order.bankAccountId && order.bankAccountId != null) {
                //             bankAccountId = order.bankAccountId;
                //             useAlternativeIban = false;
            } else {
                //              alternativeIban = order.iban;
                //              alternativeBic = order.swift;
                //              useAlternativeIban = true;
            }

            if (order.alternativeAddress) {
                alternativeAddress = order.alternativeAddress;
            }
            //           useAlternativeAddress = order.useAlternativeAddress;
            orderIsCombi = order.orderIsCombi;
            forOrderSelectedPaymentType = order.paymentMethod;

            dispatch(setOrderCatchword(order.orderCatchword));
            /*      dispatch(setBankAccountId(bankAccountId));
                  dispatch(setUseAlternativeIban(useAlternativeIban));
                  dispatch(setAlternativeIban(alternativeIban));
                  dispatch(setAlternativeBic(alternativeBic));*/
            dispatch(setAlternativeAddress(alternativeAddress));
            /*      dispatch(setUseAlternativeAddress(useAlternativeAddress)); */
            dispatch(setOrderIsCombi(orderIsCombi));
            /*        dispatch(setForOrderSelectedPaymentType(forOrderSelectedPaymentType)); */
            dispatch(setPaymentMethod(forOrderSelectedPaymentType));

            // Rubrik/editionPart im edition-part-hier setzen
            dispatch(setEditionPart(editionPartId));


            // only draft
            if (_features.ASE_1992.active || _isWSS) {
                if (order.orderState === 'draft' || order.orderState === 'repeat') {
                    if (order.effectiveDates && order.effectiveDates.length) {
                        dispatch(setDesiredDate(order.effectiveDates[0]));
                    } else if (order.startDate) {
                        dispatch(setDesiredDate(order.startDate));
                    }
                }
            }

            // only agency
            /*           if (order.advertizerNumber) {
                           let advertizerNumber;
                           advertizerNumber = order.advertizerNumber;
                           dispatch(setAdvertizer(advertizerNumber));
                       } */

            // check if the template of the origin is available, but not in payment-link-processing mode
            if (!_paymentStatus && order.templateId) {
                var t = _allTemplates.find(t => t._id === order.templateId);
                if (t) {
                    dispatch(setTextFlows());
                    dispatch(setOffer(offerId));
                    dispatch(setMarket(marketId));
                    dispatch(setTemplate(order.templateId));

                    let adBoxNumber;
                    adBoxNumber = order.adBoxNumber;
                    if (adBoxNumber) {
                        dispatch(setAdBoxNumber(adBoxNumber));
                        dispatch(fetchAdBoxNumberTypes(order.templateId));
                    }

                    // selectedEditionIds
                    let selectedEditions = [];
                    if (order.scheduleCompositions) {
                        order.scheduleCompositions.forEach(sc => {
                            if (!selectedEditions.includes(sc.edition._id)) {
                                selectedEditions.push(sc.edition._id);
                            }
                        });
                        dispatch(setEditions(JSON.parse(JSON.stringify(selectedEditions))));
                    }
                } else {
                    /*                   this.$.notAvailable.open(); */
                    console.error(_templates);
                    console.error(order.templateId);
                }
            }
        }
    }

    useEffect(() => {
        if (_orderToRepeat) {
            if (_orderToRepeat?.orderState !== "draft")
                _orderToRepeat.orderState = "repeat";
            doContinueOrRepeat(_orderToRepeat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderToRepeat])

    useEffect(() => {
        if (_orderToEdit) {
            doContinueOrRepeat(_orderToEdit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderToEdit])

    useEffect(() => {
        if (_selectedOffer?.criteriaValues?.findIndex(cv => cv.description === 'ordernopatterncriteria') > -1) {
            dispatch(fetchOfferCriterias());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_selectedOffer]);

    return (<></>);
}
