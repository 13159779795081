
export const TrackingReducer = (state, action) => {

	if (!state) {
		return {};
	}

	let t = { value: 1, fieldsObject: { /* transport: 'beacon' */ } };
	switch (action.type) {
		case 'SET_TRACKER':
			return Object.assign({}, state, {
				tracker: action.value,
				event: null
			});
		case 'SET_TRACKING_ACCEPTED': {
			let tracker = state.tracker || {};
			tracker.trackingAccepted = action.value;
			return Object.assign({}, state, {
				tracker: tracker,
				event: null
			});
		}
		case 'TRACK_EVENT':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: action.value.category,
					action: action.value.action,
					label: action.value.label
				})
			});
		case 'SET_TEMPLATE':
			if (action.tracking) {
				return Object.assign({}, state, {
					event: Object.assign(t, {
						category: 'Creative',
						action: 'select template',
						label: action.type
					})
				});
			}
			break;
		case 'SET_MARKET':
			if (action.tracking) {
				return Object.assign({}, state, {
					event: Object.assign(t, {
						category: 'MasterData',
						action: 'select market',
						label: action.type
					})
				});
			}
			break;
		case 'SET_OFFER':
			if (action.tracking) {
				return Object.assign({}, state, {
					event: Object.assign(t, {
						category: 'MasterData',
						action: 'select offer',
						label: action.type
					})
				});
			}
			break;
		case 'SET_EDITION_PART':
			if (action.tracking) {
				return Object.assign({}, state, {
					event: Object.assign(t, {
						category: 'MasterData',
						action: 'select editionPart',
						label: action.type
					})
				});
			}
			break;
		case 'SET_PAYMENT_METHOD':
			//					if (action.tracking) {
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'Order',
					action: 'select paymentMethod',
					label: action.value
				})
			});
			//					}
			break;
		case 'SET_PAGE':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'Page',
					action: 'change page',
					label: action.value
				})
			});
			break;
		case 'BUY_SUCCESS':
		case 'BUY_FAILURE':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'Order',
					action: 'response',
					label: action.type
				})
			});
			break;
		case 'LOGIN_REQUEST':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'BusinessPartner',
					action: 'login',
					label: action.type
				})
			});
			break;
		case 'LOGOUT_REQUEST':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'BusinessPartner',
					action: 'logout',
					label: action.type
				})
			});
			break;
		case 'REPEAT_ORDER_REQUEST':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'BusinessPartner',
					action: 'repeat order',
					label: action.type
				})
			});
			break;
		case 'CANCEL_ORDER_REQUEST':
		case 'CANCEL_ORDER_FAILURE':
			return Object.assign({}, state, {
				event: Object.assign(t, {
					category: 'BusinessPartner',
					action: 'cancel order',
					label: action.type
				})
			});
			break;
		default:
			break;
	}
	return state;
};
