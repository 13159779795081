import React from 'react';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { appTheme } from '../../AppTheme';
import { passwordReminder } from '../../redux/Actions.js';
import { properties, passwordReminderResponse } from '../../redux/Selectors.js';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Alert,
    Stack,
    TextField,
    Typography
} from '@mui/material';

export const PasswordReminder = ({ userName, handleDialogClose, dialogOpen }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const _properties = useSelector((state) => properties(state));
    const _passwordReminderResponse = useSelector((state) => passwordReminderResponse(state));

    const [valueUserName, setValueUserName] = useState(userName);
    const [errorMsgOpen, setErrorMsgOpen] = useState(false);
    const [successMsgOpen, setSuccessMsgOpen] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const theme = createTheme(appTheme(_properties));

    useEffect(() => {
        setValueUserName(userName);
    }, [userName])

    useEffect(() => {
        console.log(_passwordReminderResponse);
        if (_passwordReminderResponse.success) {
            setSuccessMsgOpen(true);
        } else if (_passwordReminderResponse.errors) {
            let msg = '';
            _passwordReminderResponse.errors.forEach(function (e) {
                console.log("ERROR: " + e.text);
                msg += e.text + "<br />";
            });
            setErrMsg(msg);
            setErrorMsgOpen(true);
        }
    }, [_passwordReminderResponse]);

    useEffect(() => {
        if (dialogOpen === false) {
            setErrorMsgOpen(false);
            setSuccessMsgOpen(false);
        }
    }, [dialogOpen])

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(passwordReminder(valueUserName));
    };

    const ErrorResponseDialog = () => {
        return (<Dialog
            open={errorMsgOpen}
            onClose={handleErrMsgClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Alert severity="warning">
            </Alert>
            <DialogContent dangerouslySetInnerHTML={{ __html: errMsg }}>

            </DialogContent>
            <DialogActions>
                <Button sx={{ textTransform: 'none' }} variant="navigationAction" onClick={handleErrMsgClose}>Ok</Button>
            </DialogActions>
        </Dialog>)
    };

    const handleErrMsgClose = () => {
        setErrMsg(false);
        setErrorMsgOpen(false);
    }

    const SuccessResponseDialog = () => {
        return (<Dialog
            open={successMsgOpen}
            onClose={handleSuccessMsgClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Alert severity="warning">
            </Alert>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('login.reminderSent')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ textTransform: 'none' }} variant="navigationAction" onClick={handleSuccessMsgClose}>Ok</Button>
            </DialogActions>
        </Dialog>)
    };

    const handleSuccessMsgClose = () => {
        setSuccessMsgOpen(false);
        handleDialogClose();
    }

    const styleForm = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        width: 300,

        '& .MuiTextField-root': {
            margin: 1,
            width: 300,
            m: 3
        },
        '& .MuiButtonBase-root': {
            margin: 1,
            width: 300,
            m: 3
        },
        '& .MuiBox-root': {
            width: 300
        }
    }

    const styleButtons = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: 300
    }

    return (
        <Box sx={styleForm}>
            <ThemeProvider theme={theme}>
                <Dialog
                    maxWidth="md"
                    open={dialogOpen}
                    onClose={handleDialogClose}
                >
                    <DialogTitle>{t('customerdata.account.password.title')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                sx={{ width: '300px', pb: 15 }}
                                variant="filled"
                                // inputProps={{ readOnly: true }}
                                label={t('customerdata.account.userName')}
                                type="text"
                                value={valueUserName}
                                onChange={e => setValueUserName(e.target.value)}
                            />
                            <Box sx={styleButtons}>
                                <Button type="submit" variant="contained">
                                    {t('customerdata.account.password.request')}
                                </Button>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            </ThemeProvider>
            <ErrorResponseDialog />
            <SuccessResponseDialog />
        </Box >
    );
};

export default PasswordReminder;