
/**
 * 
 * @param {*} props 
 * @returns 
 * 
 *  layout	left		middle		right
 *          display     alternatives  offers
 *  ----------------------------------------------
 *	1		inactive	inactive    active
 *	2		inactive	active      inactive
 *	3		active		inactive    inactive
 *	4		active      active      inactive
 *  5
 *  6       active        -         active
 */
export const CreativeToolAreaLayouts = (layout, props) => {

    const l1 = {
        toolsActive: {
            display: 'none'
        },
        toolsInactive: {
            width: '16.5%',
            height: '100%',
            pl: 1
        },
        alternativesActive: {
            display: 'none'
        },
        alternativesInactive: {
            width: '16.5%',
            height: '100%'
        },
        offersActive: {
            width: '66%',
            pr: 0
        },
        offersInactive: {
            display: 'none'
        },
        orderToEditMsg: {
            display: 'none'
        }
    };
    const l2 = {
        toolsActive: {
            display: 'none'
        },
        toolsInactive: {
            width: '16.5%',
            height: '100%'
        },
        alternativesActive: {
            width: '58.5%',
            height: '100%'
        },
        alternativesInactive: {
            display: 'none',
        },
        offersActive: {
            display: 'none',
        },
        offersInactive: {
            width: '25%',
            pr: 0
        },
        orderToEditMsg: {
            display: 'none'
        }
    };
    const l3 = {
        toolsActive: {
            width: '25%',
            height: '100%'
        },
        toolsInactive: {
            display: 'none'
        },
        alternativesActive: {
            display: 'none'
        },
        alternativesInactive: {
            width: '9%',
        },
        offersActive: {
            width: '66%',
            pr: 0
        },
        offersInactive: {
            display: 'none'
        },
        orderToEditMsg: {
            display: 'none'
        }
    };
    const l4 = {
        toolsActive: {
            width: '25%',
            height: '100%',
            pl: 2
        },
        toolsInactive: {
            display: 'none'
        },
        alternativesActive: {
            width: '50%',
        },
        alternativesInactive: {
            display: 'none'
        },
        offersActive: {
            display: 'none',
        },
        offersInactive: {
            width: '25%',
            pr: 0
        },
        orderToEditMsg: {
            display: 'none'
        }
    };
    const l5 = {
        toolsActive: {
            width: '25%',
            height: '100%',
            pl: 2
        },
        toolsInactive: {
            display: 'none'
        },
        alternativesActive: {
            display: 'none',
        },
        alternativesInactive: {
            display: 'none'
        },
        offersActive: {
            display: 'none',
        },
        offersInactive: {
            width: '25%',
            pr: 0
        },
        orderToEditMsg: {
            width: '50%'
        }
    };
    const l6 = {
        toolsActive: {
            width: '33.3%',
            pl: 2
        },
        toolsInactive: {
            display: 'none',
        },
        alternativesActive: {
            display: 'none'
        },
        alternativesInactive: {
            display: 'none'
        },
        offersActive: {
            width: '66.7%'
        },
        offersInactive: {
            display: 'none'
        },
        orderToEditMsg: {
            display: 'none'
        }
    };
    const l7 = {
        toolsActive: {
            display: 'none'
        },
        toolsInactive: {
            display: 'none'
        },
        alternativesActive: {
            width: '75%',
            ml: '-4px'
        },
        alternativesInactive: {
            display: 'none'
        },
        offersActive: {
            display: 'none',
        },
        offersInactive: {
            width: '25%',
            pr: 0
        },
        orderToEditMsg: {
            display: 'none'
        }
    }; switch (layout) {
        case 1:
            return l1;
        case 2:
            return l2;
        case 3:
            return l3;
        case 4:
            return l4;
        case 5:
            return l5;
        case 6:
            return l6;
        case 7:
            return l7;
        default:
            return l1;
    }
}

