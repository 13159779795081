import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Button, Grid, Snackbar, Alert } from '@mui/material';
import {
    selectedOffer, orderPriceResponse, editionDisplayNames, selectedEditionPart, desiredDate, tenant,
    isCicAuthenticated, businessPartner, ordercriterias, features, properties, offers, voucher
} from '../redux/Selectors.js';
import {
    resetPrice
} from '../redux/Actions.js';
import AppHeader from '../components/AppHeader';
import AppFooter from '../components/AppFooter';
import InfoText from '../components/InfoText';
import MsgPopper from '../components/common/MsgPopper';
import { OrderPreviewCard } from '../components/order/OrderPreviewCard.jsx';
import { EditionPartCard } from '../components/order/EditionPartCard.jsx';
import { CalendarCard } from '../components/order/CalendarCard.jsx';
import { DataCard } from '../components/order/DataCard.jsx';
import { AdditionalDataCard } from '../components/order/AdditionalDataCard.jsx';
import { PriceCard } from '../components/common/PriceCard.jsx';
import { getPage } from '../common/navigationTools.js';

/**
 * @returns 
 */
export const Schedule = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _desiredDate = useSelector((state) => desiredDate(state));
    //   const _tenant = useSelector((state) => tenant(state));
    const _features = useSelector((state) => features(state));
    const _isCicAuthenticated = useSelector((state) => isCicAuthenticated(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _ordercriterias = useSelector((state) => ordercriterias(state));
    const _offers = useSelector((state) => offers(state));
    const _properties = useSelector((state) => properties(state));
    const _voucher = useSelector((state) => voucher(state));

    const navigate = useNavigate();
    // const [height, setHeight] = useState(window.innerHeight - 500);

    const smallWidth = 900;

    const [upperHeight, setUpperHeight] = useState(((window.innerHeight - 195) / 3 * 2) - 5);
    const [lowerHeight, setLowerHeight] = useState(((window.innerHeight - 195) / 3) - 5);
    const [width, setWidth] = useState(window.innerWidth);
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [msgAnchorRef, setMsgAnchorRef] = useState(null);
    const [hasAdditionalData, setHasAdditionalData] = useState(true);
    const [complete, setComplete] = useState(false);
    const [isOrderVoucherEnabled, setIsOrderVoucherEnabled] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const refEditionPartCard = useRef(null);
    const refAddCard = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        setHasAdditionalData((_ordercriterias?.length > 0 && _selectedOffer?.criteriaValues?.length > 0 &&
            _selectedOffer?.criteriaValues?.find(c => c.description === 'orderwithpatterncriteria' || c.description === 'ordernopatterncriteria'))
            || isOrderVoucherEnabled || _businessPartner?.servicePackages?.length > 0 ||
            _selectedOffer?.sapCombination || _features?.ASE_1017?.status === "on" || _isCicAuthenticated);
    }, [_selectedOffer, _ordercriterias, _businessPartner, _features, _isCicAuthenticated, isOrderVoucherEnabled]);

    useEffect(() => {
        if (_selectedOffer && _selectedEditionPart && _selectedEditionPart.bookable === true && _desiredDate && _orderPriceResponse?.value?.effectiveDates?.length > 0) {
            setComplete(true);
        } else {
            setComplete(false);
        }
    }, [_selectedOffer, _selectedEditionPart, _desiredDate, _orderPriceResponse]);

    useEffect(() => {
        if (_orderPriceResponse) {
            if (_orderPriceResponse.success) {
            }
            else if (_orderPriceResponse.exception) {
                setSnackbarMsg(t('main.commonFailure'));
                setSnackbarOpen(true);
                setSnackbarSeverity('error');
            }
            else {
                // ASE-395: Einloesen von Gutschein, etc
                //   let priceMessage = t('main.commonFailure') + '<br/>';
                /*               let priceMessage = '';
                               if (_orderPriceResponse.errors) {
                                   priceMessage += _orderPriceResponse.errors.map(m => m.text)[0];
                               }
                               setSnackbarMsg(priceMessage);
                               setSnackbarOpen(true);
                               setSnackbarSeverity('error');*/
                refAddCard.current.setVoucherFieldEnabled(true);
            }
        }
    }, [_orderPriceResponse]);


    useEffect(() => {
        setIsOrderVoucherEnabled(Boolean(_selectedOffer?.orderVoucher));
    }, [_selectedOffer]);

    const listenResize = () => {
        setUpperHeight(((window.innerHeight - 195) / 3 * 2) - 5);
        setLowerHeight(((window.innerHeight - 195) / 3) - 5);
        setWidth(window.innerWidth);
    };

    const getEditionPartCardStyle = () => {
        return {
            height: upperHeight,
            width: width < smallWidth ? "100%" : '35%'
        }
    };

    const getCalendarCardStyle = () => {
        return {
            height: upperHeight,
            width: width < smallWidth ? "100%" : (hasAdditionalData ? '30%' : '70%')
        }
    };

    const getAdditionalDataCardStyle = () => {
        return {
            height: upperHeight,
            width: width < smallWidth ? "100%" : '35%',
            display: (hasAdditionalData ? 'block' : 'none')
        }
    };

    const getPreviewCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : '25%'
        }
    };

    const getDataCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : '50%'
        }
    };

    const getPriceCardStyle = () => {
        return {
            height: lowerHeight,
            width: width < smallWidth ? "100%" : '25%'
        }
    };

    const buttonStyle = () => {
        return {
            /* marginRight: "1em",
             marginLeft: "1em",
             marginBottom: '1em',*/
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2
        }
    };

    const getPrevPage = () => {
        if (_offers.length > 1 /*&& _properties.displayOfferPage*/) {
            return (getPage('offers', 'desktop'));
        } else {
            return (getPage('creative', 'desktop'));
        }
    }

    const back = () => {
        //       navigate('/webstore/app/creative');
        refEditionPartCard.current.saveData();
        navigate(getPrevPage());

    };

    const next = () => {
        refEditionPartCard.current.saveData();
        navigate(getPage('order', 'desktop'));
    };

    const closeSnackbarHandler = () => {
        console.log("closeSnackbarHandler ...");
        setSnackbarOpen(false);
        setSnackbarMsg('');
        dispatch(resetPrice());
    }

    return (
        <>
            <Stack direction="column" spacing={5}>
                <AppHeader></AppHeader>
                <Box>
                    <Box sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }} elevation={4} >
                        <Grid container direction="row" rowSpacing={4} justifyContent="space-between">
                            <Grid item xs={12}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, pr: 0, height: width < smallWidth ? "100%" : upperHeight, mr: 0, ml: 0 }}>
                                    <Stack
                                        direction={width < smallWidth ? 'column' : 'row'}
                                        spacing={4}
                                        sx={{ width: '100%', height: '100%' }}>

                                        <Box sx={getEditionPartCardStyle()} >
                                            <EditionPartCard
                                                ref={refEditionPartCard}
                                                variant='xlarge'
                                                direction="vertical"
                                                height={upperHeight}
                                                doNotDisplayPrice="true"
                                                displayCurrentOffer="true"
                                                withNavigation="true"
                                            />
                                        </Box>
                                        <Box sx={getCalendarCardStyle()} >
                                            <CalendarCard height={upperHeight} width={hasAdditionalData ? width / 4 : width / 2} orientation={hasAdditionalData ? 'portrait' : 'landscape'} /*monthsShown={hasAdditionalData ? 1 : 2 } onClose={() => setLayout(1)}*/ />
                                        </Box>
                                        <Box sx={getAdditionalDataCardStyle()} >
                                            <AdditionalDataCard displayPriceText={true} height={upperHeight} ref={refAddCard} />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, pr: 0, height: width < smallWidth ? "100%" : lowerHeight, mr: 0, ml: 0 }}>
                                    <Stack
                                        direction={width < smallWidth ? 'column' : 'row'}
                                        spacing={4}
                                        sx={{ width: '100%', height: '100%', justifyContent: 'stretch' }}>
                                        <Box sx={getPreviewCardStyle()}>
                                            <OrderPreviewCard height={lowerHeight} width={width < smallWidth ? "100%" : width / 5} />
                                        </Box>
                                        <Box sx={getDataCardStyle()}>
                                            <DataCard />
                                        </Box>
                                        <Box sx={getPriceCardStyle()} >
                                            <PriceCard displayPriceText={true} />
                                        </Box>


                                    </Stack>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>

                                <Box width="100%" sx={buttonStyle()}>
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                        sx={{ width: '100%', justifyContent: 'stretch' }}
                                    >
                                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'stretch' }}>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box >
                <AppFooter
                    action1={<Button variant="navigationBack" onClick={() => { back(); }} >{t('offer.back')}</Button>}
                    action2={<Button variant="navigationAction" disabled={!complete} onClick={() => { next(); }} >{t('offer.next')}</Button>}
                >

                </AppFooter>
                <InfoText />
            </Stack >

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
            >
                <Alert severity={snackbarSeverity}>{snackbarMsg}</Alert>
            </Snackbar>

            <MsgPopper
                open={msgOpen}
                arrow={true}
                anchorRef={msgAnchorRef}
                placement='left'
                text={msgText}
            >
            </MsgPopper>
        </>
    );
}

export default Schedule;
