

const createUUID = () => {
	var dt = new Date().getTime();
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
	});
	return uuid;
};

const initialState = () => {

	let d = new Date();
	d.setDate(d.getDate() + 1);
	let tomorrow = d.toJSON().substring(0, 10);

	return {
		criterionValues: [],
		UUID: createUUID(),
		desiredDate: tomorrow,
		termsAndConditionsConfirmed: false
	};

};

export const OrderReducer = (state, action) => {
	if (!state) {
		return initialState();
	}
	let newState = state;
	switch (action.type) {
		// case 'SET_MOTIV':
		// console.log('SET_MOTIV', action.value);
		// newState = Object.assign({}, state, { motif: action.value });
		// break;
		// case 'SET_MOTIF_CRITERION_VALUES':
		// newState = Object.assign({}, state, { motifCriterionValues:
		// action.value });
		// break;

		// case 'SET_CUSTOMER_TYPE':
		// newState = Object.assign({}, state, {
		// legalEntityType: action.value.toUpperCase()
		// });
		// break;
		// case 'SET_PROPERTIES':
		// const customerType =
		// action.value.legalEntityTypes.split(',')[0];
		// if (customerType) {
		// newState = Object.assign({}, state, {
		// legalEntityType: customerType.toUpperCase()
		// });
		// }
		// break;

		case 'SET_DESIRED_DATE': {
			const date = action.value;
			if (date) {
				newState = Object.assign({}, state, { desiredDate: date });
				// const d = new Date(date.substring(0, 4),
				// Number(date.substring(5, 7)) - 1, date.substring(8,
				// 10));
				// const f = (v, n) => ("0000" + String(v)).slice(-n);
				// const x = f(d.getUTCFullYear(), 4) + "-" +
				// f(d.getUTCMonth() + 1, 2) + "-" + f(d.getUTCDate(),
				// 2) + "T00:00:00.000Z";
				// newState = Object.assign({}, state, { desiredDate: x
				// });
			}
			break;
		}
		case 'SET_SELECTED_DATES':
			newState = Object.assign({}, state, { productionDates: action.value });
			break;
		case 'SET_VOUCHER':
			newState = Object.assign({}, state, { voucher: action.value });
			break;
		case 'SET_AD_BOX_NUMBER':
			newState = Object.assign({}, state, { adBoxNumber: action.value });
			break;
		case 'GET_AD_BOX_NUMBER_SUCCESS':
			newState = Object.assign({}, state, { adBoxNumber: action.json });
			break;
		case 'SET_AUCTION_CREDIT':
			newState = Object.assign({}, state, { auctionCredit: Boolean(action.value) });
			break;
		case 'SET_PAYMENT_METHOD':
			newState = Object.assign({}, state, { paymentMethod: action.value });
			break;
		case 'SET_SERVICE_PACKAGE_ID':
			newState = Object.assign({}, state, { servicePackageId: action.value });
			break;
		case 'ADD_CRITERION': {
			const criterionValues = state.criterionValues.slice();
			const criterionValue = { shortName: action.key, value: action.value };
			const filtered = criterionValues.filter(e => e.shortName === criterionValue.shortName);
			if (filtered.length === 0) {
				criterionValues.push(criterionValue);
			}
			else {
				criterionValues.forEach(ce => {
					if (ce.shortName === criterionValue.shortName) {
						ce.value = action.value;
					}
				});
			}
			newState = Object.assign({}, state, { criterionValues: criterionValues });
			break;
		}
		case 'SET_CRITERION_WITH_PATTERN':
			newState = Object.assign({}, state, { orderCriterionWithPattern: action.value });
			break;
		case 'SET_BANK_ACCOUNT':
			newState = Object.assign({}, state, { bankAccountId: action.bankAccount._id });
			break;
		case 'SET_BANK_ACCOUNT_ID':
			newState = Object.assign({}, state, { bankAccountId: action.value });
			break;
		case 'RESET_ORDER': {
			newState = initialState();
			break;
		}
		case 'SET_ADVERTIZER':
			newState = Object.assign({}, state, { advertizer: action.businessPartnerNo });
			break;
		case 'SET_ORDER_CATCHWORD':
			newState = Object.assign({}, state, { orderCatchword: action.value });
			break;
		case 'SET_ORDER_COMMENT':
			newState = Object.assign({}, state, { orderComment: action.value });
			break;
		case 'SET_ALTERNATIVE_ADDRESS':
			newState = Object.assign({}, state, { alternativeAddress: action.value });
			break;
		case 'SET_HAS_ALTERNATIVE_ADDRESS':
			newState = Object.assign({}, state, { useAlternativeAddress: action.value });
			break;
		case 'SET_ORDER_TYPE':
			newState = Object.assign({}, state, { orderType: action.value });
			break;
		case 'SET_PRICE_CALCULATION':
			newState = Object.assign({}, state, { priceCalculation: action.value });
			break;
		case 'SET_ORDER_FIX_PRICE':
			newState = Object.assign({}, state, { orderFixPrice: action.value });
			break;
		case 'SET_ORDER_IS_COMBI':
			newState = Object.assign({}, state, { orderIsCombi: action.value });
			break;
		case 'SET_ORDER_CONFIRMATION':
			newState = Object.assign({}, state, { orderConfirmation: action.value });
			break;
		case 'SET_ORDER_CODE_TO_REPLACE':
			newState = Object.assign({}, state, { orderCodeToReplace: action.value });
			break;
		case 'SET_USE_ALTERNATIVE_IBAN':
			newState = Object.assign({}, state, { useAlternativeIban: action.value });
			break;
		case 'SET_ALTERNATIVE_IBAN':
			newState = Object.assign({}, state, { iban: action.value });
			break;
		case 'SET_ALTERNATIVE_BIC':
			newState = Object.assign({}, state, { swift: action.value });
			break;
		case 'SET_SELECTED_EDITION_IDS':
			newState = Object.assign({}, state, { selectedEditionIds: action.value });
			break;
		case 'CONFIRM_TERMS_AND_CONDITIONS':
			newState = Object.assign({}, state, { termsAndConditionsConfirmed: action.value });
			break;
		default:
			return state;
	}
	return newState;
}
