import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import { Label, DataBold } from '../common/Commons';

import { editions, selectedEditionIds, selectedOffer, editionDisplayNames } from '../../redux/Selectors.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OfferInfoCard = ({ height }) => {
    const { t } = useTranslation();

    const _editions = useSelector((state) => editions(state));
    const _editionDisplayNames = useSelector((state) => editionDisplayNames(state));
    const _selectedEditionIds = useSelector((state) => selectedEditionIds(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));

    const editionText = (ids, eds) => {
        const edition = eds.find(e => ids[0] === e._id);
        return edition?.htmlText || edition?.description;
    };

    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('offer.infoTitle')} />
            <CardContent sx={{ pt: 4, height: height - 40 }}>
                <Box
                    sx={{
                        mb: 2,
                        height: '100%'
                    }}
                >
                    <Grid container width='100%' spacing='10'>
                        <Grid item xs={4} >
                            <Label text='main.offer' />
                        </Grid>
                        <Grid item xs={8}>
                            <DataBold text={_selectedOffer.name} />
                        </Grid>
                        <Grid item xs={4} >
                            <Label text='order.editions' />
                        </Grid>
                        <Grid item xs={8}>
                            <DataBold text={_editionDisplayNames} />
                            {_selectedEditionIds.length === 1 && (
                                <Typography
                                    sx={{ fontSize: 14, mt: '0.5rem' }}
                                    dangerouslySetInnerHTML={{ __html: editionText(_selectedEditionIds, _editions) }} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </CardContent >
        </Card >
    );

}