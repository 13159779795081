
import React from 'react';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { orderToEdit } from '../../redux/Selectors.js';

import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Collapse, Stack } from '@mui/material';

import AreaCardHeader from '../common/AreaCardHeader';
import { CreativeConfigArea } from './CreativeConfigArea.jsx';
import { PriceArea } from '../common/PriceArea.jsx';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CreativeConfigCard = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const [msgOpen, setMsgOpen] = useState(false);

    const [expanded, setExpanded] = useState(true);

    useImperativeHandle(ref, () => ({ setExpanded }));

    /*
     * AdbBox
     */
    useEffect(() => {
        setMsgOpen(props.msgOpen);
    }, [props.msgOpen]);

    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('creative.configCardTitle')} fullCard={expanded} handleExpand={props.handleExpand} />
            <CardContent>
                <CreativeConfigArea msgOpen={msgOpen} disabled={_orderToEdit ? true : false} />
            </CardContent>

        </Card >
    );

});

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CreativeConfigPriceCard = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const [msgOpen, setMsgOpen] = useState(false);
    const [expanded, setExpanded] = useState(true);

    console.log("CreativeConfigPriceCard expanded = " + expanded);
    console.log("displayOnlyPrice = " + props.displayOnlyPrice);

    useImperativeHandle(ref, () => ({ setExpanded }));

    /*
     * AdbBox
     */
    useEffect(() => {
        setMsgOpen(props.msgOpen);
    }, [props.msgOpen]);

    return (
        <Card
            variant='outlined'
        >
            <AreaCardHeader
                sx={{ height: 38 }}
                titleTypographyProps={{ variant: 'areaCardHeader' }}
                title={t('creative.optionCardTitle')}
                handleExpand={props.handleExpand}
                fullCard={expanded}>
            </AreaCardHeader>
            <CardContent
                sx={{ height: props.height, display: expanded ? 'block' : 'none', pb: 0 }}>
                <Collapse in={expanded}
                    orientation='vertical'>
                    <Stack
                        direction={props.direction ? props.direction : 'column'}
                    >
                        <Box sx={props.creativeStyle}>
                            <CreativeConfigArea msgOpen={msgOpen} />
                        </Box>
                        <PriceArea noDistanceBox={props.displayOnlyPrice} displayOffer={false} displayMoreOfferText={false} displayMorePriceText={true} />
                    </Stack>
                </Collapse>
            </CardContent>
        </Card>
    );

});