import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    Menu,
    MenuItem,
} from '@mui/material';

import {
    readOrder,
    repeatOrder,
    setTemplate,
    setAdBoxNumber,
    releaseDraft,
    deleteDraft,
    setCancelUrl,
    editOrder
} from '../../redux/Actions.js';

import {
    isWSS,
    selectedTemplate,
    components,
    features,
    services
} from '../../redux/Selectors.js';
import { DEVICE_DESKTOP, getPage } from '../../common/navigationTools.js';
import { CancelIcon, OrderEditIcon, PdfIcon, RedoIcon, ReleaseIcon } from '../../common/widgetTools.js';
import { ConfirmationDialog } from '../ModalDialog.js';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const DraftActionsMenu = ({ menuAnchorEl, isMenuOpen, handleMenuClose, selectedOrder }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [orderState, setOrderState] = useState('new');
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [action, setAction] = useState('');

    //    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _components = useSelector((state) => components(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _features = useSelector((state) => features(state));
    const _services = useSelector((state) => services(state));

    const handleMenuClick = (value) => {
        console.log('##### handleMenuClick', value);

        console.log("setTemplate()");
        dispatch(setTemplate());
        dispatch(setAdBoxNumber());

        if (value === 'repeat') {
            dispatch(repeatOrder(selectedOrder.orderCode));
            setOrderState(value);
        } else if (value === 'orderEditable') {
            let url = _services.deleteDraft.href.replace('{id}', selectedOrder._id);
            console.log("cancel with url " + selectedOrder._links.cancel.href);
            dispatch(setCancelUrl(url));
            dispatch(editOrder(selectedOrder.orderCode));
            setOrderState(value);
        } else if (value === 'release') {
            dispatch(releaseDraft(selectedOrder, selectedOrder._id));
        } else if (value === 'cancel') {
            setMsg(t('order.draft.deleteConfirmation'));
            setConfirmationDialogOpen(true);
        } else {
            alert(value);
        }
        setAction(value);
    }

    useEffect(() => {
        console.log('DraftActionsMenu - useEffect ');
        console.log(_selectedTemplate);
        console.log(_components);
        console.log(orderState);
        if ((orderState === 'repeat' || orderState === 'orderEditable') && _selectedTemplate && _components) {
            navigate(getPage('creative', DEVICE_DESKTOP));
            handleMenuClose();
        }
    }, [_selectedTemplate, _components]);

    const isPdfAvailable = () => {
        return _isWSS;
    };

    const isRepeatable = order => {
        return Boolean(order && order.onlineOrder && _features.ASE_1992.active && !isBeforeDeadline(order));
    };

    const isCancelable = order => {
        return Boolean(order && order._links.cancel && isBeforeDeadline(order));
    };

    const isEditable = order => {
        return Boolean(order && _features.ASE_1992.active && isBeforeDeadline(order));
    };

    const isReleaseable = order => {
        return Boolean(order && _features.ASE_1992.active && isBeforeDeadline(order));
    }

    const isBeforeDeadline = (order) => {
        console.log("beforeDeadline order.bookingDeadline = " + order.bookingDeadline);
        let now = Date.now();
        let deadline = Date.parse(order.bookingDeadline);
        console.log("now = " + now);
        console.log("deadline = " + deadline);
        return now < deadline;
    }

    const okAction = (e) => {
        console.log("Do action " + action);
        if (action === 'cancel') {
            let url = _services.deleteDraft.href.replace('{id}', selectedOrder._id);
            console.log("cancel with url " + selectedOrder._links.cancel.href);
            dispatch(deleteDraft(url));
            setMsg('');
        }
        setConfirmationDialogOpen(false);
    }

    const cancelAction = (e) => {
        setMsg('');
        setConfirmationDialogOpen(false);
    }

    return (
        <>
            <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={'menuId'}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >

                {isPdfAvailable() && (
                    <MenuItem>
                        <Button
                            onClick={() => { handleMenuClick('motifDownload'); handleMenuClose() }}
                            color="inherit"
                            startIcon={<PdfIcon />}
                        >
                            {t('order.motifDownload')}
                        </Button>
                    </MenuItem>)}

                {isRepeatable(selectedOrder) && (
                    <MenuItem>
                        <Button
                            onClick={() => { handleMenuClick('repeat'); handleMenuClose() }}
                            color="inherit"
                            startIcon={<RedoIcon />}
                        >
                            {t('order.draft.repeat')}
                        </Button>
                    </MenuItem>)}

                {isCancelable(selectedOrder) && (
                    <MenuItem>
                        <Button
                            onClick={() => { handleMenuClick('cancel'); handleMenuClose() }}
                            color="inherit"
                            startIcon={<CancelIcon />}
                        >
                            {t('order.draft.delete')}
                        </Button>
                    </MenuItem>)}

                {isEditable(selectedOrder) && (
                    <MenuItem>
                        <Button
                            onClick={() => { handleMenuClick('orderEditable'); handleMenuClose() }}
                            color="inherit"
                            startIcon={<OrderEditIcon />}
                        >
                            {t('order.draft.edit')}
                        </Button>
                    </MenuItem>)}

                {isReleaseable(selectedOrder) && (
                    <MenuItem>
                        <Button
                            onClick={() => { handleMenuClick('release'); handleMenuClose() }}
                            color="inherit"
                            startIcon={<ReleaseIcon />}
                        >
                            {t('order.draft.release')}
                        </Button>
                    </MenuItem>)}



                {   // WSS only
            /* <MenuItem>
                <Button
                    onClick={() => { handleMenuClick('expose.preview'); handleMenuClose() }}
                    color="inherit"
                    startIcon={<VisibilityIcon />}
                >
                    {t('order.expose.preview')}
                </Button>
            </MenuItem>
            <MenuItem>
                <Button
                    onClick={() => { handleMenuClick('expose.statistics'); handleMenuClose() }}
                    color="inherit"
                    startIcon={<InfoIcon />}
                >
                    {t('order.expose.statistics')}
                </Button>
            </MenuItem> */}
            </Menu >
            <ConfirmationDialog open={confirmationDialogOpen} msg={msg} okAction={okAction} cancelAction={cancelAction} />
        </>
    )
};
export default DraftActionsMenu;