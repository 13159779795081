import {
    Divider,
    Stack,
    Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { properties } from '../../redux/Selectors.js';

const createMarkup = (value) => {
    return { __html: value };
};

export const TemplateInfo = ({ item }) => {

    const _properties = useSelector((state) => properties(state));

    return <Stack alignItems='left'>
        <Typography sx={{ textAlign: 'center', fontSize: '18px' }}
            dangerouslySetInnerHTML={createMarkup(item.name)}></Typography>
        {item.htmlText && (<Divider sx={{ backgroundolor: _properties.appBackgroundColor }}></Divider>)}
        {item.htmlText && (<Typography sx={{ fontSize: '14px' }} dangerouslySetInnerHTML={createMarkup(item.htmlText)}></Typography>)}
        <Typography sx={{ fontSize: '14px' }}>R: {item.rows} C: {item.cols}</Typography>
        <Typography sx={{ fontSize: '14px' }}>H: {item.imageHeight} W: {item.imageWidth}</Typography>
        {/* <Typography sx={{ fontSize: '14px' }}>src: {item._links.image.href}</Typography> */}
        {item.__creativeParams && (<>
            <Typography sx={{ fontSize: '14px' }}>doc-height: {item.__creativeParams['doc-height-1000mm'] / 1000}</Typography>
            <Typography sx={{ fontSize: '14px' }}>doc-width: {item.__creativeParams['doc-width-1000mm'] / 1000}</Typography>
        </>
        )}
    </Stack>
};

export const Label = ({ text }) => {
    const { t } = useTranslation();

    return (
        <Typography
            sx={{ fontSize: 14 }}>
            {t(text)}:
        </Typography>
    );
}

export const LabelShrink = ({ text }) => {
    const { t } = useTranslation();

    return (
        <Typography
            sx={{ fontSize: 10 }}>
            {t(text)}
        </Typography>
    );
}

export const DataBold = ({ text }) => {
    return (
        <Typography variant='textBold'>
            {text}
        </Typography>
    );
}