import React from 'react';

import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Grid,
    Stack,
    Divider,
    Pagination,
    Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { OrderCardSmall, OrderDetail } from './OrderCard';

const Item = styled(Paper)(({ theme }) => ({
    border: 'none',
    boxShadow: 'none'
}));

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OrderPicker = forwardRef(({ xHeight, orders, isDraft, detailDialogOpen }, ref) => {
    const { t } = useTranslation();
    const [itemHeight, setItemHeight] = useState(Math.floor((xHeight - 50) / 2));
    const [pickerHeight, setPickerHeight] = useState((itemHeight * 2) + 40);
    const [innerPickerHeight, setInnerPickerHeight] = useState(pickerHeight - 40);
    const listenResize = () => {
        let tmpItemHeight = Math.floor((xHeight - 50) / 2);
        let tmpPickerHeight = (tmpItemHeight * 2) + 40;
        let tmpInnerPickerHeight = tmpPickerHeight - 30;
        setItemHeight(tmpItemHeight);
        setPickerHeight(tmpPickerHeight);
        setInnerPickerHeight(tmpInnerPickerHeight);
        if (window.innerWidth < 600) {
            setItemsPerPage(100);
            setPickerHeight('auto');
            setInnerPickerHeight('auto');
        } else if (window.innerWidth < 900) {
            setItemsPerPage(4);
        } else if (window.innerWidth < 1200) {
            setItemsPerPage(6);
        } else {
            setItemsPerPage(8);
        }
    };

    useImperativeHandle(ref, () => ({
        closeDetailDialog: () => {
            setDialogOpen(false);
        }
    }));


    useEffect(() => {
        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    const [itemsPerPage, setItemsPerPage] = React.useState(12);
    const [page, setPage] = React.useState(1);
    const [noOfPages, setNoOfPages] = React.useState(
        Math.ceil(orders.length / itemsPerPage)
    );

    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(null);

    const handleDialogOpen = (order) => {
        setDialogOpen(true);
        setCurrentOrder(order);
    };

    const handleDialogClose = (ba) => {
        setDialogOpen(false);
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(
        () => setNoOfPages(Math.ceil(orders.length / itemsPerPage)),
        [orders, itemsPerPage]
    );

    const DetailDialog = () => {
        return currentOrder && (
            <OrderDetail order={currentOrder} handleDialogClose={handleDialogClose} dialogOpen={dialogOpen} isDraft={isDraft} />
        );
    };

    return (
        <>
            <Stack direction="column" justifyContent="flex-end" xsx={{ height: xHeight }} >
                <Item height={10} sx={{ m: 1 }} >
                    <Divider />
                </Item>
                <Item sx={{ height: innerPickerHeight }}>
                    <Grid container>
                        {orders
                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            .map(item =>
                                <Grid key={item._id} item xs={12} sm={6} md={4} lg={3}  >
                                    <Box sx={{ height: itemHeight, pl: 1 }}>
                                        <OrderCardSmall
                                            item={item}
                                            itemHeight={itemHeight}
                                            dialogHandler={handleDialogOpen} />
                                    </Box>
                                </Grid>
                            )}
                    </Grid>
                </Item>
                <Item height={10} sx={{ m: 1 }}>
                    <Box display={{ xs: 'none', sm: 'block' }}>
                        <Divider />
                    </Box>
                </Item>
                <Item>
                    <Stack direction="row" justifyContent="flex-end">
                        <Item />
                        <Item height={15}>
                            <Box component="span" display={{ xs: 'none', sm: 'flex' }}>
                                <Pagination
                                    count={noOfPages}
                                    page={page}
                                    onChange={handleChange}
                                    defaultPage={1}
                                    color="primary"
                                    size="large"
                                    showFirstButton
                                    showLastButton
                                    sx={{ '& .MuiPaginationItem-page': { display: 'none' } }}
                                />
                            </Box>
                        </Item>
                    </Stack>
                </Item>
            </Stack >
            <DetailDialog
                open={dialogOpen}
            />
        </>
    )

}
);

export default OrderPicker;
