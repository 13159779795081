import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { InputAdornment, Typography, Button, DialogActions, MenuItem } from '@mui/material';
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { PickersDay } from '@mui/x-date-pickers';
import startOfDay from "date-fns/startOfDay";
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import i18n, { localeMap, maskMap, formatMap } from '../../configuration/i18n';
import EventIcon from '@mui/icons-material/Event';
import { CustomExpandMore } from '../../common/widgetTools';
import { SwiperNextIcon, SwiperPrevIcon } from './DesktopIcons.jsx';
import { device } from '../../redux/Selectors';
import MsgPopper from '../common/MsgPopper.jsx';

export const MultipleDatePicker = forwardRef(({ startDate, possibleDates, selectedDates, displayAll, onChange, variant, openDatePicker, label, toolbarPlaceholder, inputRef, visibleDate }, ref) => {

    const { t } = useTranslation();

    const _device = useSelector((state) => device(state));

    const [values, setValues] = useState([]);
    const [value, setValue] = useState();
    const [pickerVariant, setPickerVariant] = useState("desktop");
    const [highlightedDays, setHighlightedDays] = React.useState([]);
    const [calendarOpened, setCalendarOpened] = useState(true);
    const [referenceDate, setReferenceDate] = useState(startDate);
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState('');

    const calRef = useRef();

    useImperativeHandle(ref, () => ({
        openPicker: () => {
            handleClickOpen(true);
        }
    }));

    const handleClickOpen = () => {
        //      console.log("setCalendarOpened(true)");
        setCalendarOpened(true);
    };

    const handleClose = () => {
        if (variant !== 'mobile') {
            //           console.log("setCalendarOpened(false)");
            setCalendarOpened(false);
        }
    };

    useEffect(() => {
        setPickerVariant(variant === "mobile" ? "mobile" : "desktop");
        console.log("setValue " + startDate)
        setValue(startDate);
        setReferenceDate(visibleDate);
        //       console.log("setCalendarOpened " + openDatePicker);
        setCalendarOpened(openDatePicker);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setReferenceDate(visibleDate);
    }, [visibleDate]);

    useEffect(() => {
        if (variant === "mobile") {
            //           console.log("setCalendarOpened " + openDatePicker);
            setCalendarOpened(openDatePicker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDatePicker]);

    useEffect(() => {
        //       console.log("useEffect possibleDates");
        console.log("possibleDates = " + possibleDates);
        console.log("selectedDates = " + selectedDates);
        let v = [];
        if (!displayAll && (!selectedDates || selectedDates.length === 0 || new Date(selectedDates[0]) < new Date(possibleDates[0])) && possibleDates?.length > 0 && new Date(startDate) < new Date(possibleDates[0])) {
            v = [startOfDay(new Date(possibleDates[0]))];
            //           console.log("values (first possibleDate) = " + v);
            setValues(v);
            onChange([possibleDates[0]], possibleDates[0]);
        } else if (selectedDates?.length > 0 && possibleDates?.length > 0) {
            let possDates = possibleDates.map(pd => startOfDay(new Date(pd)));
            selectedDates.forEach(d => {
                let dd = startOfDay(new Date(d));
                if (findDate(possDates, dd)) {
                    v.push(dd);
                }
            })
            if (v?.length === 0) {
                v.push(possDates[0]);
            }
            //          console.log("values = " + v);
            setValues(v);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleDates, selectedDates])

    useEffect(() => {
        console.log("useEffect values ... " + values);
        console.log(value);
        console.log(referenceDate);
        if (values.length !== 0) {
            genHighlightedDays(referenceDate, values);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    function toJSONLocal(date) {
        //     console.log(date);
        if (!date) return "";
        var local = new Date(date);
        var d = new Date(date);
        local.setMinutes(d.getMinutes() - d.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }

    function disableDays(date) {
        let dateString = toJSONLocal(date);
        return Boolean(!displayAll && possibleDates.find(d => d === dateString) === undefined ? true : false);
    }

    const CustomPickersDay = styled(PickersDay, {
        shouldForwardProp: (prop) => prop !== "selected"
    })(({ theme, isSelected, day }) => ({
        ...(isSelected && day && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.dark
            },
            borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%"
        })
    }));

    const PickersToolbar = () => {
        return (<></>);
    }

    const PrevIcon = () => {
        return (
            <Box sx={{ mr: 4 }}>
                <SwiperPrevIcon />
            </Box>
        )
    }

    const NextIcon = () => {
        return (
            <Box sx={{}}>
                <SwiperNextIcon />
            </Box>
        )
    }

    const findDate = (dates, date) => {
        const dateTime = new Date(date).getTime();
        return dates.find((item) => startOfDay(item).getTime() === dateTime);
    };

    const findIndexDate = (dates, date) => {
        const dateTime = new Date(date).getTime();
        return dates.findIndex((item) => item.getTime() === dateTime);
    };

    const RenderPickerDay = (props) => {
        //    console.log("RenderPickerDay props = " + JSON.stringify(props));
        //const { day, ...others } = props;
        const { highlightedDays = [], day, outsideCurrentMonth, ...others } = props;
        console.log(day);
        if (!values) {
            return <PickersDay {...others} day={day} />;
        }

        const isSelected =
            !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.getDate()) >= 0;

        //        console.log("isSelected " + isSelected);
        //       console.log(others);
        //       console.log("day = " + day);
        return (
            <CustomPickersDay
                {...others}
                disableMargin
                isSelected={isSelected}
                selected={false}
                day={day}
            />
        );
    };

    const handleMonthChange = (date) => {
        //     console.log("handleMonthChange " + date);
        //      console.log("values " + values);
        if (_device !== 'mobile') {
            //          console.log("setValue " + value)
            setValue(new Date(date));
            //           console.log("setReferenceDate " + date);
            setReferenceDate(new Date(date));
        } else {
            //           console.log("setReferenceDate " + date);
            setReferenceDate(new Date(date));
        }
        genHighlightedDays(date, values);
    };

    function genHighlightedDays(newDate, selDates) {
        //      console.log("genHighlightedDays " + newDate);
        //      console.log("selDates " + selDates);
        if (newDate !== undefined) {
            var hdays = [];
            var visibleMonth = (new Date(newDate)).getMonth();
            selDates.forEach(d => {
                let dd = new Date(d);
                let day = dd.getDate();
                let month = dd.getMonth();
                if (visibleMonth === month) {
                    hdays.push(day);
                }
            });
            setHighlightedDays(hdays);
            //         console.log("highlighted days " + hdays);
        }
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const DatePickerDesktop = () => {
        //      console.log("Display desktop date picker ...")
        //       console.log(values);
        return (
            <>
                <Box
                    ref={calRef}
                >
                    {values?.length > 0 && (<StaticDatePicker
                        format={formatMap[i18n.language]}
                        disablePast
                        openTo="day"
                        label={label}
                        //      value={value}
                        referenceDate={new Date(referenceDate)}
                        views={['day']}
                        shouldDisableDate={disableDays}
                        maxDate={addDays(new Date(), 365)}
                        onMonthChange={handleMonthChange}
                        onChange={(newValue) => {
                            //copying the values array 
                            console.log("onChange " + newValue);
                            console.log("setValue " + newValue)

                            setMsgOpen(false);
                            setValue(newValue);
                            const array = [...values];
                            const date = startOfDay(newValue);
                            const index = findIndexDate(array, date);
                            if (index >= 0) {
                                if (values.length > 1) {
                                    array.splice(index, 1);
                                } else {
                                    //                                   console.log("last date");
                                    setMsgText(t("schedule.text.calendar.lastDate"));
                                    setMsgOpen(true);
                                }
                            } else {
                                array.push(date);
                            }
                            //                         console.log(array);
                            setValues(array);
                            let stringArray = [];
                            array.forEach(a => {
                                if (!disableDays(a)) {   // without startdate ...
                                    stringArray.push(toJSONLocal(a));
                                }
                            })
                            //                         console.log(stringArray);
                            onChange(stringArray, newValue);
                        }}
                        slots={{
                            day: RenderPickerDay,
                            toolbar: PickersToolbar,
                            leftArrowIcon: PrevIcon,
                            rightArrowIcon: NextIcon
                        }}
                        slotProps={{
                            day: { highlightedDays },
                            actionBar: {
                                // The actions will be the same between desktop and mobile
                                actions: []
                            }
                        }}
                    />)}
                </Box>
                {values?.length === 0 && (<Typography variant='text'>
                    {t('schedule.text.noCalendar')}
                </Typography>)}
                <MsgPopper
                    open={msgOpen}
                    arrow={true}
                    anchorRef={calRef}
                    placement='right'
                    text={msgText}
                >
                </MsgPopper>
            </>)
    };


    function CustomActionBar(props) {
        const { onAccept, onClear, onCancel, actions, className } = props;
        const localeText = useLocaleText();
        if (actions == null || actions.length === 0) {
            return null;
        }
        const menuItems = actions?.map((actionType) => {
            switch (actionType) {
                case "clear":
                    return (
                        <MenuItem
                            data-mui-test="clear-action-button"
                            onClick={() => {
                                onClear();
                            }}
                            key={actionType}
                        >
                            {localeText.clearButtonLabel}
                        </MenuItem>
                    );
                case "cancel":
                    return (
                        <MenuItem
                            onClick={() => {
                                //                             console.log("onCancel ...");
                                onCancel();
                                //                              console.log("setCalendarOpened(false)");
                                setCalendarOpened(false);
                            }}
                            key={actionType}
                        >
                            {localeText.cancelButtonLabel}
                        </MenuItem>
                    );
                case "accept":
                    return (
                        <MenuItem
                            onClick={() => {
                                //                     callback(customizedDate.current);
                                //                              console.log("onAccept ...");
                                onAccept();
                                //                               console.log("setCalendarOpened(false)");
                                setCalendarOpened(false);
                            }}
                            key={actionType}
                        >
                            {t('m.schedule.calendar.close')}
                        </MenuItem>
                    );
                default:
                    return null;
            }
        });
        return (
            <DialogActions className={className}>
                <Button>{menuItems}</Button>
            </DialogActions>
        );
    }

    const onOpen = () => {
        //     console.log("onOpen ... ");
        //     console.log("setCalendarOpened(true)");
        setCalendarOpened(true);
    }


    const DatePickerMobile = () => {
        //       console.log("Display mobile date picker ...")
        //       console.log("toolbarPlaceholder = " + toolbarPlaceholder);
        return (
            <MobileDatePicker
                inputFormat={formatMap[i18n.language]}
                mask={maskMap[i18n.language]}
                disablePast
                maxDate={addDays(new Date(), 365)}
                minDate={new Date()}
                displayStaticWrapperAs='mobile'
                openTo="day"
                label={label}
                open={calendarOpened}
                onOpen={onOpen}
                referenceDate={new Date(referenceDate)}
                //         value={values[0]}
                shouldDisableDate={disableDays}
                showToolbar={false}
                onMonthChange={handleMonthChange}
                closeOnSelect={false}
                onChange={(newValue) => {
                    //copying the values array 
                    console.log("onChange " + newValue);
                    if (newValue !== null) {
                        const array = [...values];
                        const date = startOfDay(newValue);
                        const index = findIndexDate(array, date);
                        if (index >= 0) {
                            if (values.length > 1) {
                                array.splice(index, 1);
                            } else {
                                console.log("last date");
                            }
                        } else {
                            array.push(date);
                        }
                        console.log(array);
                        setValues(array);
                        let stringArray = [];
                        array.forEach(a => {
                            if (!disableDays(a)) {   // without startdate ...
                                stringArray.push(toJSONLocal(a));
                            }
                        })
                        console.log(stringArray);
                        onChange(stringArray, newValue);
                        genHighlightedDays(newValue, stringArray);
                        //                  console.log("setCalendarOpened(true)");
                        setCalendarOpened(true);
                    }
                }}
                slots={{
                    day: RenderPickerDay,
                    toolbar: PickersToolbar,
                    leftArrowIcon: PrevIcon,
                    rightArrowIcon: NextIcon,
                    actionBar: CustomActionBar
                }}
                slotProps={{
                    day: { highlightedDays },
                    textField: {

                        variant: 'standard',
                        style: {},
                        InputProps: {
                            value: toolbarPlaceholder,
                            type: 'text',
                            style: { marginTop: '10px' },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomExpandMore />
                                </InputAdornment>
                            ),
                        },
                    },
                    actionBar: {
                        actions: ["accept"],
                    },
                }}

            />);
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[i18n.language]}
            locale={localeMap[i18n.language]}>
            {(variant !== "static" && pickerVariant === "desktop") && (
                <>
                    <IconButton onClick={handleClickOpen} sx={{ p: 0, color: 'inherit' }} component="span">
                        <EventIcon />
                    </IconButton>
                    <Dialog open={calendarOpened} onClose={handleClose}>
                        <Box>
                            <DatePickerDesktop />
                        </Box>
                    </Dialog>
                </>
            )}
            {pickerVariant === "mobile" && (<DatePickerMobile />)}
            {variant === "static" && (<DatePickerDesktop />)}

        </LocalizationProvider>
    );
});

