import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import { properties, motifUuid } from '../redux/Selectors.js';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const MinWidth = (props) => {
    const { t } = useTranslation();
    const _properties = useSelector((state) => properties(state));

    return (
        <Box sx={{ fontSize: '7vw' }}>
            <AppBar position="static"><Toolbar></Toolbar></AppBar>
            <img style={{ width: '100%' }} src="/webstore/images/16912179_s.jpg"></img>
            <p style={{ margin: '2vw', textAlign: 'center' }}>{t('browser.unsupported.minwidth', { width: _properties.minimumWidth })}</p>
        </Box>
    );
}

export default MinWidth;
