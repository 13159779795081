import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export async function urlIsSvg(url) {
    const r = await fetch(url, { method: 'HEAD' });
    return r.headers.get('content-type') === 'image/svg+xml';
}

const convert = require('xml-js');
const JsonFind = require("json-find");

export async function readSVG(url) {
    //    console.log("readSVG: " + url);
    const r = await fetch(url);
    return r.text()
        .then(str => JSON.parse(convert.xml2json(str, { compact: true })))
        .then(data => {
            let svg = {};
            let doc = JsonFind(data);
            let paths = doc?.checkKey("path");
            let path = [];
            if (paths?._attributes?.d) {
                path.push(paths._attributes.d)
            } else {
                for (let i = 0; i < paths.length; i++) {
                    let p = paths[i]._attributes?.d;
                    path.push(p);
                }
            }
            svg.path = path;
            svg.viewBox = doc?.checkKey("viewBox");
            svg.strokeWidth = doc?.checkKey("stroke-width")?.toString();
            svg.strokeLinecap = doc?.checkKey("stroke-linecap")?.toString();
            svg.strokeLinejoin = doc?.checkKey("stroke-linejoin")?.toString();
            //           console.log("return svg = " + JSON.stringify(svg));
            return svg;
        })
        .catch(e => {
            console.log(e);
        });

}


export const SVGIcon = (props) => {
    //    console.log("createSVGIcon ...");
    //    console.log(props.svg);
    let index = 1;

    const CusIcon = createSvgIcon(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={props.svg.fill}
            viewBox={props.svg?.viewBox}
            strokeWidth={props.svg?.strokeWidth}
            stroke="currentColor"
        >
            {props.svg.path.map(p =>
                <path key={index++} strokeLinecap={props.svg?.strokeLinecap} strokeLinejoin={props.svg?.strokeLinejoin} d={p}></path>
            )}
        </svg>,
        props.svg.name,
    );

    return <CusIcon {...props} />;
}