import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
    pageDimension,
    properties,
    selectedTemplate,
    selectedMarket
} from '../../redux/Selectors.js';
import { fetchDesignAlternatives } from '../../redux/Actions.js';

import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import SelectedMarket from '../components/SelectedMarket.jsx';
import { DEVICE_MOBILE, getPage } from '../../common/navigationTools.js';

/**
 * @returns 
 */
export const Preview = () => {

    const offsetTopMobile = 180;
    const offsetTopDesktop = 155;
    const pageOffset = 250;
    const pageMargin = 1;
    const itemBorderWidth = 1;

    const { t } = useTranslation();

    const _pageDimension = useSelector((state) => pageDimension(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ref = useRef();

    const [mmToPx, setMmToPx] = useState(1);
    const [dimension, setDimension] = useState({});
    const [columnCount, setColumnCount] = useState(0);
    const [columnGap, setColumnGap] = useState(0);
    const [columnWidth, setColumnWidth] = useState(0);
    const [columns, setColumns] = useState([]);
    const [columnHeight, setColumnHeight] = useState(0);
    const [pageWidth, setPageWidth] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [offsetTop, setOffsetTop] = useState(0);

    const setupPage = () => {
        let count = dimension.columnCount;
        let width = dimension.columnWidth;
        let gap = dimension.columnGap;
        setColumnCount(count);
        setColumnWidth(width * mmToPx); // mm -> px
        setColumnGap(gap * mmToPx);
        let pwPx = mmToPx * ((count * width) + ((count - 1) * gap));
        setPageWidth(pwPx);

        let ol = ((window.innerWidth - pwPx) / 2);
        setOffsetLeft(ol);

        const c = [];
        for (let i = 0; i < count; ++i) {
            c.push(i);
        }
        setColumns(c);

        //        setColumnHeight(`${window.innerHeight - pageOffset - 20}px`);
        setColumnHeight(`${dimension.heightInMM - dimension.gapToTopBorder - dimension.gapToBottomBorder}px`);
        //        setPageHeight(`${window.innerHeight - pageOffset}px`);
        setPageHeight(`${dimension.heightInMM}px`);
        setOffsetTop(offsetTopMobile);
    };

    useEffect(() => {
        if (_pageDimension) {
            setDimension(_pageDimension);
        }
    }, [_pageDimension]);

    useEffect(() => {
        setupPage();
    }, [dimension]);

    useEffect(() => {
        if (_selectedMarket) {
            dispatch(fetchDesignAlternatives());
        }
    }, [_selectedMarket]);

    const calcHeight = () => {
        return `${window.innerHeight - pageOffset}px`;
    };

    useEffect(() => {
        setupPage();
        return (() => {
        });
    }, []);

    const itemWidth = item => {
        let w = item.minWidth;
        let iw = columnWidth * w + (columnGap * (w - 1));
        return iw;
    };

    const AdPreview = ({ template, columnRef }) => {

        return (
            <Box sx={{ width: itemWidth(template) }}>
                <img
                    width="100%"
                    height="auto"
                    id={template._id}
                    src={template._links.image.href}>
                </img>
            </Box>
        )
    }

    const Column = styled(Box)(({ theme }) => ({
        backgroundColor: '#AEAEAE65',
        ...theme.typography.body2,
        //        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: `${columnWidth}px`,
        height: columnHeight
    }));

    const Space = styled(Box)(({ theme }) => ({
        backgroundColor: 'white',
        ...theme.typography.body2,
        //        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: `${columnGap}px`,
        height: columnHeight
    }));

    const PageColumn = ({ i }) => {
        return (
            <Stack direction="row" >
                <Column></Column>
                {i < columnCount && (<Space></Space>)}
            </Stack>);
    };

    const PagePreview = () => {

        return (
            <>
                <Paper
                    elevation={4}
                    sx={{
                        padding: pageMargin,
                        position: 'fixed',
                        width: pageWidth + 20,
                        height: pageHeight,
                        left: offsetLeft - 10,
                        top: offsetTop - 20,
                    }}>
                    {/* Spaltenraster */}
                    <Box id='id-spaltenraster' sx={{
                        position: 'fixed',
                        left: offsetLeft,
                        top: offsetTop,
                        maxHeight: columnHeight,
                        // overflowY: 'hidden',
                    }}>

                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            spacing={0}
                        >
                            {
                                columns.map((e, i) => {
                                    return <PageColumn i={i + 1} key={i}>x</PageColumn>
                                })
                            }
                        </Stack>
                    </Box>

                    {/* Motiv */}
                    <Box sx={{
                        position: 'fixed',
                        left: offsetLeft,
                        top: offsetTop,
                        maxHeight: columnHeight,
                        width: pageWidth + 20,
                        overflowY: 'scroll',
                    }}>
                        <AdPreview template={_selectedTemplate} columnRef={ref}></AdPreview>

                    </Box>
                </Paper>
            </>
        );

    }

    const back = () => {
        navigate(getPage('templates', DEVICE_MOBILE));
    };

    const next = () => {
        navigate(getPage('creative', DEVICE_MOBILE));
    };

    return (
        < Stack direction="column" alignItems="center" justifyContent="flexStart" spacing={8} >
            <AppHeader></AppHeader>

            <Stack sx={{ width: '90%' }} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket market={_selectedMarket}></SelectedMarket>
                <Divider />
                <Typography color={_properties.appPrimaryColor} variant="mobilePageLabel">{t('m.preview.title')}</Typography>
                <PagePreview columnCount={columnCount}></PagePreview>
            </Stack>
            <NavigationBar
                fixed
                back={{ clicked: back, label: t('m.preview.back') }}
                next={{ clicked: next, label: t('m.preview.next') }}>
            </NavigationBar>
            {/* <InfoText /> */}
        </Stack >
    )
}

export default Preview;
