import React, { useEffect, useState } from 'react';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { orderAlternativeAddress, hasAlternativeAddress } from '../../redux/Selectors.js';
import { MailAddress } from './MailAddress';

export const AlternativeContacts = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [modified, setModified] = useState();

    const _hasAlternativeAddress = useSelector((state) => hasAlternativeAddress(state));
    const _orderAlternativeAddress = useSelector((state) => orderAlternativeAddress(state));

    useEffect(() => {
        if (_hasAlternativeAddress) {
            if (_orderAlternativeAddress) {
                setModified(JSON.parse(JSON.stringify(_orderAlternativeAddress)));
            } else {
                setModified({ 'title': '', 'name': '' });
            }
        } else {
            setModified(null);
        }
    }, [_hasAlternativeAddress]);


    const DataBold = ({ text }) => {
        return (
            <Typography variant='textBold'>
                {text}
            </Typography>
        );
    }

    const handleAddressChange = (event) => {
        //  setOrderCatchword(event.target.value);
        //  dispatch(setOrderCatchword(event.target.value));
    };

    return (
        <Stack>
            <DataBold text={t('customerdata.alternativeEmail')} />
            <DataBold text={t('customerdata.alternativeBillAddress')} />
            {modified && <MailAddress onChange={handleAddressChange}
                mailAddress={modified} />}
        </Stack>
    );

}