import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Box,
    Fab,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';

import { setAdvertizer } from '../../redux/Actions.js';
import {
    selectAdvertizers,
    selectAdvertizersForSelection,
    datagridLocaleText
} from '../../redux/Selectors.js';

const advertizersList = (advertizers, t) => {
    return advertizers.map(e => {
        return {
            id: e.businessPartnerNo,
            name: e.name,
            businessPartnerNo: e.businessPartnerNo,
        }
    });
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Advertizers = ({ showBusyIndicator }) => {
    const { t } = useTranslation();
    const _advertizers = useSelector((state) => selectAdvertizers(state));
    const _datagridLocaleText = useSelector((state) => datagridLocaleText(state));
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(advertizersList(_advertizers, t));
        showBusyIndicator(false)
    }, [_advertizers]);


    const columns = [
        { field: 'name', headerName: t('cutomerdata.advertizers.grid.name'), width: 200, type: Date },
        { field: 'businessPartnerNo', headerName: t('cutomerdata.advertizers.grid.businessPartnerNo'), width: 200 },
    ];

    const handleAdd = () => {

    };

    return (
        <Stack
            sx={{ m: '1em' }}
            direction="column"
            justifyContent="space-between"
            spacing="0.5em"
            alignItems="left">

            <Stack direction="row" alignItems="center">
                <Typography variant="pageTitleBold">
                    {t('customerdata.advertizers.title')}
                </Typography>
                {/* <Fab sx={{ marginLeft: "auto" }} size="small" color="primary" onClick={() => handleAdd()}>
                    <AddIcon />
                </Fab> */}
            </Stack>

            <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    localeText={_datagridLocaleText}
                />
            </Box>
        </Stack>

    );
}

/**
 * @returns 
 */
export const AdvertizersSmall = () => {

    const { t } = useTranslation();
    const _advertizers = useSelector((state) => selectAdvertizers(state));
    const [list, setList] = useState([]);

    useEffect(() => {
        const l = []
        advertizersList(_advertizers, t).forEach(function (item, index, array) {
            if (index < 3) {
                l.push(item);
            }
        });
        setList(l);
    }, [_advertizers]);

    return (_advertizers ? (
        <Box>
            {
                list.map(it => {
                    return (<Grid key={it.businessPartnerNo} container width='100%' >
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {it.businessPartnerNo}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="cardData">
                                {it.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    )
                })
            }
        </Box>
    ) : (< Box >
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
    ));
}

/**
 * @returns 
 */
export const AdvertizersSelect = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _advertizers = useSelector((state) => selectAdvertizersForSelection(state));
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (_advertizers) {
            setValue(_advertizers[0]);
        }
    }, [_advertizers]);

    const handleChange = e => {
        setValue(e.target.value);
        if (e.target.value && e.target.value.businessPartnerNo) {
            dispatch(setAdvertizer(e.target.value.businessPartnerNo));
        }
    };

    return value ? (
        <FormControl variant="standard">
            <Select
                labelId="advertizers-select"
                id="advertizers-select"
                value={value}
                label={t('status.for')}
                onChange={handleChange}
                displayEmpty
            >
                {_advertizers.map(item => {
                    return <MenuItem key={item.businessPartnerNo} sx={{ height: '2rem', fontSize: '14px' }} value={item}>{item.businessPartnerNo} {item.name}</MenuItem>
                })}
            </Select>
        </FormControl>) : <></>;
}


