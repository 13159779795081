import { RefObject, useEffect, useMemo, useState, useRef } from 'react'
import { debounce } from '../common/tools';

export const useIntersectionObserver = (elementRef,
    {
        threshold = 0,
        root = null,
        rootMargin = '0%',
        freezeOnceVisible = false
    }) => {

    const [entry, setEntry] = useState();

    const frozen = entry?.isIntersecting && freezeOnceVisible;

    const updateEntry = entry => {
        setEntry(entry)
    };

    useEffect(() => {
        const node = elementRef?.current // DOM Ref
        const hasIOSupport = !!window.IntersectionObserver

        if (!hasIOSupport || frozen || !node) return

        const observerParams = { threshold, root, rootMargin }
        const observer = new IntersectionObserver(updateEntry, observerParams)

        observer.observe(node)

        return () => observer.disconnect()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

    return entry;
}

/**
 * 
 * @param {*} callback 
 * @returns 
 */
export const useDebounce = (callback, delay = 1000) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, delay);
    }, []);

    return debouncedCallback;
};

/**
 * Hook that alerts clicks outside of the passed ref
 * 
 * @param {*} ref 
 * @param {*} notify 
 */
export const useOutsideClickListener = (ref, notify) => {

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                notify(event);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}