import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, IconButton, Paper, Typography } from '@mui/material';

export const OrderFailureDialog = ({ open, handleClose, message }) => {

    const { t } = useTranslation();

    const [msg, setMsg] = useState(t('order.orderFailure'));

    useEffect(() => {
        setMsg(message ? message : t('order.orderFailure'));
    }, [message]);


    return (
        <Dialog fullScreen open={open}>
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <Typography
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('order.orderFailure.title')}</Typography>
                <Typography
                    sx={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: msg }}></Typography>
                <Box sx={{ marginTop: '5vh' }} >
                </Box>
                <Button sx={{ marginTop: '10vh', textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>Ok</Button>
            </Paper >
        </Dialog >
    );
}
