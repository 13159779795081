import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import {
    Box,
    Divider,
    Stack,
    Typography
} from '@mui/material';
import { WarningIcon } from '../../components/common/DesktopIcons.jsx';
import {
    formxValid,
    formxValues,
    isMotifValid,
    motifUuid,
    navigateCreativeNext,
    preSelectedOffer,
    preSelectedTemplate,
    selectedMarket,
    selectedTemplate,
    adBoxNumberTypes
} from '../../redux/Selectors.js';
import AppHeader from '../../components/AppHeader';
import SelectedMarket from '../components/SelectedMarket.jsx';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';

import Formx from '../../frames/Formx';
import Preview from '../../components/Preview';
import { getPage, DEVICE_MOBILE } from '../../common/navigationTools.js';
import { CreativeConfigArea } from '../../components/creative/CreativeConfigArea.jsx';
import { motifHasOptions } from '../../common/tools.js';

/**
 * @returns 
 */
export const Creative = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const formx = useRef(null);

    const _formxValid = useSelector((state) => formxValid(state));
    const _formxValues = useSelector((state) => formxValues(state));
    const _isMotifValid = useSelector((state) => isMotifValid(state));
    const _motifUuid = useSelector((state) => motifUuid(state))
    const _next = useSelector((state) => navigateCreativeNext(state))
    const _preSelectedOffer = useSelector((state) => preSelectedOffer(state));
    const _preSelectedTemplate = useSelector((state) => preSelectedTemplate(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _adBoxNumberTypes = useSelector(state => adBoxNumberTypes(state));

    const [validateForm, setValidateForm] = useState(false);

    const BACK = getPage('templates', DEVICE_MOBILE);

    useEffect(() => {
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (validateForm && formx?.current?.isComplete()) {
            setValidateForm(false);
            navigate(_next);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateForm, _formxValid, _next]);

    const back = () => {
        navigate(BACK);
    };

    const next = () => {
        setValidateForm(true);
        formx.current.validateForm();
    };

    return (
        < Stack direction="column" alignItems="center" justifyContent="flexStart" spacing={8} >
            <AppHeader></AppHeader>

            <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket market={_selectedMarket}></SelectedMarket>
                <Divider />
                <Typography variant="mobilePageSubTitle">{t('m.creative.preview')}</Typography>
                {/* WS-8291  */}
                {!_isMotifValid && (<Typography sx={{ textAlign: "center" }} variant="errorText"><WarningIcon />&nbsp;{t('m.creative.warning', { maxHeight: _selectedTemplate.maxHeight })}</Typography>)}
                <Box sx={{ height: '20vh', overflowY: 'scroll' }}>
                    <Preview motifUuid={_motifUuid}></Preview>
                </Box>
                <Divider />
                {motifHasOptions(_selectedTemplate, _adBoxNumberTypes) ? (<Stack><Typography variant="mobilePageSubTitle">{t('m.creative.options')}</Typography>
                    <Box sx={{ pb: 5 }}>
                        <CreativeConfigArea />
                    </Box></Stack>) : (<></>)}
                <Typography variant="mobilePageSubTitle">{t('m.creative.text')}</Typography>
                <Box sx={{
                    height: '45vh',
                    //                    position: 'fixed',
                    //                    bottom: '60px',
                    left: 0,
                    width: '100%',
                    //                    height: '40vh'
                }}>
                    <Formx
                        ref={formx}
                        form={_formxValues}
                    >
                    </Formx>
                </Box>
            </Stack>
            <NavigationBar
                back={!_preSelectedTemplate ? { clicked: back, label: t('m.navigation.toTemplates') } : null}
                next={!_preSelectedOffer ? { clicked: next, label: t('m.navigation.toOffers') } : { clicked: next, label: t('m.navigation.toSchedule') }}>
            </NavigationBar>
            <InfoText />
        </Stack >
    )
}

export default Creative;
