import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { desiredDate, possibleDates, selectedOffer, reqProductionDates } from '../../redux/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import { MultipleDatePicker } from '../common/MultipleDatePicker';
import { setSelectedDates } from '../../redux/Actions';
import { Typography, Stack } from '@mui/material';
import format from 'date-fns/format';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const PublicationDatePicker = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _desiredDate = useSelector((state) => desiredDate(state));
    const _possibleDates = useSelector((state) => possibleDates(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _productionDates = useSelector((state) => reqProductionDates(state));

    const [selectedValues, setSelectedValues] = useState([]);
    const [visibleDate, setVisibleDate] = useState();


    useEffect(() => {
        let selV = _productionDates ? _productionDates : [];
        if ((!selV || selV.length === 0) && _possibleDates?.length > 0 && _possibleDates.find(pd => pd === _desiredDate) === -1) {
            selV.push(_possibleDates);
        } else {
            selV.push(_desiredDate);
        }
        console.log("setSelectedValues " + JSON.stringify(selV));
        setSelectedValues(selV);
        setVisibleDate(selV[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box>
            <Stack direction='row'>
                {props.variant !== "static" && (<Typography
                    variant='textBold'>
                    {selectedValues?.length === 1 ? format(new Date(selectedValues[0]), formatMap[i18n.language], { locale: localeMap[i18n.language] }) : selectedValues?.length + ' ' + t('form.dates')}
                </Typography>)}
                <MultipleDatePicker
                    {...props}
                    startDate={selectedValues[0]}
                    possibleDates={_possibleDates}
                    displayAll={!_selectedOffer.publicationCalendar}
                    selectedDates={selectedValues}
                    visibleDate={visibleDate}
                    onChange={(values, referenceDate) => {
                        console.log("MultipleDatePicker onChange " + JSON.stringify(values));
                        setSelectedValues(values);
                        dispatch(setSelectedDates(values));
                        setVisibleDate(referenceDate);
                    }} />
            </Stack >
        </Box>

    );

}