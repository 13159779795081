import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Stack,
    Typography
} from '@mui/material';

import { designAlternatives, selectedTemplate, selectedMarket } from '../../redux/Selectors.js';

import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import SelectedMarket from '../components/SelectedMarket.jsx';
import UpsellingComponent from '../../components/creative/UpsellingComponent.jsx';
import { DEVICE_MOBILE, getPage } from '../../common/navigationTools.js';

/**
 * @returns 
 */
export const Upselling = () => {

    const { t } = useTranslation();

    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const navigate = useNavigate();

    const back = () => {
        navigate(getPage('creative', DEVICE_MOBILE));
    };

    const next = () => {
        navigate(getPage('offers', DEVICE_MOBILE));
    };

    const handleUpselling = (e) => {
        alert(e);
    };

    return (
        < Stack direction="column" alignItems="center" justifyContent="flexStart" spacing={8} >
            <AppHeader></AppHeader>

            <Stack sx={{ width: '90%' }} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket market={_selectedMarket}></SelectedMarket>
                <Divider />
                <Box sx={{ xbackgroundColor: 'yellow', height: '75vh' }}>
                    <UpsellingComponent handleComponentClose={handleUpselling} ></UpsellingComponent>
                </Box>
            </Stack>
            <NavigationBar
                fixed
                back={{ clicked: back, label: t('m.upselling.back') }}
                next={{ clicked: next, label: t('m.upselling.next') }}>
            </NavigationBar>
            {/* <InfoText /> */}
        </Stack >
    )
}

export default Upselling;
