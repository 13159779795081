import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material'
import { CcMenuItem, CcSelect } from '../../AppTheme';
import { DataGrid } from '@mui/x-data-grid';
import {
    datagridLocaleText
} from '../../redux/Selectors.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const ServicePackages = ({ servicePackages, showBusyIndicator }) => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const _datagridLocaleText = useSelector(state => datagridLocaleText(state));

    useEffect(() => {
        setRows(list(servicePackages));
    }, []);

    const startDownload = e => {
        e.stopPropagation();
        const id = e.target.download;
        const checkDownload = (id, count) => {
            if (document.cookie.indexOf("download=" + id) !== -1 || count >= 20) {
                showBusyIndicator(false);
            }
            else {
                setTimeout(() => checkDownload(id, count + 1), 500);
            }
        }
        showBusyIndicator(true);
        document.cookie = "download=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkDownload(id, 0);
    };

    const list = (items) => {
        return items.map(e => {
            return {
                id: e._id,
                name: e.shortName,
                description: e.description,
                regular: e.nonSaleTotalNetPrice,
                contract: e.aggreedPrice,
                rest: e.remainingBalance,
                from: t('common.date.short', { value: new Date(e.validFrom) }),
                to: t('common.date.short', { value: new Date(e.validTo) }),
                download: e
            }
        });
    }

    const columns = [

        { field: 'name', headerName: t('package.name'), width: 140 },
        { field: 'description', headerName: t('package.description'), width: 230 },
        {
            field: 'regular',
            headerName: t('package.regular'),
            width: 140,
            type: Number,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: params => {
                // todo: get currency by params.id from order-list
                return t('global.currency.' + ('EUR'), { value: params.value.value });
            },
        },
        {
            field: 'contract',
            headerName: t('package.contract'),
            width: 140,
            type: Number,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: params => {
                // todo: get currency by params.id from order-list
                return t('global.currency.' + ('EUR'), { value: params.value.value });
            },
        },
        {
            field: 'rest',
            headerName: t('package.rest'),
            width: 150,
            type: Number,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: params => {
                // todo: get currency by params.id from order-list
                return t('global.currency.' + ('EUR'), { value: params.value.value });
            },
        },
        { field: 'from', headerName: t('package.from'), width: 150 },
        { field: 'to', headerName: t('package.to'), width: 150 },
        {
            field: 'download',
            headerName: t('package.download'),
            width: 200,
            renderCell: (params) => {
                const fileName = `${params.value.shortName}.pdf`;
                return <a
                    target="_blank"
                    download={fileName}
                    onClick={startDownload}
                    href={params.value._links.download.href}>{params.value.shortName}.pdf</a>;
            }
        }
    ];

    return (
        <Stack
            sx={{ margin: '1em' }}
            direction="column"
            justifyContent="space-between"
            spacing="1em"
            alignItems="left">

            <Typography variant="pageTitleBold">
                {t('customerdata.servicePackages.title')}
            </Typography>

            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    localeText={_datagridLocaleText}
                />
            </Box>
        </Stack>

    );
}



