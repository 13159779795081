import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Stack, Box, Card, Typography, CardContent, Grid, Backdrop, CircularProgress, Divider, IconButton
} from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import AppHeader from '../../components/AppHeader';
import RegistrationPaging from '../components/RegistrationPaging';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { fetchInvoices } from '../../redux/Actions';
import { datagridLocaleText, invoicesPeriod, invoices, properties } from '../../redux/Selectors';
import { DownloadIcon } from '../../common/widgetTools';

export const Invoices = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _invoices = useSelector((state) => invoices(state));
    const period = useSelector((state) => invoicesPeriod(state));
    const _properties = useSelector(state => properties(state));

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [invoicesInitialized, setInvoicesInitialized] = useState(false);

    useEffect(() => {
        setBackdropOpen(true);
        setInvoicesInitialized(false);
        dispatch(fetchInvoices('year'));
    }, [period]);


    useEffect(() => {
        if (/*_invoices?.length > 0 && */ invoicesInitialized === true) {
            console.log("setBackdropOpen false");
            setBackdropOpen(false);
        } else {
            setInvoicesInitialized(true);
        }
    }, [_invoices]);

    const startDownload = e => {
        e.stopPropagation();
        const id = e.target.download;
        const checkDownload = (id, count) => {
            if (document.cookie.indexOf("download=" + id) !== -1 || count >= 20) {
                setBackdropOpen(false);

            }
            else {
                setTimeout(() => checkDownload(id, count + 1), 500);
            }
        }
        setBackdropOpen(true);
        document.cookie = "download=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkDownload(id, 0);
    };

    const DownloadButton = ({ invoice }) => {
        const fileName = `${invoice?.invoiceNumber}.pdf`;
        return <IconButton
            target="_blank"
            rel="noreferrer"
            download={fileName}
            onClick={startDownload}
            sx={{ color: _properties.appPrimaryColor }}
            href={invoice?._links.download.href}><DownloadIcon size='lg' /></IconButton>;
    }

    const getSwiperStyle = () => {
        return {
            "--swiper-pagination-color": _properties.appPrimaryColor,
            "--swiper-navigation-color": _properties.appPrimaryColor,
            width: '100%',
            "paddingBottom": "40px",
            "paddingTop": "15px"
        }
    }

    const InvoiceCard = ({ invoice }) => {
        return (
            <Card
                key={invoice.invoiceNumber}
                sx={{ height: '100%', width: '90%', border: 'none', boxShadow: 'none', cursor: 'pointer' }}>
                <Box sx={{ height: '100%', border: 1, borderRadius: 2.5, borderColor: '#A0A0A0', p: 2, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Stack>
                        <CardContent sx={{ height: '25vw', pt: 1, textAlign: 'left' }}>
                            < Grid container >
                                <Grid item xs={6} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('invoice.date')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={6} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('common.date.short', { value: new Date(invoice.invoiceDate) })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('invoice.number')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={6} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {invoice.invoiceNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {t('invoice.value')}:
                                    </Typography>
                                </Grid>
                                < Grid item xs={6} >
                                    <Typography variant="mobileTextBoxLabel" >
                                        {
                                            t('global.currency.' + ('EUR'), { value: invoice.grossValue.value })
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} >
                                    <Typography variant="mobileTextBoxLabel" >

                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="mobileTextBoxLabel">
                                        <DownloadButton invoice={invoice} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Stack>
                </Box>
            </Card>
        );
    }


    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ minHeight: windowHeight - 150 }} spacing={4} width='100%'>
                <RegistrationPaging step={0} pagetitle='m.customer.invoices.title' />

                <Divider />

                {_invoices.length > 0 && (<Stack width='100%' alignItems='center'>
                    <Box width='90%' >
                        <Swiper
                            slidesPerView='1'
                            spaceBetween={10}
                            keyboard={{
                                enabled: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Keyboard, Pagination, Navigation]}
                            className="mySwiper"
                            direction={"horizontal"}
                            style={getSwiperStyle()}
                        >
                            {_invoices.slice(0, 10).map(item =>
                                <SwiperSlide key={item.invoiceNumber} width='90%'>
                                    <InvoiceCard invoice={item} key={item.invoiceNumber} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Box>
                </Stack>)}
                {_invoices.length === 0 && (<Typography sx={{ pl: 4 }} variant="mobilePagePrimaryLabel">{t('m.customer.invoices.empty')}</Typography>)}
            </Stack>

            <NavigationBar
                back={{ clicked: () => navigate(-1), label: t('m.customer.invoices.back') }}>
            </NavigationBar>
            <InfoText />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
}

export default Invoices;