import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, breadcrumbsClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import { Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { confirmTermsAndConditions } from '../../redux/Actions';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const TermsAndConditionDialog = ({ open, handleClose }) => {
    const { t } = useTranslation();

    const handleAction = (confirmed) => {
        handleClose(confirmed);
    };

    return (<Dialog
        open={open}
        onClose={handleClose}
    >
        <DialogContent>
            <DialogContentText>
                <Typography
                    variant='termsAndConditions'
                    dangerouslySetInnerHTML={{ __html: t('order.checkbox1') }}></Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button sx={{ textTransform: 'none' }} variant="navigationBack"
                onClick={() => { handleAction(false) }} > Ablehnen</Button>
            <Button sx={{ textTransform: 'none' }} variant="navigationAction"
                onClick={() => { handleAction(true) }}>Bestätigen</Button>

        </DialogActions>
    </Dialog >)
};

export default TermsAndConditionDialog;