import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import Popper from '@mui/material/Popper';
import { styled } from '@mui/system';
import { Typography, Fade, Paper, Box, Stack, Button, IconButton } from '@mui/material';
import { properties } from '../../redux/Selectors.js';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
/**
 * 
 * @param {*} title 
 * @returns 
 */

const StyledPopper = styled(Popper, {
    shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme, arrow }) => ({
    zIndex: 1,
    '& > div': {
        position: 'relative',
        width: '300px'
    },
    '&[data-popper-placement*="bottom"]': {
        '& > div': {
            marginTop: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.primary.main} transparent`,
            },
        },
    },
    '&[data-popper-placement*="top"]': {
        '& > div': {
            marginBottom: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.primary.main} transparent transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="right"]': {
        '& > div': {
            marginLeft: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.primary.main} transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="left"]': {
        '& > div': {
            marginRight: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
            },
        },
    },
}));

const Arrow = styled('div')(({ theme }) => ({
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
    },
}));

export const MsgPopper = (props) => {

    const _properties = useSelector((state) => properties(state));

    const [arrowRef, setArrowRef] = useState(null);
    const [msgOpen, setMsgOpen] = useState(false);
    const [anchorRef, setAnchorRef] = useState();

    useEffect(() => {
        if (props.open !== undefined) {
            setMsgOpen(props.open);
        }
    }, [props.open]);

    useEffect(() => {
        if (props.anchorRef !== undefined) {
            setAnchorRef(props.anchorRef);
        }
    }, [props.anchorRef]);


    const getMsgStyle = {
        background: `${_properties.appPrimaryColor}`,
        borderRadius: '8px'
    }



    return (
        <Box>
            <StyledPopper
                open={msgOpen}
                anchorEl={props.anchorEl ? props.anchorEl : anchorRef?.current}
                placement={props.placement}
                transition
                modifiers={[
                    {
                        name: 'arrow',
                        enabled: props.arrow,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}>

                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box >
                            <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
                            <Paper sx={getMsgStyle}>
                                <Stack>
                                    <Typography
                                        sx={{ fontSize: 14, textAlign: 'left', color: '#F4F4F4', p: 5 }}
                                        dangerouslySetInnerHTML={{ __html: props.text }}></Typography>
                                    <Stack direction="row"
                                        justifyContent="space-around"
                                        alignItems="center"
                                        spacing={6}
                                        sx={{ width: '100%', pt: 2, pb: 2, pl: 10, pr: 10 }} >
                                        {props.button && (<Button onClick={props.handleButton} sx={{ bgcolor: '#F4F4F4', p: 2 }} >{props.buttonText}</Button>)}
                                        {props.cancel && (<IconButton size="small" sx={{ color: _properties.appBackgroundColor }} onClick={props.cancel.onClick}>
                                            <CloseIcon />
                                        </IconButton>)}
                                        {props.ok && (<IconButton size="small" sx={{ color: _properties.appBackgroundColor }} onClick={props.ok.onClick} >
                                            <CheckIcon />
                                        </IconButton>)}
                                    </Stack>
                                </Stack>
                            </Paper>
                        </Box>
                    </Fade>
                )}
            </StyledPopper>
        </Box >
    );
}

export default MsgPopper;
